import {renderPlanCRail} from "./CRailRenderFactory";
import {vaultActions} from "../../../actions";
import {toRealLine, toScreenLength, toScreenPosition} from "../helper/realWorldTransform";
import {snapResizeCRail} from "../helper/snap";

export const createCRails = (cRails, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  return cRails.map((cRail) => {
    const screenPosition = toScreenPosition(
      cRail,
      realWorldTransform,
      {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
    );
    const clickCallbackHandler = () => {
      dispatch(vaultActions.removeCRail(wallIndex, cRail.id));
    };
    const dragEndCallbackHandler = (snappedCoordinate) => {
      dispatch(vaultActions.moveCRail(wallIndex, cRail.id, snappedCoordinate.x, snappedCoordinate.y));
    };
    const resizedCallbackHandler = (resizedLine, direction) => {
      const newScreenLine = {
        ...resizedLine,
        x: screenPosition.x + resizedLine.x,
        y: screenPosition.y + resizedLine.y
      };
      const newRealLine = toRealLine(
        newScreenLine,
        realWorldTransform,
        {x: configAreaLayout.screenX, y: configAreaLayout.screenY});
      const snappedNewRealCRail = snapResizeCRail(
        newRealLine,
        configAreaLayout.realWidth,
        configAreaLayout.realHeight,
        direction);

      if (snappedNewRealCRail.x !== cRail.x || snappedNewRealCRail.y !== cRail.y) {
        dispatch(vaultActions.moveCRail(wallIndex, cRail.id, snappedNewRealCRail.x, snappedNewRealCRail.y));
      }
      dispatch(vaultActions.resizeCRail(wallIndex, cRail.id, snappedNewRealCRail.length));
      return toScreenLength(snappedNewRealCRail.length, realWorldTransform)
    };

    return renderPlanCRail(
      cRail,
      configAreaLayout,
      realWorldTransform,
      dragEndCallbackHandler,
      clickCallbackHandler,
      resizedCallbackHandler
    );
  });
};

