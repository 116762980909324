import {vaultActions} from "../../../actions";
import {renderPlanDrainage} from "./DrainageRenderFactory";

const createDrainage = (drainage, index, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  const dragEndCallbackHandler =
    snappedCoordinate => {
      dispatch(vaultActions.moveWallDrainage(wallIndex, drainage.id, snappedCoordinate.x, snappedCoordinate.y));
    };
  const clickCallbackHandler = () => {
    dispatch(vaultActions.removeWallDrainage(wallIndex, drainage.id));
  };

  return renderPlanDrainage(drainage, configAreaLayout, realWorldTransform, dragEndCallbackHandler, clickCallbackHandler);
};

export const createDrainages = (drainages, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  return drainages.map((drainage, i) => {
    return createDrainage(drainage, i, configAreaLayout, realWorldTransform, wallIndex, dispatch);
  });
};
