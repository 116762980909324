import {Button, ButtonGroup, Grid, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {projectActions} from "../../../actions";
import {grayButtonStyle, orangeButtonStyle} from "../../../constants";

const useStyles = () => {
  return makeStyles({
    orangeButton: orangeButtonStyle,
    grayButton: grayButtonStyle,
  })();
};

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Lieferart
      </Typography>
      < hr/>
    </Grid>
  );
};

const DeliveryTypeGridItem = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deliveryType = useSelector(state => state.project.deliveryType);

  return (
    <Grid item xs={12}>
      <ButtonGroup
        fullWidth
        variant="contained"
      >
        <Button
          className={deliveryType === "delivery" ? classes.orangeButton : classes.grayButton}
          color={deliveryType === "delivery" ? "primary" : "default"}
          onClick={() => {
            dispatch(projectActions.setDeliveryType("delivery"));
          }}
        >
          Lieferung
        </Button>
        <Button
          className={deliveryType === "pickupFactory" ? classes.orangeButton : classes.grayButton}
          color={deliveryType === "pickupFactory" ? "primary" : "default"}
          onClick={() => {
            dispatch(projectActions.setDeliveryType("pickupFactory"));
          }}
        >
          Abholung ab Werk
        </Button>
      </ButtonGroup>
    </Grid>
  );
};

export const DeliveryTypeSection = () => {
  return (
    <Grid container spacing={2}>
      <TitleGridItem/>
      <DeliveryTypeGridItem/>
    </Grid>
  );
};
