import {renderPlanBlockOut} from "./BlockOutRenderFactory";
import {vaultActions} from "../../../actions";
import {toRealRect, toScreenRect} from "../helper/realWorldTransform";
import {snapRectToGrid} from "../helper/snap";

export const createBlockOuts = (blockOuts, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  return blockOuts.map((blockOut) => {
    const screenRect = toScreenRect(
      blockOut,
      realWorldTransform,
      {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
    );
      const clickCallbackHandler = () => {
        dispatch(vaultActions.removeBlockOut(wallIndex, blockOut.id));
      };
      const dragEndCallbackHandler = (snappedCoordinate) => {
        dispatch(vaultActions.moveBlockOut(wallIndex, blockOut.id, snappedCoordinate.x, snappedCoordinate.y));
      };
      const resizedCallbackHandler = resizedRect => {
        const newScreenRect = {
          ...resizedRect,
          x: screenRect.x + resizedRect.x,
          y: screenRect.y + resizedRect.y
        };
        const newRealRect = toRealRect(
          newScreenRect,
          realWorldTransform,
          {x: configAreaLayout.screenX, y: configAreaLayout.screenY});
        const snappedNewRealRect = snapRectToGrid(
          newRealRect,
          configAreaLayout.realWidth,
          configAreaLayout.realHeight);

        if (snappedNewRealRect.x !== blockOut.x || snappedNewRealRect.y !== blockOut.y) {
          dispatch(vaultActions.moveBlockOut(wallIndex, blockOut.id, snappedNewRealRect.x, snappedNewRealRect.y));
        }
        dispatch(vaultActions.resizeBlockOut(wallIndex, blockOut.id, snappedNewRealRect.width, snappedNewRealRect.height));
      };

      return renderPlanBlockOut(
        blockOut,
        configAreaLayout,
        realWorldTransform,
        dragEndCallbackHandler,
        clickCallbackHandler,
        resizedCallbackHandler
      );
    });
};

