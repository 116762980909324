export const apiUrls = {
  authenticationService: "http://auth.hsig.ch",
  userEndpoint: "http://auth.hsig.ch/user",
  plansEndpoint: "http://project.hsig.ch/plans",
  projectsEndpoint: "http://project.hsig.ch/projects",
  planPdfDownload: "http://project.hsig.ch/pdfs.php"
};

export const mannhartWebsite = "https://mm-mannhart.ch";
export const termsAndConditions = "https://mm-mannhart.ch";
export const privacyPolicy = "https://mm-mannhart.ch";
