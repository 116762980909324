import React from 'react';

import {makeStyles, Paper, useTheme} from "@material-ui/core";

import {orangeBackgroundColor} from "../../../constants";
import Grid from "@material-ui/core/Grid";

import {PlanDescriptionSection} from "./PlanDescriptionSection";
import {GroundPlanSection} from "./GroundPlanSection";
import {ElevationPlanSection} from "./ElevationPlanSection";
import {LidSection} from "../LidStep/LidSection";

const useStyles = theme =>  {
  return makeStyles({
    plan: {
      background: orangeBackgroundColor,
      padding: theme.spacing(6)
    }
  })();
};

export const VerificationStep = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Paper square={true} className={classes.plan}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <PlanDescriptionSection/>
        </Grid>
        <Grid item xs={6}>
          <GroundPlanSection/>
        </Grid>
        <Grid item xs={6}>
          <ElevationPlanSection step={2}/>
        </Grid>
        <Grid item xs={6}>
          <ElevationPlanSection step={3}/>
        </Grid>
        <Grid item xs={6}>
          <ElevationPlanSection step={4}/>
        </Grid>
        <Grid item xs={6}>
          <ElevationPlanSection step={5}/>
        </Grid>
        <Grid item xs={6}>
          <LidSection/>
        </Grid>
      </Grid>
    </Paper>
  )
};

