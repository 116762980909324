import {planActionTypes as actionTypes} from "../constants";
import uuid from "react-uuid";

const initialState = {
  type: "A15",
  collar: "beveled",
  fill: "anthracite",
  manholes: []
}

const manholesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addManhole:
      return [...state, {id: uuid(), ...action.data}] // todo_mga: dont generate uuid in reducer

    case actionTypes.removeManhole:
      return state.filter(manhole => manhole.id !== action.data)

    case actionTypes.moveManhole:
      return state.map(manhole => {
        if (action.data.id !== manhole.id) {
          return manhole;
        }
        return {...manhole, x: action.data.x, y: action.data.y}
      });

    case actionTypes.resizeManhole:
      return state.map(manhole => {
        if(action.data.id !== manhole.id) {
          return manhole;
        }
        return {...manhole, width: action.data.width};
      });

    default:
      return state
  }
}

export const lidReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.setLid:
      return state || initialState

    case actionTypes.unsetLid:
      return undefined;

    case actionTypes.setLidType:
      if (state) {
        if (action.data === 'subfloor') {
          const {type, pavingReady, fill, collar, ...others} = state

          return {...others, type: action.data}
        }
        const collar = state.collar ?? initialState.collar
        if (state.pavingReady || state.collar){
          return {...state, collar, type: action.data}
        }
        return {...state, collar, fill: initialState.fill, type: action.data}
      }
      return state

    case actionTypes.setLidCollar:
      if (state) {
        return {...state, collar: action.data}
      }
      return state

    case actionTypes.setLidPavingReady:
      if (state) {
        const {fill, ...others} = state;

        return {...others, pavingReady: true}
      }
      return state

    case actionTypes.unsetLidPavingReady:
      if (state) {
        const {pavingReady, ...others} = state;
        const fill = initialState.fill;

        return {...others, fill};
      }
      return state

    case actionTypes.setLidFill:
      if (state && !state.pavingReady) {
        return {...state, fill: action.data}
      }
      return state

    default:
  }
  if (!state) return state
  const newManholes = manholesReducer(state.manholes, action)

  if (state.manholes === newManholes) return state
  return {...state, manholes: newManholes}
}
