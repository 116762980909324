import React from "react";

import {Grid, makeStyles, TextField, Typography, useTheme} from "@material-ui/core";
import {useSelector, useDispatch} from "react-redux";

import {orangeBackgroundColor} from "../../../constants";
import {projectActions} from "../../../actions"

const useStyles = theme => {
  return makeStyles({
    plan: {
      background: orangeBackgroundColor,
      padding: theme.spacing(2)
    },
    italic: {
      fontStyle: 'italic',
    }
  })();
};

const TitleGridItem = () => {
  return (
    <Grid style={{marginTop: '-2em'}} item xs={12}>
      <Typography component="h1" variant="h4">
        Baustelle/Lieferung
      </Typography>
      < hr/>
    </Grid>
  );
};

const DescriptionGridItem = () => {
  const dispatch = useDispatch();
  const description = useSelector(state => state.project.description);

  return (
    <Grid item xs={12}>
      <TextField
        margin="normal"
        variant="outlined"
        fullWidth
        id="description"
        label="Baustellenbezeichnung"
        name="description"
        value={description}
        error={!description}
        helperText={!description ? "Baustellenbezeichnung ist erforderlich" : null}
        onChange={e => {
          dispatch(projectActions.setDescription(e.target.value));
        }}
      />
    </Grid>
  );
};

const StreetGridItem = () => {
  const dispatch = useDispatch();
  const street = useSelector(state => state.project.street);

  return (
    <Grid item xs={12}>
      <TextField
        margin="normal"
        variant="outlined"
        fullWidth
        id="street"
        label="Strasse und Nummer"
        name="street"
        value={street}
        error={!street}
        helperText={!street ? "Strasse ist erforderlich" : null}
        onChange={e => {
          dispatch(projectActions.setStreet(e.target.value));
        }}
      />
    </Grid>
  );
};

const CityGridItem = () => {
  const dispatch = useDispatch();
  const zip = useSelector(state => state.project.zip);
  const city = useSelector(state => state.project.city);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            margin="normal"
            variant="outlined"
            fullWidth
            id="zip"
            label="PLZ"
            name="zip"
            value={zip}
            error={!zip}
            helperText={!zip ? "PLZ ist erforderlich" : null}
            onChange={e => {
              dispatch(projectActions.setZip(e.target.value));
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            margin="normal"
            variant="outlined"
            fullWidth
            id="city"
            label="Ort"
            name="city"
            value={city}
            error={!city}
            helperText={!city ? "Ort ist erforderlich" : null}
            onChange={e => {
              dispatch(projectActions.setCity(e.target.value));
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const NumberGridItem = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const number = useSelector(state => state.project.number);

  return (
    <Grid item xs={12}>
      <TextField
        margin="normal"
        variant="outlined"
        fullWidth
        className={classes.italic}
        id="number"
        label="Baustellennummer - Optional"
        name="number"
        value={number}
        onChange={e => {
          dispatch(projectActions.setNumber(e.target.value));
        }}
      />
    </Grid>
  );
};

const PersonGridItem = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const person = useSelector(state => state.project.person);

  return (
    <Grid item xs={12}>
      <TextField
        margin="normal"
        variant="outlined"
        fullWidth
        className={classes.italic}
        id="person"
        label="Name Kontaktperson auf Baustelle - Optional"
        name="person"
        value={person}
        onChange={e => {
          dispatch(projectActions.setPerson(e.target.value));
        }}
      />
    </Grid>
  );
};

const PhoneGridItem = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const phone = useSelector(state => state.project.phone);

  return (
    <Grid item xs={12}>
      <TextField
        margin="normal"
        variant="outlined"
        fullWidth
        className={classes.italic}
        id="phone"
        label="Telefon Kontaktperson auf Baustelle - Optional"
        name="phone"
        value={phone}
        onChange={e => {
          dispatch(projectActions.setPhone(e.target.value));
        }}
      />
    </Grid>
  );
};

const CommissionGridItem = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const commission = useSelector(state => state.project.commission);

  return (
    <Grid item xs={12}>
      <TextField
        margin="normal"
        variant="outlined"
        fullWidth
        className={classes.italic}
        id="commission"
        label="Kommission - Optional"
        name="commission"
        value={commission}
        onChange={e => {
          dispatch(projectActions.setCommission(e.target.value));
        }}
      />
    </Grid>
  );
};

const UsersOrderNumberGridItem = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const usersOrderNumber = useSelector(state => state.project.usersOrderNumber);

  return (
    <Grid item xs={12}>
      <TextField
        margin="normal"
        variant="outlined"
        fullWidth
        className={classes.italic}
        id="usersOrderNumber"
        label="Ihre Bestell-/Auftragsnummer - Optional"
        name="commission"
        value={usersOrderNumber}
        onChange={e => {
          dispatch(projectActions.setUsersOrderNumber(e.target.value));
        }}
      />
    </Grid>
  );
};

const RemarksGridItem = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const remarks = useSelector(state => state.project.remarks);

  return (
    <Grid item xs={12}>
      <TextField
        margin="normal"
        variant="outlined"
        multiline
        rows={10}
        fullWidth
        className={classes.italic}
        id="remarks"
        label="Bemerkungen - Optional"
        name="remarks"
        value={remarks}
        onChange={e => {
          dispatch(projectActions.setRemarks(e.target.value));
        }}
      />
    </Grid>
  );
};

export const ConstructionSiteSection = () => {
  return (
    <Grid container spacing={2}>
      <TitleGridItem/>
      <Grid item container alignItems="flex-start" spacing={6}>
        <Grid item xs={6} container>
          <DescriptionGridItem/>
          <StreetGridItem/>
          <CityGridItem/>
        </Grid>
        <Grid item xs={6} container>
          <NumberGridItem/>
          <PersonGridItem/>
          <PhoneGridItem/>
          <CommissionGridItem/>
          <UsersOrderNumberGridItem/>
          <RemarksGridItem/>
        </Grid>
      </Grid>
    </Grid>
  );
};

