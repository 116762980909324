export const isLoginRequired = authentication => {
  const now =  Math.ceil(new Date() / 1000);

  return authentication.loggedIn && now + 10 > authentication.jwtPayload.exp;
};

export const shouldExtendLogin = authentication => {
  if (isLoginRequired(authentication)) {
    return false;
  }
  const now =  Math.ceil(new Date() / 1000);

  //return authentication.jwtPayload && authentication.jwtPayload.exp - now < 1800;
  return authentication.jwtPayload && authentication.jwtPayload.exp - now < 60;
};

