import {renderResizeHandle} from "./resizeHandles"

const toLine = handles => {
  const orientation = handles.left && handles.right ? 'horizontal'
    : handles.top && handles.bottom ? 'vertical'
      : undefined

  switch (orientation) {
    case 'horizontal':
      return {
        x: handles.left.x(),
        y: handles.left.y(),
        orientation,
        length: handles.right.x() - handles.left.x(),
      }
    case 'vertical':
      return {
        x: handles.bottom.x(),
        y: handles.bottom.y(),
        orientation,
        length: handles.bottom.y() - handles.top.y(),
      }

    default:
  }
}

const dragBoundFunc = (pos, name, configAreaLayout, orientation, screenPosition, screenLength) => {
  let {x, y} = pos

  x = Math.max(x, configAreaLayout.screenX)
  x = Math.min(x, configAreaLayout.screenX + configAreaLayout.screenWidth)
  y = Math.max(y, configAreaLayout.screenY)
  y = Math.min(y, configAreaLayout.screenY + configAreaLayout.screenHeight)

  switch (name) {
    case "top":
      x = screenPosition.x
      y = Math.min(y, screenPosition.y)
      break

    case "bottom":
      x = screenPosition.x
      y = Math.max(y, screenPosition.y - screenLength)
      break

    case "left":
      x = Math.min(x, screenPosition.x + screenLength)
      y = screenPosition.y
      break

    case "right":
      x = Math.max(x, screenPosition.x)
      y = screenPosition.y
      break

    default:
      break
  }
  return {x: x, y: y}
}

export const renderResizeHandles = (key, configAreaLayout, orientation, screenPosition, screenLength, resizingCallback, resizedCallback) => {
  switch (orientation) {
    case 'horizontal':
      return [
        renderResizeHandle(
          0,
          0,
          "left",
          key,
          toLine,
          pos => dragBoundFunc(pos, "left", configAreaLayout, orientation, screenPosition, screenLength),
          resizingCallback,
          resizedCallback
        ),
        renderResizeHandle(
          screenLength,
          0,
          "right",
          key,
          toLine,
          pos => dragBoundFunc(pos, "right", configAreaLayout, orientation, screenPosition, screenLength),
          resizingCallback,
          resizedCallback
        )
      ]

    case 'vertical':
      return [
        renderResizeHandle(
          0,
          0,
          "bottom",
          key,
          toLine,
          pos => dragBoundFunc(pos, "bottom", configAreaLayout, orientation, screenPosition, screenLength),
          resizingCallback,
          resizedCallback
        ),
        renderResizeHandle(
          0,
          -screenLength,
          "top",
          key,
          toLine,
          pos => dragBoundFunc(pos, "top", configAreaLayout, orientation, screenPosition, screenLength),
          resizingCallback,
          resizedCallback
        )
      ]

    default:
  }
}
