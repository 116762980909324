import {userApiActionTypes} from "../constants";
import {userService} from "../services";

export const reset = () => {
  return {
    type: userApiActionTypes.reset
  };
};

const read = () => {
  const request = () => {
    return {type: userApiActionTypes.readRequest};
  };
  const success = result => {
    return {type: userApiActionTypes.readSuccess, data: result};
  };
  const failure = error => {
    return {type: userApiActionTypes.readFailure, error};
  };

  return dispatch => {
    dispatch(request());
    userService.read()
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      );
  };
};

export const userApiActions = {
  read
};
