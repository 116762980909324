import React, {Component} from "react";
import {Route, Redirect, Switch} from "react-router-dom";
import "./css/style.css";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import {Auth} from './containers/Auth';
import {Plans} from './containers/Plans'
import {Projects} from './containers/Projects'
import {Step} from "./containers/Steps";
import {ApiRequest} from "./containers/ApiRequest";

// todo convert App to functional component
export default class App extends Component {
  render() {
    const theme = createMuiTheme({
      palette: {
        primary: {main: '#ef8b2d'},
        secondary: {main: '#efbb2d'},
        error: {main: '#ff0000'}
      },
      status: {
        danger: 'red',
      },
      overrides: {
        MuiButton: {
          contained: {
            textTransform: 'none',
          },
          text: {
            textTransform: 'none',
          }
        },
      },
    });

    return (
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/auth" component={Auth}/>
          <Route exact path="/registration" component={Auth}/>
          <Route exact path="/emailverification" component={Auth}/>
          <Route exact path="/login" component={Auth}/>
          <Route exact path="/resetpassword" component={Auth}/>
          <Route exact path="/changepassword" component={Auth}/>
          <Route exact path="/plans" component={Plans}/>
          <Route exact path="/projects" component={Projects}/>
          <Route path="/apirequest" component={ApiRequest}/>
          <Route path={"/step"} component={Step}/>
          <Route path="/" render={() => {
            return <Redirect to={"/step/1"}/>;
          }}/>
        </Switch>
      </MuiThemeProvider>
    );
  }
}
