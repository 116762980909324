import {renderResizeHandle} from "./resizeHandles"

const toRect = handles => {
  return {
    x: handles.left.x(),
    y: handles.top.y(),
    width: handles.right.x() - handles.left.x(),
    height: handles.bottom.y() - handles.top.y()
  }
}

const dragBoundFunc = (pos, name, configAreaLayout, screenRect) => {
  let {x, y} = pos

  x = Math.max(x, configAreaLayout.screenX)
  x = Math.min(x, configAreaLayout.screenX + configAreaLayout.screenWidth)
  y = Math.max(y, configAreaLayout.screenY)
  y = Math.min(y, configAreaLayout.screenY + configAreaLayout.screenHeight)
  const centerX = screenRect.x + screenRect.width / 2
  const centerY = screenRect.y + screenRect.height / 2

  switch (name) {
    case "top":
      x = centerX
      y = Math.min(y, screenRect.y + screenRect.height)
      break

    case "bottom":
      x = centerX
      y = Math.max(y, screenRect.y)
      break

    case "left":
      x = Math.min(x, screenRect.x + screenRect.width)
      y = centerY
      break

    case "right":
      x = Math.max(x, screenRect.x)
      y = centerY
      break

    default:
      break
  }
  return {x: x, y: y}
}

export const renderResizeHandles = (key, configAreaLayout, screenRect, resizingCallback, resizedCallback) => {
  return [
    renderResizeHandle(
      screenRect.width / 2,
      0,
      "top",
      key,
      toRect,
      pos => dragBoundFunc(pos, "top", configAreaLayout, screenRect),
      resizingCallback,
      resizedCallback),
    renderResizeHandle(
      0,
      screenRect.height / 2,
      "left",
      key,
      toRect,
      pos => dragBoundFunc(pos, "left", configAreaLayout, screenRect),
      resizingCallback,
      resizedCallback
    ),
    renderResizeHandle(
      screenRect.width / 2,
      screenRect.height,
      "bottom",
      key,
      toRect,
      pos => dragBoundFunc(pos, "bottom", configAreaLayout, screenRect),
      resizingCallback,
      resizedCallback
    ),
    renderResizeHandle(
      screenRect.width,
      screenRect.height / 2,
      "right",
      key,
      toRect,
      pos => dragBoundFunc(pos, "right", configAreaLayout, screenRect),
      resizingCallback,
      resizedCallback
    )
  ]
}
