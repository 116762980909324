import {projectApiActionTypes} from "../constants";
import {projectService} from "../services";
import {projectActions, planActions} from "./";

const createProject = (project, plan, requestType) => {
  const request = () => {
    return {type: projectApiActionTypes.createRequest};
  };
  const success = () => {
    return {type: projectApiActionTypes.createSuccess};
  };
  const failure = error => {
    return {type: projectApiActionTypes.createFailure, error};
  };

  return dispatch => {
    dispatch(request());
    projectService.createProject({
      ...project,
      requestType,
      plan
    })
      .then(
        () => {
          dispatch(success());
          dispatch(projectActions.reset());
          dispatch(planActions.reset());
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

export const resetProjects = () => {
  return {
    type: projectApiActionTypes.resetProjects
  };
};

const readProjects = () => {
  const request = () => {
    return {type: projectApiActionTypes.readProjectsRequest};
  };
  const success = result => {
    //console.log("result:", result);
    return {type: projectApiActionTypes.readProjectsSuccess, data: result};
  };
  const failure = error => {
    return {type: projectApiActionTypes.readProjectsFailure, error};
  };

  return dispatch => {
    dispatch(request());
    projectService.readProjects()
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      );
  };
};

export const projectApiActions = {
  createProject,
  resetProjects,
  readProjects
};
