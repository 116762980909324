import React, {useState} from 'react';
import {Typography, Grid, Button, TextField, CircularProgress, Container} from '@material-ui/core';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import {orangeButtonStyle, dialogStyles} from "../../../constants";
import {authenticationActions} from "../../../actions";
import {isValidEmail} from "../../../validation/authValidation";
import * as queryString from "query-string";

const useStyles = () => {
  return makeStyles({
    orangeButton: orangeButtonStyle,
    paragraph: dialogStyles.paragraphStyle
  })();
};

const Success = props => {
  const navigateToQueryString = queryString.parse(props.location.search).navigateTo;
  const navigateTo = navigateToQueryString === undefined ? '/step/1' : navigateToQueryString;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  return (
    <Container componemt="main" maxWidth="sm">
      <Typography component="h1" variant="h4">
        Kennwort neu setzen
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Wenn Ihre E-Mail Adress in unserer Datenbank existiert,
        bekommen Sie in Kürze ein Mail mit einem Passwort-Wiederherstellungslink.
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Falls Sie kein E-Mail in Ihrem Postfach finden, prüfen Sie bitte auch Ihren Spam- oder Junk-Mail-Ordner.
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.orangeButton}
        style={{
          margin: theme.spacing(3, 0, 2),
        }}
        onClick={() => history.replace("/login?navigateTo=" + navigateTo)}
      >
        Anmelden
      </Button>
    </Container>
  );
};

const Request = props => {
  const navigateToQueryString = queryString.parse(props.location.search).navigateTo;
  const navigateTo = navigateToQueryString === undefined ? '/step/1' : navigateToQueryString;
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  // redux state
  const resetting = useSelector(state => state.authentication.resetting);
  const error = useSelector(state => state.authentication.error);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        Kennwort vergessen
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Geben Sie Ihre E-Mail Adresse ein, um Ihr Passwort neu zu setzen.
      </Typography>

      <form
        style={{marginTop: theme.spacing(6)}}
        name="form"
        noValidate
        onSubmit={e => {
          e.preventDefault();
          setSubmitted(true);
          if (isValidEmail(email)) {
            dispatch(authenticationActions.resetPassword(email));
          }
        }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="E-Mail"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          error={submitted && !isValidEmail(email)}
          helperText={
            !submitted
              ? null
              : !email
              ? "E-Mail ist erforderlich"
              : !isValidEmail(email)
                ? "E-Mail ist ungültig"
                : null
          }
          onChange={e => setEmail(e.target.value)}
        />
        {error &&
        <Typography
          component="p"
          variant="caption"
          color="error"
          style={{
            marginTop: theme.spacing(2)
          }}
        >
          {error.toString()}
        </Typography>
        }
        {resetting &&
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CircularProgress/>
          </Grid>
        </Grid>
        }
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.orangeButton}
          style={{
            margin: theme.spacing(3, 0, 2),
          }}
        >
          Kennwortänderung anfordern
        </Button>
      </form>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Button onClick={() => history.replace("/login?navigateTo=" + navigateTo)}>
            <Typography component="p" color="primary" variant="body2" texttransform="none">
              Zurück zum Login
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const ResetPassword = props => {
  const dispatch = useDispatch();
  const [isFirstRender, setIsFirstRender] = useState(true);

  if (isFirstRender) {
    dispatch(authenticationActions.logout());
    setIsFirstRender(false);
  }
  const reset = useSelector(state => state.authentication.reset);

  if (reset) {
    return <Success location={props.location}/>;
  }
  return <Request location={props.location}/>;
};

