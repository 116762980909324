const doesMatch = (term, filter) => {
  return term && term.toLowerCase().indexOf(filter.toLowerCase()) > -1;
};

const doesPlanMatch = (plan, filter) => {
  return doesMatch(plan.description, filter)
    || doesMatch(plan.length, filter)
    || doesMatch(plan.width, filter)
    || doesMatch(plan.vault.height, filter) // todo_mga: check vault is not null, create doesVaultMatch method
    || doesMatch(plan.wallWidth, filter);
};

const doesProjectMatch = (project, filter) => {
  return doesPlanMatch(project.plan, filter)
    || doesMatch(project.description, filter)
    || doesMatch(project.street, filter)
    || doesMatch(project.number, filter)
    || doesMatch(project.zip, filter)
    || doesMatch(project.city, filter)
    || doesMatch(project.person, filter)
    || doesMatch(project.phone, filter)
    || doesMatch(project.commission, filter)
    || doesMatch(project.usersOrderNumber, filter)
    || doesMatch(project.remarks, filter);
};

export const toFilteredPlans = (plans, filter) => {
  if (!plans || plans.length < 1) {
    return [];
  }
  if (filter.length < 1) {
    return plans;
  }
  return plans.filter(p => doesPlanMatch(p, filter));
};

export const toFilteredProjects = (projects, filter) => {
  if (!projects || projects.length < 1) {
    return [];
  }
  if (filter.length < 1) {
    return projects;
  }
  return projects.filter(p => doesProjectMatch(p, filter));
};

