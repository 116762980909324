import {getCenteredGridLinePositions, getGridLinePositions} from './gridLinePositions.js'
import {SNAP_GRID_SIZE, cRailLengths} from '../../../constants/index.js'

const snap = (pos, availablePos) => {
  let best
  let bestOffset = Number.MAX_VALUE

  for (let i = 0; i < availablePos.length; i++) {
    const current = availablePos[i]
    const offset = Math.abs(pos - current)

    if (best === undefined || offset < bestOffset) {
      best = current
      bestOffset = offset
    }
  }
  return best
}

export const snapCircleToGrid = (pos, circleRadius, gridWidth, gridHeight) => {
  const verticalGridLinePositions = getGridLinePositions(gridWidth, SNAP_GRID_SIZE)
    .filter(gridPos => gridPos - circleRadius >= 0 && gridPos + circleRadius <= gridWidth)
  const horizontalGridLinePositions = getCenteredGridLinePositions(gridHeight, SNAP_GRID_SIZE)
    .filter(gridPos => gridPos - circleRadius >= 0 && gridPos + circleRadius <= gridHeight)

  return {
    x: snap(pos.x, verticalGridLinePositions),
    y: snap(pos.y, horizontalGridLinePositions)
  }
}

const snapLength = (length, cellWidth) => {
  return Math.round(length / cellWidth) * cellWidth
}

// Caution... also snaps length to grid, not just position!!!
export const snapRectToGrid = (rect, gridWidth, gridHeight) => {
  const width = snapLength(rect.width, SNAP_GRID_SIZE)
  const height = snapLength(rect.height, SNAP_GRID_SIZE)
  const verticalGridLinePositions = getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true)
    .filter(gridPos => gridPos <= gridWidth - width)
  const horizontalGridLinePositions = [
    ...getCenteredGridLinePositions(gridHeight, SNAP_GRID_SIZE, true),
    gridHeight - rect.height
  ]
    .filter(gridPos => gridPos <= gridHeight - height)

  return {
    x: snap(rect.x, verticalGridLinePositions),
    y: snap(rect.y, horizontalGridLinePositions),
    width,
    height
  }
}

export const snapNoResizeRectToGrid = (rect, gridWidth, gridHeight) => {
  const width = rect.width
  const height = rect.height
  const verticalGridLinePositions = getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true)
    .filter(gridPos => gridPos <= gridWidth - width)
  const horizontalGridLinePositions = [
    ...getCenteredGridLinePositions(gridHeight, SNAP_GRID_SIZE, true),
    gridHeight - rect.height
  ]
    .filter(gridPos => gridPos <= gridHeight - height)

  return {
    x: snap(rect.x, verticalGridLinePositions),
    y: snap(rect.y, horizontalGridLinePositions),
    width,
    height
  }
}


export const snapResizeManhole = (manhole, gridWidth) => {
  const stepSize = (() => {
    switch (manhole.type) {
      case 'V2A':
        return 50
      case "V2A-10":
        return 47
      default:
        return 50
    }
  })
  const lengths = (() => {
    const maxLength = gridWidth - manhole.x
    const count = maxLength / stepSize() - 1
    return Array.from({length: count + 1}, (v, i) => (i + 1) * stepSize())
  })

  return snap(manhole.width, lengths())
}

export const snapResizeCRail = (cRail, gridWidth, gridHeight, direction) => {
  switch (direction) {
    case 'top': {
      const lengths = cRailLengths.filter(l => l <= gridHeight - cRail.y + SNAP_GRID_SIZE / 10)
      const length = snap(cRail.length, lengths)
      const x = snap(cRail.x, getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true))
      const y = snap(
        cRail.y,
        getCenteredGridLinePositions(gridHeight, SNAP_GRID_SIZE, true)
      )

      return {...cRail, x, y, length}
    }

    case 'bottom': {
      const lengths = cRailLengths.filter(l => l <= cRail.y + cRail.length + SNAP_GRID_SIZE / 10)
      const length = snap(cRail.length, lengths)
      const x = snap(cRail.x, getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true))
      const y = snap(
        cRail.y + cRail.length - length,
        getCenteredGridLinePositions(gridHeight, SNAP_GRID_SIZE, true)
      )

      return {...cRail, x, y, length}
    }

    case 'left': {
      const lengths = cRailLengths.filter(l => l <= cRail.x + cRail.length + SNAP_GRID_SIZE / 10)
      const length = snap(cRail.length, lengths)
      const x = snap(
        cRail.x + cRail.length - length,
        getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true)
      )
      const y = snap(cRail.y, getCenteredGridLinePositions(gridHeight, SNAP_GRID_SIZE, true))

      return {...cRail, x, y, length}
    }

    case 'right': {
      const lengths = cRailLengths.filter(l => l <= gridWidth - cRail.x + SNAP_GRID_SIZE / 10)
      const length = snap(cRail.length, lengths)
      const x = snap(
        cRail.x,
        getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true)
      )
      const y = snap(cRail.y, getCenteredGridLinePositions(gridHeight, SNAP_GRID_SIZE, true))

      return {...cRail, x, y, length}
    }

    default:
      return cRail
  }
}

export const snapCRailToGrid = (cRail, gridWidth, gridHeight) => {
  const halfCRailWidth = 1.9
  const maxLength = cRail.orientation === 'horizontal' ? gridWidth : gridHeight
  const length = snap(cRail.length, cRailLengths.filter(l => l <= maxLength))
  const verticalGridLinePositions = getGridLinePositions(gridWidth, SNAP_GRID_SIZE, true)
    .filter(p => {
      switch (cRail.orientation) {
        case 'horizontal':
          return p >= 0 && p <= gridWidth - length
        case 'vertical':
          return p >= halfCRailWidth && p <= gridWidth - halfCRailWidth
        default:
          return true;
      }
    })
  const horizontalGridLinePositions = [
    ...getCenteredGridLinePositions(gridHeight, SNAP_GRID_SIZE, true),
    ...(cRail.orientation === 'vertical' ? [gridHeight - length] : [])
  ]
    .filter(p => {
      switch (cRail.orientation) {
        case 'horizontal':
          return p >= halfCRailWidth && p <= gridHeight - halfCRailWidth
        case 'vertical':
          return p >= 0 && p <= gridHeight - length
        default:
          return true;
      }
    })

  return {
    ...cRail,
    x: snap(cRail.x, verticalGridLinePositions),
    y: snap(cRail.y, horizontalGridLinePositions),
    length
  }
}

export const snapManholeWidth = (type, width) => {

  switch (type) {

    default:
      return width
  }
}
