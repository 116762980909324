export const projectActionTypes = {
  reset: 'RESET_PROJECT',

  setDescription: 'SET_PROJECT_DESCRIPTION',
  setQuantity: 'SET_QUANTITY',
  setDeliveryType: 'SET_DELIVERY_TYPE',
  setDesiredSchedule: 'SET_DESIRED_SCHEDULE',
  setStreet: 'SET_STREET',
  setZip: 'SET_ZIP',
  setCity: 'SET_CITY',
  setNumber: 'SET_NUMBER',
  setPerson: 'SET_PERSON',
  setPhone: 'SET_PHONE',
  setCommission: 'SET_COMMISSION',
  setUsersOrderNumber: 'SET_USERS_ORDER_NUMBER',
  setRemarks: 'SET_REMARKS'
};