import React from 'react';

import {
  Step,
  Stepper as MuiStepper,
  Grid,
  makeStyles,
  StepButton,
  StepLabel,
  withStyles,
  StepConnector, Typography
} from '@material-ui/core';
import {useRouteMatch, useHistory} from "react-router-dom";

import {grayBackgroundColor} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {authenticationActions} from "../../actions";
import {StepIcon} from "./StepIcon";
import {CONFIGURATION_STEPS, getStepByRoute, getStepIndex, isStepEnabled} from "./configurationSteps";
import clsx from "clsx";

const Connector = withStyles({
  alternativeLabel: {
    top: 13,
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useCircleStepIconStyles = makeStyles({
  root: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    backgroundImage:
      'linear-gradient( 136deg, rgba(120, 120, 120, 0.8) 0%, rgba(120, 120, 120, 0.9) 50%, rgba(120, 120, 120, 1) 100%)',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgba(239, 139, 45, 0.8) 0%, rgba(239, 139, 45, 0.9) 50%, rgba(239, 139, 45, 1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  disabled: {
    backgroundImage:
      'linear-gradient( 136deg, rgba(204, 204, 204, 0.8) 0%, rgba(204, 204, 204, 0.9) 50%, rgba(204, 204, 204, 1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function CircleStepIcon(props) {
  const classes = useCircleStepIconStyles();
  const {active, disabled} = props;
  const icons = CONFIGURATION_STEPS.map(current => current.id)

  return (
    <div className={clsx(classes.root, {[classes.active]: active, [classes.disabled]: disabled})}>
      {icons[String(props.icon - 1)]}
    </div>
  );
}

const useStyles = makeStyles(
  () => ({
    grayPaper: {
      background: grayBackgroundColor
    }
  })
);

export const Stepper = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const plan = useSelector(state => state.plan);
  const match = useRouteMatch();
  const step = getStepByRoute(match.url)
  const stepIndex = getStepIndex(step.id)

  const disabledStepStyle = {
   // background: 'green',
    // foreground: 'blue'
  };

  const disabledTextStyle = {
    color: 'gray'
  }
  const enabledTextStyle = {
    color: 'black'
  }

  return (
    <MuiStepper
      alternativeLabel
      nonLinear
      className={classes.grayPaper}
      elevation={1}
      activeStep={stepIndex}
      connector={<Connector/>}
    >
      {CONFIGURATION_STEPS.map((current, index) => {
        const stepProps = {};
        const disabled = !isStepEnabled(plan, current.id)

        return (
          <Step key={current.description} {...stepProps} disabled={disabled} style={disabled ? disabledStepStyle : {}}>
            <StepButton
              onClick={() => {
                history.push("/step/" + (current.id));
                dispatch(authenticationActions.extendLoginIfRequired());
              }}>
              <StepLabel StepIconComponent={(props) => (<CircleStepIcon {...props} disabled={disabled}/>)}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography component="p" variant="body2" style={disabled ? disabledTextStyle : enabledTextStyle}>
                      {current.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <StepIcon scale={4} step={index} disabled={disabled} isSelected={index === stepIndex}/>
                  </Grid>
                </Grid>
              </StepLabel>
            </StepButton>
          </Step>
        );
      })}
    </MuiStepper>
  );
};
