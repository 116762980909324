import React from "react";

import {useTheme, makeStyles, Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import {orangeBackgroundColor} from "../../../constants";
import {DeliveryTypeSection} from "./DeliveryTypeSection";
import {QuantitySection} from "./QuantitySection";
import {DeadlineSection} from "./DeadlineSection";
import {ConstructionSiteSection} from "./ConstructionSiteSection";

const useStyles = theme => {
  return makeStyles({
    plan: {
      background: orangeBackgroundColor,
      padding: theme.spacing(6)
    },
    italic: {
      fontStyle: 'italic',
    }
  })();
};

export const OrderStep = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Paper square={true} className={classes.plan}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <QuantitySection/>
        </Grid>
        <Grid item xs={6}/>
        <Grid item xs={6}>
          <DeliveryTypeSection/>
        </Grid>
        <Grid item xs={6}>
          <DeadlineSection/>
        </Grid>
        <Grid item xs={12}>
          <ConstructionSiteSection/>
        </Grid>
      </Grid>
    </Paper>
  )
};
