import React from "react";
import {Text, Layer, Line} from "react-konva";
import {snapCircleToGrid} from '../helper/snap'
import {FONT_SIZE} from "../../../constants";
import {toRealPosition} from "../helper/realWorldTransform";

const crossHairColor = "red";
const textColor = "red";

const textWidth = 40;
const textHeight = 20;
const textDistance = 12;

const createLine = (key, name) => {
  return (
    <Line
      key={key}
      name={name}
      stroke={crossHairColor}
      strokeWidth={0.3}
      points={[]}
    />
  );
};

export const createCircularElementCrossHairLayer = () => {
  return (
    <Layer
      name={"circularElementCrossHairLayer"}
      visible={false}
    >
      {[
        createLine("horizontal_circular_element_cross_hair_1_1", "horizontal-cross-hair-left"),
        createLine("horizontal_circular_element_cross_hair_1_2", "horizontal-cross-hair-middle"),
        createLine("horizontal_circular_element_cross_hair_1_3", "horizontal-cross-hair-right"),
        createLine("vertical_circular_element_cross_hair_1_1", "vertical-cross-hair-top"),
        createLine("vertical_circular_element_cross_hair_1_2", "vertical-cross-hair-middle"),
        createLine("vertical_circular_element_cross_hair_1_3", "vertical-cross-hair-bottom"),
      ]}
      <Text
        key="top_text"
        name="top_text"
        width={textWidth}
        height={textHeight}
        align="center"
        verticalAlign="middle"
        fontSize={FONT_SIZE}
        fill={textColor}
      />
      <Text
        key="bottom_text"
        name="bottom_text"
        width={textWidth}
        height={textHeight}
        align="center"
        verticalAlign="middle"
        fontSize={FONT_SIZE}
        fill={textColor}
      />
      <Text
        key="left_text"
        name="left_text"
        width={textWidth}
        height={textHeight}
        align="center"
        verticalAlign="middle"
        fontSize={FONT_SIZE}
        fill={textColor}
      />
      <Text
        key="right_text"
        name="right_text"
        width={textWidth}
        height={textHeight}
        align="center"
        verticalAlign="middle"
        fontSize={FONT_SIZE}
        fill={textColor}
      />
    </Layer>
  );
};

const isWithinLayout = (x, y, layout) => {
  return x >= layout.screenX
    && x <= layout.screenX + layout.screenWidth
    && y >= layout.screenY
    && y <= layout.screenY + layout.screenHeight;
};

const getShapes = layer => {
  return {
    topText: layer.find('.top_text')[0],
    bottomText: layer.find('.bottom_text')[0],
    leftText: layer.find('.left_text')[0],
    rightText: layer.find('.right_text')[0],
    horizontalLineLeft: layer.find('.horizontal-cross-hair-left')[0],
    horizontalLineMiddle: layer.find('.horizontal-cross-hair-middle')[0],
    horizontalLineRight: layer.find('.horizontal-cross-hair-right')[0],
    verticalLineTop: layer.find('.vertical-cross-hair-top')[0],
    verticalLineMiddle: layer.find('.vertical-cross-hair-middle')[0],
    verticalLineBottom: layer.find('.vertical-cross-hair-bottom')[0]
  };
};

const getTextPositions = (radius, x, y) => {
  const halfTextWidth = textWidth / 2;
  const halfTextHeight = textHeight / 2;

  return {
    top: {x: x - halfTextWidth, y: y - radius - textHeight - textDistance},
    bottom: {x: x - halfTextWidth, y: y + radius + textDistance},
    left: {x: x - radius - textWidth - textDistance, y: y - halfTextHeight},
    right: {x: x + radius + textDistance, y: y - halfTextHeight}
  };
};

const updateLines = (configAreaLayout, x, y, shapes, textPositions) => {
  const left = configAreaLayout.screenX;
  const right = configAreaLayout.screenX + configAreaLayout.screenWidth;
  const top = configAreaLayout.screenY;
  const bottom = configAreaLayout.screenY + configAreaLayout.screenHeight;

  shapes.horizontalLineLeft.points([left, y, Math.max(left, textPositions.left.x), y]);
  shapes.horizontalLineMiddle.points([textPositions.left.x + textWidth, y, textPositions.right.x, y]);
  shapes.horizontalLineRight.points([Math.min(textPositions.right.x + textWidth, right), y, right, y]);
  shapes.verticalLineTop.points([x, top, x, Math.max(top, textPositions.top.y)]);
  shapes.verticalLineMiddle.points([x, textPositions.top.y + textHeight, x, textPositions.bottom.y]);
  shapes.verticalLineBottom.points([x, Math.min(textPositions.bottom.y, bottom) + textHeight, x, bottom]);
};

const updateText = (textShape, text, position) => {
  textShape.text(text);
  textShape.x(position.x);
  textShape.y(position.y);
};

const updateTexts = (element, configAreaLayout, realWorldTransform, x, y, shapes, textPositions) => {
  const {screenX, screenY, realWidth, realHeight} = configAreaLayout;
  const realPosition = toRealPosition({x, y}, realWorldTransform, {x: screenX, y: screenY});
  const snappedRealPosition = snapCircleToGrid(realPosition, element.outerDiameter / 2, realWidth, realHeight);

  updateText(shapes.topText, configAreaLayout.realHeight - snappedRealPosition.y, textPositions.top);
  updateText(shapes.bottomText, snappedRealPosition.y, textPositions.bottom);
  updateText(shapes.leftText, snappedRealPosition.x, textPositions.left);
  updateText(shapes.rightText, configAreaLayout.realWidth - snappedRealPosition.x, textPositions.right);
};

export const updateCrossHairs = (element, configAreaLayout, realWorldTransform, stage, x, y) => {
  const crossHairLayer = stage.find(".circularElementCrossHairLayer")[0];

  if (!isWithinLayout(x, y, configAreaLayout)) {
    crossHairLayer.hide();
    crossHairLayer.draw();
    return;
  }
  crossHairLayer.show();
  const radius = element.outerDiameter * configAreaLayout.scale / 2;
  const shapes = getShapes(crossHairLayer);
  const textPositions = getTextPositions(radius, x, y);

  updateLines(configAreaLayout, x, y, shapes, textPositions);
  updateTexts(element, configAreaLayout, realWorldTransform, x, y, shapes, textPositions);
  crossHairLayer.draw();
};

export const hideCrossHairs = (stage) => {
  const crossHairLayer = stage.find(".circularElementCrossHairLayer")[0];

  crossHairLayer.hide();
  crossHairLayer.draw();
};

