import {planApiActionTypes as actionTypes} from "../constants";

const initialState = [];

export const plansReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.resetPlans:
      return initialState;

    case actionTypes.readPlansRequest:
      return {fetching: true};

    case actionTypes.readPlansFailure:
      return {error: action.error};

    case actionTypes.readPlansSuccess:
      return Array.isArray(action.data) ? [...action.data] : [];

    default:
      return state;
  }
};
