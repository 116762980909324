import {projectActionTypes as actionTypes} from "../constants";

const initialState = {
  description: '',
  quantity: 1,
  deliveryType: 'delivery',
  desiredSchedule: '',
  street: '',
  zip: '',
  city: '',
  number: '', // optional
  person: '', // optional
  phone: '', // optional
  commission: '', // optional
  usersOrderNumber: '', // optional
  remarks: '' // optional
};

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.reset:
      return initialState;

    case actionTypes.setDescription:
      return {...state, description: action.data};

    case actionTypes.setQuantity:
      if (Number.isInteger(action.data)) {
        return {...state, quantity: action.data};
      }
      return state;

    case actionTypes.setDeliveryType:
      return {...state, deliveryType: action.data};

    case actionTypes.setDesiredSchedule:
      return {...state, desiredSchedule: action.data};

    case actionTypes.setStreet:
      return {...state, street: action.data};

    case actionTypes.setZip:
      return {...state, zip: action.data};

    case actionTypes.setCity:
      return {...state, city: action.data};

    case actionTypes.setNumber:
      return {...state, number: action.data};

    case actionTypes.setPerson:
      return {...state, person: action.data};

    case actionTypes.setPhone:
      return {...state, phone: action.data};

    case actionTypes.setCommission:
      return {...state, commission: action.data};

    case actionTypes.setUsersOrderNumber:
      return {...state, usersOrderNumber: action.data};

    case actionTypes.setRemarks:
      return {...state, remarks: action.data};

    default:
      return state;
  }
};
