export const planApiActionTypes = {
  resetPlans: 'RESET_PLANS',

  createPlanRequest: 'CREATE_PLAN_REQUEST',
  createPlanSuccess: 'CREATE_PLAN_SUCCESS',
  createPlanFailure: 'CREATE_PLAN_FAILURE',

  readPlanRequest: 'READ_PLAN_REQUEST',
  readPlanSuccess: 'READ_PLAN_SUCCESS',
  readPlanFailure: 'READ_PLAN_FAILURE',

  readPlansRequest: 'READ_PLANS_REQUEST',
  readPlansSuccess: 'READ_PLANS_SUCCESS',
  readPlansFailure: 'READ_PLANS_FAILURE'
};