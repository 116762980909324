export const getQuantityError = quantity => {
  return Number.isInteger(quantity) && quantity > 0 && quantity <= 1000
    ? ''
    : "Menge muss ganzzahlig sein und zwischen 1 und 1000 liegen";
};
export const isQuantityValid = quantity => {
  return getQuantityError(quantity).length < 1;
};

export const getMinDeadline = () => {
  const date = new Date();

  date.setDate(date.getDate() + 1);
  return date.toISOString().slice(0, 10);
};

export const getDeadlineError = deadline => {
  return deadline < getMinDeadline()
    ? "Wunschtermin muss in der Zukunft liegen"
    : '';
};

export const isDeadlineValid = deadline => {
  return getDeadlineError(deadline).length < 1;
};

export const isProjectValid = project => {
  return isQuantityValid(project.quantity)
    && isDeadlineValid(project.desiredSchedule)
    && project.description
    && project.street
    && project.zip
    && project.city;
};