import {hideCrossHairs, updateCrossHairs} from "./CircularElementCrossHairs"
import {FONT_SIZE, PLAN_ITEM_OPACITY} from "../../../constants"
import {toRealPosition, toScreenPosition} from "../helper/realWorldTransform"
import {snapCircleToGrid} from "../helper/snap"
import Konva from "konva"
import {Group, Text} from "react-konva"
import React from "react"
import {handleDragStart, setOpacity} from "./elementFunctions";

export const handleDragMove = (e, configAreaLayout, realWorldTransform) => {
  const group = e.target
  const element = group.attrs.element
  const stage = group.getStage()
  const screenX = group.x()
  const screenY = group.y()

  updateCrossHairs(element, configAreaLayout, realWorldTransform, stage, screenX, screenY)
}

export const handleDragEnd = (e, element, configAreaLayout, realWorldTransform, dragEndCallback) => {
  hideCrossHairs(e.target.getStage())
  setOpacity(e.target)
  const realPosition = toRealPosition(
    {x: e.target.x(), y: e.target.y()},
    realWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  )
  const realRadius = element.outerDiameter / 2
  const snappedRealPosition = snapCircleToGrid(
    realPosition,
    realRadius,
    configAreaLayout.realWidth,
    configAreaLayout.realHeight
  )

  if (e.target.attrs.origin) { // dragged from toolbar?
    e.target.to({
      opacity: 0,
      duration: 0,
      x: e.target.attrs.origin.x,
      y: e.target.attrs.origin.y,
    })
  } else { // dragged from plan!
    const screenPosition = toScreenPosition(
      snappedRealPosition,
      realWorldTransform,
      {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
    )

    e.target.to({
      duration: 0.05,
      easing: Konva.Easings.StrongEaseOut,
      x: screenPosition.x,
      y: screenPosition.y
    })
  }
  if (
    realPosition.x < 0 || realPosition.x > configAreaLayout.realWidth
    || realPosition.y < 0 || realPosition.y > configAreaLayout.realHeight) {
    return
  }
  dragEndCallback(snappedRealPosition)
}

export const dragBoundFunc = (
  pos,
  element,
  boundLayout
) => {
  const radius = element.outerDiameter / 2 * boundLayout.scale
  const minX = boundLayout.screenX + radius
  const maxX = boundLayout.screenX + boundLayout.screenWidth - radius
  const x = Math.max(minX, Math.min(pos.x, maxX))
  const minY = boundLayout.screenY + radius
  const maxY = boundLayout.screenY + boundLayout.screenHeight - radius
  const y = Math.max(minY, Math.min(pos.y, maxY))

  return {x, y}
}


export const renderStaticToolbarElement = (key, element, x, y, radius, renderGroupElements) => {
  return (
    <Group
      key={key}
      x={x} y={y}
      opacity={PLAN_ITEM_OPACITY}
    >
      {renderGroupElements(key, radius, element)}
    </Group>
  )
}

export const renderDragPreviewToolbarElement = (
  key,
  element,
  x,
  y,
  radius,
  configAreaLayout,
  realWorldTransform,
  dragEndCallback,
  renderGroupElements
) => {
  return (
    <Group
      key={key}
      x={x}
      y={y}
      opacity={0}
      draggable
      onDragStart={handleDragStart}
      onDragMove={e => handleDragMove(e, configAreaLayout, realWorldTransform)}
      onDragEnd={e => handleDragEnd(e, element, configAreaLayout, realWorldTransform, dragEndCallback)}
      element={element}
      origin={{x: x, y: y}}
    >
      {renderGroupElements(key, radius, element)}
    </Group>
  )
}

export const renderPlanElement = (
  key,
  element,
  radius,
  configAreaLayout,
  configAreaRealWorldTransform,
  dragEndCallback,
  clickCallback,
  renderGroupElements
) => {
  const screenPosition = toScreenPosition(
    {x: element.x, y: element.y},
    configAreaRealWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  )
  const createPositionLabel = () => {
    if (element.x !== undefined && element.y !== undefined) {
      return (
        <Text
          key={key + "_position_text"}
          name="position"
          x={-(0.8 * radius) - 200}
          y={0.8 * radius}
          width={200}
          height={FONT_SIZE}
          fontSize={FONT_SIZE}
          align="right"
          verticalAlign="top"
          text={element.x + "/" + element.y}
          fill="black"
        />)
    }
  }

  return (
    <Group
      key={key}
      x={screenPosition.x}
      y={screenPosition.y}
      opacity={PLAN_ITEM_OPACITY}
      draggable
      onClick={clickCallback}
      onDragStart={handleDragStart}
      onDragMove={e => handleDragMove(e, configAreaLayout, configAreaRealWorldTransform)}
      onDragEnd={e => handleDragEnd(e, element, configAreaLayout, configAreaRealWorldTransform, dragEndCallback)}
      dragBoundFunc={pos => dragBoundFunc(pos, element, configAreaLayout)}
      element={element}
    >
      {renderGroupElements(key, radius, element)}
      {createPositionLabel()}
    </Group>
  )
}

