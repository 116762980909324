import React from "react"
import {Layer, Stage} from "react-konva"
import {useSelector, useDispatch} from "react-redux"
import {createPart} from "./Part"
import {createBottomAlignedGrid} from "../Grid"
import {createMeasurements} from "../Measurement"
import {createToolbar} from "./Toolbar"
import {createSleeves} from "./Sleeve"
import {createBlockOuts} from "./BlockOut"
import {getNestedTransform, getRealWorldTransform} from "../helper/realWorldTransform"
import {createGroundings} from "./Grounding"
import {createDrainages} from "./Drainage"
import {createCRails} from "./CRail"
import {createCircularElementCrossHairLayer} from "./CircularElementCrossHairs"
import {createBlockOutCrossHairLayer} from "./BlockOutCrossHairs"
import {createCRailCrossHairLayer} from "./CRailCrossHairs"
import {AutoResizeDiv} from "../AutoResizeDiv";

const createLayout = (clientSize, wallIndex, planLength, planWidth, planHeight, wallWidth, isToolbarVisible) => {
  const measurementsAreaScreenWidth = Math.min(clientSize.width - 4, 65)
  const toolbarScreenHeight = Math.min(clientSize.width - 4, 125)
  const partScreenWidth = (clientSize.width - measurementsAreaScreenWidth - 4)
  const innerWidth = wallIndex < 2 ? planLength : planWidth
  const outerWidth = innerWidth + 2 * wallWidth
  const greatestOuterWidth = Math.max(planWidth, planLength) + 2 * wallWidth
  const scale = partScreenWidth / greatestOuterWidth
  const partRealWorldTransform = getRealWorldTransform(greatestOuterWidth, planHeight, partScreenWidth)
  const configAreaRealWorldTransform = getNestedTransform(partRealWorldTransform, innerWidth, planHeight)
  const partScreenHeight = planHeight * scale

  const overall = {
    screenX: 2,
    screenY: 0,
    screenWidth: clientSize.width - 4,
    screenHeight: isToolbarVisible
      ? toolbarScreenHeight + measurementsAreaScreenWidth + partScreenHeight + 40
      : measurementsAreaScreenWidth + partScreenHeight,
    realWidth: outerWidth,
    realHeight: planHeight,
    scale: scale
  }
  const toolbar = {
    screenX: overall.screenX + 10,
    screenY: overall.screenY,
    screenWidth: overall.screenWidth - 15,
    screenHeight: toolbarScreenHeight
  }
  const part =
    isToolbarVisible
      ? {
        screenX: overall.screenX + measurementsAreaScreenWidth,
        screenY: toolbar.screenX + toolbar.screenHeight + 10,
        screenWidth: outerWidth * scale,
        screenHeight: planHeight * scale,
        realWidth: outerWidth,
        realHeight: planHeight,
        scale: scale
      }
      : {
        screenX: overall.screenX + measurementsAreaScreenWidth,
        screenY: overall.screenY,
        screenWidth: outerWidth * scale,
        screenHeight: planHeight * scale,
        realWidth: outerWidth,
        realHeight: planHeight,
        scale: scale
      }
  const configArea = {
    screenX: part.screenX + wallWidth * scale,
    screenY: part.screenY,
    screenWidth: innerWidth * scale,
    screenHeight: part.screenHeight,
    realWidth: innerWidth,
    realHeight: part.realHeight,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const horizontalMeasurements = {
    screenX: part.screenX,
    screenY: part.screenY + part.screenHeight,
    screenWidth: outerWidth * scale,
    screenHeight: measurementsAreaScreenWidth,
    realWidth: outerWidth,
    scale: scale
  }
  const verticalMeasurements = {
    screenX: overall.screenX,
    screenY: part.screenY,
    screenWidth: measurementsAreaScreenWidth,
    screenHeight: planHeight * scale,
    realHeight: planHeight,
    scale: scale
  }

  return {
    scale,
    configAreaRealWorldTransform,
    overall, toolbar, part, configArea, horizontalMeasurements, verticalMeasurements
  }
}

const Plan = props => {
  const {wallIndex, isToolbarVisible, clientSize} = props
  const dispatch = useDispatch()
  const planWidth = useSelector(state => state.plan.width)
  const planLength = useSelector(state => state.plan.length)
  const wallWidth = useSelector(state => state.plan.wallWidth)
  const vault = useSelector(state => state.plan.vault)
  const planHeight = vault ? vault.height : 0
  const wall = vault && wallIndex >= 0 ? vault.walls[wallIndex] : null
  const sleeves = wall ? wall.sleeves : []
  const blockOuts = wall ? wall.blockOuts : []
  const groundings = wall ? wall.groundings : []
  const drainages = wall ? wall.drainages : []
  const cRails = wall ? wall.cRails : []
  const layout = createLayout(clientSize, wallIndex, planLength, planWidth, planHeight, wallWidth, isToolbarVisible)

  return (
    <div>
      {!isNaN(layout.scale) && vault &&
      <Stage
        name={"stage"}
        width={clientSize.width}
        height={layout.overall.screenHeight}
      >
        <Layer>
          {createPart(layout.part, layout.configArea, wallWidth)}
          {wallIndex >= 0 && createBottomAlignedGrid(layout.configArea, layout.configAreaRealWorldTransform)}
          {
            createMeasurements(
              [...sleeves, ...groundings, ...drainages],
              cRails,
              blockOuts,
              layout.configArea.realWidth,
              wallWidth,
              layout.horizontalMeasurements,
              layout.verticalMeasurements)
          }
          {
            isToolbarVisible
              ? createToolbar(layout.toolbar, layout.configArea, layout.configAreaRealWorldTransform, wallIndex, dispatch)
              : null
          }
          {createSleeves(sleeves, layout.configArea, layout.configAreaRealWorldTransform, wallIndex, dispatch)}
          {createGroundings(groundings, layout.configArea, layout.configAreaRealWorldTransform, wallIndex, dispatch)}
          {createDrainages(drainages, layout.configArea, layout.configAreaRealWorldTransform, wallIndex, dispatch)}
          {createBlockOuts(blockOuts, layout.configArea, layout.configAreaRealWorldTransform, wallIndex, dispatch)}
          {createCRails(cRails, layout.configArea, layout.configAreaRealWorldTransform, wallIndex, dispatch)}
        </Layer>
        {createBlockOutCrossHairLayer()}
        {createCRailCrossHairLayer()}
        {createCircularElementCrossHairLayer()}
      </Stage>
      }
    </div>
  )
}

export const ElevationPlan = props => {
  return (
    <AutoResizeDiv>
      <Plan {...props}/>
    </AutoResizeDiv>
  )
}
