import {planActionTypes as actionTypes, planApiActionTypes as apiActionTypes} from "../constants";
import {isLengthValid, isWallWidthValid, isWidthValid} from "../validation/planValidation";
import {initialState as initialVaultState, vaultReducer} from "./vaultReducer";
import {floorReducer} from "./floorReducer";
import {lidReducer} from "./lidReducer";

const initialState = {
  description: '',
  length: 200,
  width: 100,
  wallWidth: 15,
  vault: initialVaultState
};

const planRootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.reset:
      return initialState;

    case apiActionTypes.readPlanSuccess:
      return action.data;

    case actionTypes.setDescription:
      return {...state, description: action.data};

    case actionTypes.setLength:
      if (Number.isInteger(action.data) && isLengthValid(action.data)) {
        return {...state, length: action.data};
      }
      return state;

    case actionTypes.setWidth:
      if (Number.isInteger(action.data) && isWidthValid(action.data)) {
        return {...state, width: action.data};
      }
      return state;

    case actionTypes.setWallWidth:
      if (Number.isInteger(action.data) && isWallWidthValid(action.data)) {
        return {...state, wallWidth: action.data};
      }
      return state;

    default:
      return state;
  }
};

const planChildrenReducer = (state, action) => {
  const {vault, lid, floor, ...others} = state
  let result = {...others}
  const newVault = vaultReducer(vault, action, state)

  if (newVault) result = {...result, vault: newVault}
  const newLid = lidReducer(lid, action)

  if (newLid) result = {...result, lid: newLid}
  const newFloor = floorReducer(floor, action)

  if (newFloor) result = {...result, floor: newFloor}
  if (
    state.vault === result.vault
    && state.lid === result.lid
    && state.floor === result.floor
  ) {
    return state
  }
  return result
}

export const planReducer = (state = initialState, action) => {
  const state1 = planRootReducer(state, action)

  return planChildrenReducer(state1, action)
}
