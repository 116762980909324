import {authHeader} from '../helpers/authHeader';
import {handleResponse} from "./httpHelper";
import {apiUrls} from "../constants";

const apiUrl = apiUrls.projectsEndpoint;

const createProject = project => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader()
    },
    body: JSON.stringify(project)
  };

  return fetch(apiUrl, requestOptions)
    .then(handleResponse);
};

const readProjects = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader()
    }
  };

  return fetch(apiUrl, requestOptions)
    .then(handleResponse);
};

export const projectService = {
  createProject,
  readProjects
};
