import React from "react";
import {Text} from "react-konva";
import {FONT_SIZE} from "../../../constants";
import {
  handleDragEnd as handleElementDragEnd,
  handleDragStart as handleElementDragStart
} from "../ElevationPlan/elementFunctions";
import {
  renderManholeToolbarDragPreview,
  renderManholeToolbarIcon
} from "../LidPlan/manholeRenderFactory";

const getToolbarItemDefinitions = lidType => {
  switch (lidType) {
    case 'A15':
    case 'B125':
      return [
        {
          toolBarItemType: 'manhole',
          name: 'V2A',
          type: 'V2A',
          width: 50,
          height: 100
        },
        {
          toolBarItemType: 'manhole',
          name: 'V2A-10',
          type: 'V2A-10',
          width: 47,
          height: 68
        }
      ]

    default:
      return []
  }
}

const renderItemLabel = (x, y, width, text1, text2) => {
  return (
    <React.Fragment>
      <Text
        x={x}
        y={y}
        width={width}
        align="center"
        text={text1}
        fontSize={FONT_SIZE}
      />
      <Text
        x={x}
        y={y + FONT_SIZE + 2}
        width={width}
        align="center"
        text={text2}
        fontSize={FONT_SIZE}
      />
    </React.Fragment>
  )
}

const renderToolbarIcon = (item, x, y, width, height, scale) => {
  const iconWidth = item.width * scale
  const iconHeight = item.height * scale
  const iconX = x + (width - iconWidth) / 2
  const screenRect = {x: iconX, y, width: iconWidth, height: iconHeight}

  return renderManholeToolbarIcon(screenRect, item)
}

const renderToolbarItemDragPreview = (item, dragEndHandler, x, y, width, height, scale) => {
  const previewWidth = item.width * scale
  const previewHeight = item.height * scale
  const screenRect = {x, y, width: previewWidth, height: previewHeight}
  const onDragStart = handleElementDragStart
  const onDragEnd = e => {
    dragEndHandler({x: e.target.x(), y: e.target.y()})
    handleElementDragEnd(e)
  }

  return renderManholeToolbarDragPreview(screenRect, onDragStart, onDragEnd)
}

const getMinScale = (itemWidth, itemHeight, toolbarItemDefinitions) => {
  const getMax = (itemDefinitions, getValueFunc) => {
    return Math.max(...itemDefinitions.map(i => getValueFunc(i)))
  }
  const minXScale = itemWidth / getMax(toolbarItemDefinitions, curr => curr.width)
  const minYScale = itemHeight / getMax(toolbarItemDefinitions, curr => curr.height)
  return Math.min(minXScale, minYScale)
}

export const renderToolbar = (lidType, toolbarItemDragPreviewDragEndHandler, layout) => {
  const toolBarItemDefinitions = getToolbarItemDefinitions(lidType)
  const itemWidth = Math.min(layout.screenWidth / toolBarItemDefinitions.length, layout.screenHeight)
  const itemHeight = layout.screenHeight
  const iconY = layout.screenY + 10 + 2 * FONT_SIZE
  const iconHeight = itemHeight - iconY
  const iconScale = getMinScale(itemWidth, iconHeight, toolBarItemDefinitions)

  return toolBarItemDefinitions.map((curr, i) => {
    const x = layout.screenX + i * itemWidth
    const y = layout.screenY

    return (
      <React.Fragment key={'toolbar_item_' + i}>
        {renderItemLabel(x, y, itemWidth, curr.name, 'line2')}
        {renderToolbarIcon(curr, x, iconY, itemWidth, iconHeight, iconScale)}
        {renderToolbarItemDragPreview(
          curr,
          screenPos => toolbarItemDragPreviewDragEndHandler(curr, screenPos),
          x,
          iconY,
          itemWidth,
          iconHeight,
          layout.scale
        )}
      </React.Fragment>
    )
  })
}

