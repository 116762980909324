import React from 'react';

import {makeStyles, Paper, useTheme} from "@material-ui/core";
import {orangeBackgroundColor} from "../../../constants";
import Grid from "@material-ui/core/Grid";

import {ComponentsSection} from "./ComponentsSection"
import {GroundPlanSection} from "./GroundPlanSection";
import {ElevationPlanSection} from "./ElevationPlanSection";

const useStyles = theme =>  {
  return makeStyles({
    plan: {
      background: orangeBackgroundColor,
      padding: theme.spacing(6)
    }
  })();
};

export const Step1 = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Paper square={true} className={classes.plan}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ComponentsSection/>
        </Grid>
        <Grid item xs={6}>
          <GroundPlanSection/>
        </Grid>
        <Grid item xs={6}>
          <ElevationPlanSection/>
        </Grid>
      </Grid>
    </Paper>
  )
};
