import React from "react";

import {Button, Grid, makeStyles, Paper} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch, useHistory} from "react-router-dom";

import {orangeButtonStyle, grayBackgroundColor} from "../../constants";
import {
  authenticationActions,
  planActions,
  vaultActions,
  planApiActions,
  projectActions,
  projectApiActions
} from "../../actions/";
import {isProjectValid} from "../../validation/projectValidation";
import {isLoginRequired} from "../../helpers/authHelper";
import {getNextStep, getPreviousStep, getStepByRoute} from "./configurationSteps";

const useStyles = makeStyles(() => ({
  bottomBar: {
    background: grayBackgroundColor,
    padding: '1em',
  },
  orangeButton: orangeButtonStyle
}));

const ContextActions = () => {
  const history = useHistory();
  const authentication = useSelector(state => state.authentication);
  const dispatch = useDispatch();
  const plan = useSelector(state => state.plan);
  const match = useRouteMatch();
  const step = parseInt(match.params.step);

  switch (step) {
    case 1:
      return (
        <Button
          color="primary"
          onClick={() => {
            dispatch(planActions.reset());
            dispatch(projectActions.reset());
            dispatch(projectApiActions.resetProjects());
            dispatch(planApiActions.resetPlans());
          }}>
          Alle Eingaben zurücksetzen
        </Button>
      );

    case 3:
    case 5:
      return (
        <Button
          color="primary"
          onClick={() => dispatch(vaultActions.mirrorFromOppositeSide(step - 2))}
        >
          Spiegelverkehrt von gegenüberliegenden Seite kopieren
        </Button>
      );

    case 6:
      if (authentication.loggedIn === true) {
        return (
          <Button
            color="primary"
            onClick={() => {
              if (isLoginRequired(authentication)) {
                history.push("/login?navigateTo=/step/" + step);
                return;
              }
              dispatch(authenticationActions.extendLoginIfRequired());
              dispatch(planApiActions.createPlan(plan, history));
              history.push('/apirequest/createplan?navigateTo=/step/' + step);
            }}
          >
            In meinem Profil speichern
          </Button>
        );
      } else {
        return (
          <Button
            color="primary"
            onClick={() =>
              history.push("/login?navigateTo=/step/" + step)
            }
          >
            In meinem Profil speichern? Jetzt anmelden...
          </Button>
        );
      }

    default:
      return null;
  }
};

const StepNavigation = () => {
  const classes = useStyles();
  const history = useHistory();
  const plan = useSelector(state => state.plan);
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const step = getStepByRoute(match.url)

  return (
    <React.Fragment>
      {(getNextStep(plan, step.id) !== null)
        ? <Grid item>
          <Button
            className={classes.orangeButton}
            color="primary"
            variant="contained"
            onClick={() => {
              history.push("/step/" + (getNextStep(plan, step.id)).id);
              dispatch(authenticationActions.extendLoginIfRequired());
            }}
          >
            Weiter
          </Button>
        </Grid>
        : null
      }
      <Grid item>
        <Button
          color="primary"
          disabled={!getPreviousStep(plan, step.id)}
          onClick={() => {
            history.push("/step/" + (getPreviousStep(plan, step.id).id));
            dispatch(authenticationActions.extendLoginIfRequired());
          }}
        >
          Zurück
        </Button>
      </Grid>
    </React.Fragment>
  );
};

const SubmitActions = () => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const step = parseInt(match.params.step);
  const dispatch = useDispatch();
  const plan = useSelector(state => state.plan);
  const project = useSelector(state => state.project);
  const authentication = useSelector(state => state.authentication);

  if (authentication.loggedIn === true) {
    return (
      <React.Fragment>
        <Grid item>
          <Button
            className={classes.orangeButton}
            color="primary"
            variant="contained"
            onClick={() => {
              if (isProjectValid(project)) {
                if (isLoginRequired(authentication)) {
                  history.push("/login?navigateTo=/step/" + step);
                  return;
                }
                dispatch(authenticationActions.extendLoginIfRequired());
                dispatch(projectApiActions.createProject(project, plan, 'offer'));
                history.push('/apirequest/createproject?requestType=offer&navigateTo=/step/7');
              }
            }}
          >
            Offerte anfordern
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.orangeButton}
            color="primary"
            variant="contained"
            onClick={() => {
              if (isProjectValid(project)) {
                if (isLoginRequired(authentication)) {
                  history.push("/login?navigateTo=/step/" + step);
                  return;
                }
                dispatch(authenticationActions.extendLoginIfRequired());
                dispatch(projectApiActions.createProject(project, plan, 'order'));
                history.push('/apirequest/createproject?requestType=order&navigateTo=/step/7');
              }
            }}
          >
            Bestellung auslösen
          </Button>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid item>
          <Button
            className={classes.orangeButton}
            color="primary"
            variant="contained"
            onClick={() => history.push("/login?navigateTo=/step/" + step)}
          >
            Anmelden und Offerte anfordern
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.orangeButton}
            color="primary"
            variant="contained"
            onClick={() => history.push("/login?navigateTo=/step/" + step)}
          >
            Anmelden und Bestellung auslösen
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
};

export const BottomBar = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const step = parseInt(match.params.step);

  return (
    <Paper square={true} className={classes.bottomBar}>
      <Grid container justify="space-between">
        <Grid item>
          <ContextActions step={step}/>
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="row-reverse">
            {step === 7
              ? <SubmitActions/>
              : null
            }
            <StepNavigation/>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

