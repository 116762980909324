import {planApiActionTypes} from "../constants";
import {planService} from "../services";
import {planActions, projectActions} from ".";

export const createPlan = plan => {
  const request = () => {
    return {type: planApiActionTypes.createPlanRequest};
  };
  const success = () => {
    return {type: planApiActionTypes.createPlanSuccess};
  };
  const failure = error => {
    return {type: planApiActionTypes.createPlanFailure, error};
  };

  return dispatch => {
    dispatch(request());
    planService.createPlan(plan)
      .then(
        () => {
          dispatch(success());
          dispatch(projectActions.reset());
          dispatch(planActions.reset());
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

const readPlan = id => {
  const request = () => {
    return {type: planApiActionTypes.readPlanRequest};
  };
  const success = result => {
    //console.log("result:", result);
    return {type: planApiActionTypes.readPlanSuccess, data: result};
  };
  const failure = error => {
    return {type: planApiActionTypes.readPlanFailure, error};
  };

  return dispatch => {
    dispatch(request());
    planService.readPlan(id)
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      )
      .then(
        result => {
          dispatch(projectActions.reset());
          return result;
        });
  };
};

export const resetPlans = () => {
  return {
    type: planApiActionTypes.resetPlans
  };
};

const readPlans = () => {
  const request = () => {
    return {type: planApiActionTypes.readPlansRequest};
  };
  const success = result => {
    //console.log("result:", result);
    return {type: planApiActionTypes.readPlansSuccess, data: result};
  };
  const failure = error => {
    return {type: planApiActionTypes.readPlansFailure, error};
  };

  return dispatch => {
    dispatch(request());
    planService.readPlans()
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      );
  };
};

export const planApiActions = {
  createPlan,
  readPlan,
  resetPlans,
  readPlans
};