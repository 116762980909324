import {Group, Rect, Text} from "react-konva";
import React from "react";
import {FONT_SIZE} from "../../../constants";
import {renderResizeHandle} from "../ElevationPlan/resizeHandles";
import {setOpacity} from "../ElevationPlan/elementFunctions";
import {toRealRect} from "../helper/realWorldTransform";
import {snapRectToGrid} from "../helper/snap";
import {hideCrossHairs, updateCrossHairs} from "../ElevationPlan/BlockOutCrossHairs";

const widthTextSpacing = 4;
const heightTextSpacing = 6;
const positionTextSpacing = 2;

const renderRectangle = screenRect => {
  return (
    <Rect
      width={screenRect.width}
      height={screenRect.height}
      fill="white"
      stroke="black"
      strokeWidth={1}
      shadowColor="black"
      shadowBlur={8}
      shadowOpacity={0}
    />
  )
};

const renderMeasurements = (screenRect, realRect) => {
  return (
    <React.Fragment>
      {
        realRect !== undefined && realRect.width !== undefined && realRect.height !== undefined &&
        (
          <React.Fragment>
            <Text
              name="width"
              x={screenRect.width / 2 - 100}
              y={screenRect.height - FONT_SIZE - widthTextSpacing}
              width={200}
              height={FONT_SIZE}
              fontSize={FONT_SIZE}
              align="center"
              verticalAlign="bottom"
              text={realRect.width}
              fill="black"
            />
            <Text
              name="height"
              x={screenRect.width - 200 - heightTextSpacing}
              y={screenRect.height / 2 - FONT_SIZE / 2}
              width={200}
              height={FONT_SIZE}
              fontSize={FONT_SIZE}
              align="right"
              verticalAlign="middle"
              text={realRect.height}
              fill="black"
            />
          </React.Fragment>
        )
      }
      {
        realRect !== undefined && realRect.x !== undefined && realRect.y !== undefined &&
        <Text
          name="position"
          x={-200 - positionTextSpacing}
          y={screenRect.height + positionTextSpacing}
          width={200}
          height={FONT_SIZE}
          fontSize={FONT_SIZE}
          align="right"
          verticalAlign="top"
          text={realRect.x + "/" + realRect.y}
          fill="black"
        />
      }
    </React.Fragment>
  )
}

const renderGroupElements = (screenRect, manhole) => {
  return (
    <React.Fragment>
      {renderRectangle(screenRect)}
      {renderMeasurements(screenRect, manhole)}
    </React.Fragment>
  )
}

const renderResizeHandles = (screenRect, configAreaLayout, onResizing, onResized) => {
  const toRect = handles => {
    return {
      ...screenRect,
      width: handles.right.x(),
    }
  }
  const dragBoundFunc = pos => {
    return {
      x: Math.max(screenRect.x, Math.min(configAreaLayout.screenX + configAreaLayout.screenWidth, pos.x)),
      y: screenRect.y + screenRect.height / 2
    }
  }

  const resize = (group, screenRect, realRect) => {
    const rect = group.find('Rect');

    rect.width(screenRect.width);
    rect.width(screenRect.width)
    const widthText = group.find('.width');

    widthText.x(screenRect.width / 2 - 100);
    widthText.text(realRect.width);
    const heightText = group.find('.height');

    heightText.x(screenRect.width - 200 - heightTextSpacing);
    heightText.text(realRect.height);
  }

  const handleResizing = (handle, resizeRect, onResizing, configAreaLayout, realWorldTransform) => {
    const resizeResult = onResizing(resizeRect)
    const screenRect = {...resizeRect, width: resizeResult.screenWidth}
    //const screenRect = resizeRect

    resize(handle.parent, screenRect, resizeResult.manhole)


    //setOpacity(blockOut, "resizing");
//    const stage = handle.getStage()
//  updateCrossHairs(configAreaLayout, stage, absoluteScreenRect, snappedRealRect);
  }

  const handleResized = (handle, resizedRect, onResized) => {
    //hideCrossHairs(handle.getStage());
    //setOpacity(handle.parent);
    const resizeResult = onResized(resizedRect);
    const screenRect = {...resizedRect, width: resizeResult.screenWidth}

    resize(handle.parent, screenRect, resizeResult.manhole)
    handle.x(resizeResult.screenWidth)

    return
    const rect = handle.parent.find('Rect')[0];
    rect.width(resizeResult.screenWidth)
  }

  return renderResizeHandle(
    screenRect.width,
    screenRect.height / 2,
    "right",
    'the_resize_handle_key',
    toRect,
    dragBoundFunc,
    (handle, screenRect) => handleResizing(handle, screenRect, onResizing),
    (handle, screenRect) => handleResized(handle, screenRect, onResized),
  )
}

export const renderManholeToolbarIcon = (screenRect, manhole, onClick, onDragEnd) => {
  return (
    <Group
      x={screenRect.x}
      y={screenRect.y}
      onClick={onClick}
      draggable={onDragEnd !== undefined}
      onDragEnd={onDragEnd}
    >
      {renderGroupElements(screenRect, manhole)}
    </Group>
  )
}

export const renderManholeToolbarDragPreview = (screenRect, onDragStart, onDragEnd) => {
  const {x, y} = screenRect

  return (
    <Group
      x={x}
      y={y}
      opacity={0}
      draggable={true}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      origin={{x, y}}
    >
      {renderGroupElements(screenRect)}
    </Group>
  )
}

export const renderManholePlanElement = (
  screenRect,
  configAreaLayout,
  manhole,
  onClick,
  onDragEnd,
  onResizing,
  onResized
) => {
  return (
    <Group
      x={screenRect.x}
      y={screenRect.y}
      onClick={onClick}
      draggable={true}
      onDragEnd={onDragEnd}
    >
      {renderGroupElements(screenRect, manhole)}
      {renderResizeHandles(screenRect, configAreaLayout, onResizing, onResized)}
    </Group>
  )
}
