export const handleResponse = response => {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    //console.log("response:", data);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    }
    return data;
  });
};

