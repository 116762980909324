import React from "react";

import {Text} from "react-konva";

import {sleeves, blockOuts, groundings, drainages, FONT_SIZE, vaultWallItems, cRails} from "../../../constants";
import {vaultActions} from "../../../actions";
import {renderStaticToolbarSleeve, renderDragPreviewToolbarSleeve} from "./SleeveRenderFactory";
import {renderDragPreviewToolbarBlockOut, renderStaticToolbarBlockOut} from "./BlockOutRenderFactory";
import {renderDragPreviewToolbarGrounding, renderStaticToolbarGrounding} from "./GroundingRenderFactory";
import {renderDragPreviewToolbarDrainage, renderStaticToolbarDrainage} from "./DrainageRenderFactory";
import {renderDragPreviewToolbarCRail, renderStaticToolbarCRail} from "./CRailRenderFactory";

const renderItemLabel = (key, x, y, width, text1, text2) => {
  return [
    (
      <Text
        key={key + "_label1"}
        x={x}
        y={y}
        width={width}
        align="center"
        text={text1}
        fontSize={FONT_SIZE}
      />
    ),
    (
      <Text
        key={key + "_label2"}
        x={x}
        y={y + FONT_SIZE + 2}
        width={width}
        align="center"
        text={text2}
        fontSize={FONT_SIZE}
      />
    )
  ];
};

const renderSleeveItem = (key, sleeve, x, y, width, height, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  const maxToolBoxSleeveRadius = Math.min(width, 0.75 * height);
  const toolboxSleeveRadius = sleeve.outerDiameter / 60 * maxToolBoxSleeveRadius;
  const dragEndCallbackHandler =
    snappedPosition => {
      dispatch(vaultActions.addSleeve(wallIndex, {x: snappedPosition.x, y: snappedPosition.y, ...sleeve}));
    };
  const sleeveY = 2 * FONT_SIZE + 10 + toolboxSleeveRadius;

  return [
    renderItemLabel(key, x - width / 2, y - height / 2, width, sleeve.colloquialism, "(" + sleeve.name + ")"),
    renderStaticToolbarSleeve(key, sleeve, x, sleeveY, toolboxSleeveRadius),
    renderDragPreviewToolbarSleeve(key, sleeve, x, sleeveY, configAreaLayout, realWorldTransform, dragEndCallbackHandler)
  ]
};

const renderGroundingItem = (key, grounding, x, y, width, height, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  const maxRadius = Math.min(width, 0.75 * height);
  const radius = grounding.outerDiameter / 60 * maxRadius;
  const dragEndCallbackHandler =
    snappedPosition => {
      dispatch(vaultActions.addGrounding(wallIndex, {x: snappedPosition.x, y: snappedPosition.y, ...grounding}));
    };
  const groundingY = 2 * FONT_SIZE + 10 + radius;

  return [
    renderItemLabel(key, x - width / 2, y - height / 2, width, grounding.colloquialism),
    renderStaticToolbarGrounding(key, grounding, x, groundingY, radius),
    renderDragPreviewToolbarGrounding(key, grounding, x, groundingY, configAreaLayout, realWorldTransform, dragEndCallbackHandler)
  ]
};

const renderDrainageItem = (key, drainage, x, y, width, height, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  const maxRadius = Math.min(width, 0.75 * height);
  const radius = drainage.outerDiameter / 60 * maxRadius;
  const dragEndCallbackHandler =
    snappedPosition => {
      dispatch(vaultActions.addWallDrainage(wallIndex, {x: snappedPosition.x, y: snappedPosition.y, ...drainage}));
    };
  const drainageY = 2 * FONT_SIZE + 10 + radius;

  return [
    renderItemLabel(key, x - width / 2, y - height / 2, width, drainage.colloquialism),
    renderStaticToolbarDrainage(key, drainage, x, drainageY, radius),
    renderDragPreviewToolbarDrainage(key, drainage, x, drainageY, configAreaLayout, realWorldTransform, dragEndCallbackHandler)
  ]
};

const renderBlockOutItem = (key, blockOut, x, y, width, height, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  const symbolWidth = Math.min(0.85 * width, height);
  const symbolHeight = symbolWidth * 0.618;
  const symbolX = x + (width - symbolWidth) * 0.5;
  const symbolY = y + 2 * FONT_SIZE + 10;
  const dragEndCallback = snappedPosition => {
    dispatch(vaultActions.addBlockOut(wallIndex, {x: snappedPosition.x, y: snappedPosition.y, ...blockOut}));
  };
  const labelTop = blockOut.type === 'sagex' ? 'Sagex-' : 'Aussparung';
  const labelBottom = blockOut.type === 'sagex' ? 'Einlage' : '';

  return [
    renderItemLabel(key, x, y, width, labelTop, labelBottom),
    renderStaticToolbarBlockOut(key, blockOut, symbolX, symbolY, symbolWidth, symbolHeight),
    renderDragPreviewToolbarBlockOut(key, blockOut, symbolX, symbolY, configAreaLayout, realWorldTransform, dragEndCallback)
  ];
};

const renderCRailItem = (key, cRail, x, y, width, height, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  const toolboxCRailLength  = Math.min(width, 0.75 * height);
  const dragEndCallback = snappedPosition => {
    dispatch(vaultActions.addCRail(wallIndex, {x: snappedPosition.x, y: snappedPosition.y, ...cRail}));
  };
  const topLabel = 'C-Schiene';
  const bottomLabel = cRail.material === 'stainless' ? 'Edelstahl' : 'verzinkt';
  const labelHeight = 2 * FONT_SIZE + 10
  const symbolHeight = height - labelHeight
  const symbolY = y + labelHeight

  return [
    renderItemLabel(key, x, y, width, topLabel, bottomLabel),
    renderStaticToolbarCRail(key, cRail, toolboxCRailLength, x, symbolY, width),
    renderDragPreviewToolbarCRail(
      key,
      cRail,
      x,
      symbolY,
      width,
      symbolHeight,
      configAreaLayout,
      realWorldTransform,
      dragEndCallback
    )
  ];
};

export const createToolbar = (toolbarLayout, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  let toolbar = [];
  const partialWidth = toolbarLayout.screenWidth / (vaultWallItems.length);

  for (let i = 0; i < sleeves.length; i++) {
    const x = (i + 0.5) * partialWidth + toolbarLayout.screenX;
    const y = toolbarLayout.screenHeight / 2 + toolbarLayout.screenY;
    const renderedSleeve = renderSleeveItem(
      "toolbar_sleeve_" + i,
      sleeves[i],
      x, y,
      partialWidth,
      toolbarLayout.screenHeight,
      configAreaLayout,
      realWorldTransform,
      wallIndex,
      dispatch
    );

    toolbar.push(renderedSleeve);
  }
  for (let i = 0; i < blockOuts.length; i++) {
    const x = (i + sleeves.length) * partialWidth + toolbarLayout.screenX;

    const renderedBlockOut = renderBlockOutItem(
      "toolbar_block_out_" + i,
      blockOuts[i],
      x,
      toolbarLayout.screenY,
      partialWidth,
      toolbarLayout.screenHeight,
      configAreaLayout,
      realWorldTransform,
      wallIndex,
      dispatch
    );

    toolbar.push(renderedBlockOut);
  }
  for (let i = 0; i < groundings.length; i++) {
    const x = (sleeves.length + blockOuts.length + i + 0.5) * partialWidth + toolbarLayout.screenX;
    const y = toolbarLayout.screenHeight / 2 + toolbarLayout.screenY;
    const renderedGrounding = renderGroundingItem(
      "toolbar_grounding_" + i,
      groundings[i],
      x, y,
      partialWidth,
      toolbarLayout.screenHeight,
      configAreaLayout,
      realWorldTransform,
      wallIndex,
      dispatch
    );

    toolbar.push(renderedGrounding);
  }
  for (let i = 0; i < drainages.length; i++) {
    const x = (sleeves.length + blockOuts.length + groundings.length + i + 0.5) * partialWidth + toolbarLayout.screenX;
    const y = toolbarLayout.screenHeight / 2 + toolbarLayout.screenY;
    const renderedDrainage = renderDrainageItem(
      "toolbar_drainage_" + i,
      drainages[i],
      x, y,
      partialWidth,
      toolbarLayout.screenHeight,
      configAreaLayout,
      realWorldTransform,
      wallIndex,
      dispatch
    );

    toolbar.push(renderedDrainage);
  }
  for (let i = 0; i < cRails.length; i++) {
    const x = (sleeves.length + blockOuts.length + groundings.length + drainages.length + i) * partialWidth + toolbarLayout.screenX;
    const renderedCRail = renderCRailItem(
      "toolbar_c_rail_" + i,
      cRails[i],
      x,
      toolbarLayout.screenY,
      partialWidth,
      toolbarLayout.screenHeight,
      configAreaLayout,
      realWorldTransform,
      wallIndex,
      dispatch
    );

    toolbar.push(renderedCRail)
  }

  return toolbar;
};

