import {planApiActionTypes, projectApiActionTypes} from "../constants";

const initialState = {};

export const apiRequestStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case planApiActionTypes.createPlanRequest:
      return {
        request: 'create_plan',
        state: 'pending'
      };

    case planApiActionTypes.createPlanSuccess:
      return {
        request: 'create_plan',
        state: 'success'
      };

    case planApiActionTypes.createPlanFailure:
      return {
        request: 'create_plan',
        state: 'failed',
        error: action.error
      };

    case projectApiActionTypes.createRequest:
      return {
        request: 'create_project',
        state: 'pending'
      };

    case projectApiActionTypes.createSuccess:
      return {
        request: 'create_project',
        state: 'success'
      };

    case projectApiActionTypes.createFailure:
      return {
        request: 'create_project',
        state: 'failed',
        error: action.error
      };



    // todo: implement read plan?

    default:
      return state;
  }
};
