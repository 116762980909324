export const getRealWorldTransform = (realWidth, realHeight, screenWidth) => {
  return {
    scale: realWidth / screenWidth,
    zeroPointYTranslation: realHeight
  };
};

export const getNestedTransform = (parentTransform, realWidth, realHeight) => {
  return {...parentTransform, zeroPointYTranslation: realHeight}
};

export const toScreenPosition = (realPos, transform, targetZero) => {
  let result = {
    x: realPos.x / transform.scale,
    y: (realPos.y - transform.zeroPointYTranslation) / -transform.scale
  };

  if (targetZero !== undefined) {
    result.x += targetZero.x;
    result.y += targetZero.y;
  }
  return result;
};

export const toRealPosition = (screenPos, transform, sourceZero) => {
  let x = screenPos.x;
  let y = screenPos.y;

  if (sourceZero !== undefined) {
    x -= sourceZero.x;
    y -= sourceZero.y;
  }
  return {
    x: x * transform.scale,
    y: y * -transform.scale + transform.zeroPointYTranslation
  }
};

export const toScreenLength = (realLength, transform) => {
  return realLength / transform.scale;
};

export const toRealLength = (screenLength, transform) => {
  return screenLength * transform.scale;
};

export const toScreenRectPosition = (realPos, realSize, transform, targetZero) => {
  const position = {...realPos, y: realPos.y + realSize.height};

  return toScreenPosition(position, transform, targetZero);
};

export const toRealLine = (screenLine, transform, targetZero) => {
  return {
    ...screenLine,
    ...toRealPosition(screenLine, transform, targetZero),
    length: toRealLength(screenLine.length, transform)
  }
}

export const toRealRectPosition = (screenPos, realSize, transform, sourceZero) => {
  const position = toRealPosition(screenPos, transform, sourceZero);
  const y = position.y - realSize.height;

  return {...position, y};
};

export const toScreenRect = (realRect, transform, targetZero) => {
  return {
    ...toScreenRectPosition(realRect, realRect, transform, targetZero),
    width: toScreenLength(realRect.width, transform),
    height: toScreenLength(realRect.height, transform)
  }
};

export const toRealRect = (screenRect, transform, sourceZero) => {
  const realSize = {
    width: toRealLength(screenRect.width, transform),
    height: toRealLength(screenRect.height, transform)
  };

  return {
    ...toRealRectPosition(screenRect, realSize, transform, sourceZero),
    ...realSize
  }
};
