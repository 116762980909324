import React, {useEffect, useState} from "react";

import {CircularProgress, Container, Grid, makeStyles, Paper, Typography, useTheme} from "@material-ui/core";

import {Header} from "../Header";
import {grayBackgroundColor} from "../../constants";
import {PlanGridItem} from "../Plans";
import {useDispatch, useSelector} from "react-redux";
import {projectApiActions} from "../../actions";
import {toFilteredProjects} from "../../helpers/projectSearch";
import {Search} from "../Search";

const useStyles = theme => {
  return makeStyles({
    plan: {
      background: grayBackgroundColor,
      padding: theme.spacing(6)
    }
  })();
};

const TitleGridItem = props => {
  const {setFilter} = props;
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <Grid container direction="row" justify="space-between" alignItems="flex-end"
            style={{marginBottom: theme.spacing(1)}}>
        <Typography component="h1" variant="h4">
          Bestellungen und Offertanfragen
        </Typography>
        <Search
          style={{marginBottom: '30px'}}
          onChange={e => {
            setFilter(e.target.value);
          }}
        />
      </Grid>
      < hr/>
    </Grid>
  );
};

const ProjectPropertyGridItem = props => {
  const {name, value} = props;

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={4}>
          <Typography component="h1" variant="body2">
            {name + ':'}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography style={{whiteSpace:'pre-wrap'}} component="h1" variant="body2">
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const toConstructionSiteString = (street, zip, city) => {
  let result = street;

  if (result.length > 0 && (zip || city)) {
    result += ', ';
  }
  if (zip) {
    result += `${zip} `;
  }
  if (city) {
    result += city;
  }
  return  result;
};

const toContactString = (person, phone) => {
  if (person && phone) {
    return `${person}, ${phone}`;
  }
  if (person) {
    return person;
  }
  if (phone) {
    return phone;
  }
  return '';
};

const toDateString = date => {
  return new Date(date).toLocaleDateString();
};

const ProjectGridItem = props => {
  const {project} = props;
  const created = new Date(project.plan.created);

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={12} container alignItems="center" justify="space-between">
        <Grid item>
          <Typography style={{fontWeight: "bold"}} component="h1" variant="h5">
            {project.description}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h1" variant="body2">
            {created.toLocaleDateString()}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <ProjectPropertyGridItem
            name={"Lieferart"}
            value={project.deliveryType === "delivery" ? "Lieferung" : "Abholung ab Werk"}
          />
          <ProjectPropertyGridItem
            name={"Baustelle"}
            value={toConstructionSiteString(project.street, project.zip, project.city)}
          />
          <ProjectPropertyGridItem
            name={"Baustellennummer"}
            value={project.number}
          />
          <ProjectPropertyGridItem
            name={"Kontaktperson"}
            value={toContactString(project.person, project.phone)}
          />
          <ProjectPropertyGridItem
            name={"Bemerkungen"}
            value={project.remarks}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <ProjectPropertyGridItem
            name={"Menge"}
            value={`${project.quantity} Stk.`}
          />
          <ProjectPropertyGridItem
            name={"Wunschtermin"}
            value={`${toDateString(project.desiredSchedule)} (definitiver Termin in der Auftragsbestätigung)`}
          />
          <ProjectPropertyGridItem
            name={"Kommision"}
            value={project.commission}
          />
          <ProjectPropertyGridItem
            name={"Ihre Bestell-/Auftrags Nr"}
            value={project.usersOrderNumber}
          />
        </Grid>
      </Grid>
      <PlanGridItem plan={project.plan}/>
    </Grid>
  );
};

export const Projects = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const projects = useSelector(state => state.projects);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    dispatch(projectApiActions.readProjects());
  },
    [dispatch]
  );
  return (
    <Container maxWidth="lg" style={{paddingBottom: theme.spacing(4), minWidth:'750px'}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Header/>
        </Grid>
        <Grid item xs={12}>
          <Paper square={true} className={classes.plan}>
            {projects.fetching &&
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <CircularProgress style={{justifySelf: "center"}}/>
              </Grid>
            </Grid>
            }
            {projects.error &&
            <Typography
              component="p"
              variant="body2"
              color="error"
              style={{
                marginTop: theme.spacing(2)
              }}
            >
              {projects.error.toString()}
            </Typography>
            }
            {Array.isArray(projects) &&
            <Grid container spacing={4}>
              <TitleGridItem setFilter={setFilter}/>
              {projects && toFilteredProjects(projects, filter).map(project => {
                return (<ProjectGridItem project={project} key={"plan_item_" + project.id}/>)
              })}
            </Grid>
            }
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};