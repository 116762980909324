import React from "react";

import {Grid, Typography} from "@material-ui/core";

import {GroundPlan} from "../../Plan/GroundPlan/GroundPlan";

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Grundriss
      </Typography>
      < hr/>
    </Grid>
  );
};

const PlanGirdItem = () => {
  return (
    <Grid item xs={12}>
      <GroundPlan/>
    </Grid>
  );
};

export const GroundPlanSection = () => {
  return (
    <Grid container spacing={2}>
      <TitleGridItem/>
      <PlanGirdItem/>
    </Grid>
  );
};
