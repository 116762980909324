import React from "react";
import {Button, ButtonGroup, Grid, makeStyles, Typography} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch, useSelector} from "react-redux";

import {grayButtonStyle, orangeButtonStyle} from "../../../constants";
import {lidActions} from "../../../actions";

const useStyles = () => {
  return makeStyles({
    orangeButton: orangeButtonStyle,
    grayButton: grayButtonStyle,
  })();
};

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Abdeckung
      </Typography>
      < hr/>
    </Grid>
  );
};

const LidTypeGridItem = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lid = useSelector(state => state.plan.lid);

  return (
    <Grid item xs={12}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography component="p" variant="body1">
            Druckprüfung nach EN124, Klasse:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <ButtonGroup
            fullWidth
            variant="contained"
            disabled={!lid}
          >
            <Button
              className={lid && lid.type === "A15" ? classes.orangeButton : classes.grayButton}
              color={lid && lid.type === "A15" ? "primary" : "default"}
              onClick={() => {
                dispatch(lidActions.setLidType("A15"));
              }}
            >
              A15
            </Button>
            <Button
              className={lid && lid.type === "B125" ? classes.orangeButton : classes.grayButton}
              color={lid && lid.type === "B125" ? "primary" : "default"}
              onClick={() => {
                dispatch(lidActions.setLidType("B125"));
              }}
            >
              B125
            </Button>
            <Button
              className={lid && lid.type === "D400" ? classes.orangeButton : classes.grayButton}
              color={lid && lid.type === "D400" ? "primary" : "default"}
              onClick={() => {
                dispatch(lidActions.setLidType("D400"));
              }}
            >
              D400
            </Button>
            <Button
              className={lid && lid.type === "E600" ? classes.orangeButton : classes.grayButton}
              color={lid && lid.type === "E600" ? "primary" : "default"}
              onClick={() => {
                dispatch(lidActions.setLidType("E600"));
              }}
            >
              E600
            </Button>
            <Button
              className={lid && lid.type === "subfloor" ? classes.orangeButton : classes.grayButton}
              color={lid && lid.type === "subfloor" ? "primary" : "default"}
              onClick={() => {
                dispatch(lidActions.setLidType("subfloor"));
              }}
            >
              Unterflur
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BeveledCollarIcon = props => {
  const {isSelected} = props;
  const stroke = isSelected ? "white" : "gray";

  return (
    <svg width="100" height="50" viewBox="-10 -5 120 75">
      <g transform="translate(1.735 -342.98)" fill="none" stroke={stroke}>
        <path d="m0.91086 406.48h63.5v-37.042h-26.458l1e-6 -13.229-37.042 19.05z" strokeLinejoin="round"
              strokeWidth="1.0762"/>
        <path d="m101.45 369.44h-63.5v-23.812h63.5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.0762"/>
        <g strokeWidth=".26458px">
          <path d="m48.536 406.48 15.875-15.875"/>
          <path d="m40.598 406.48 23.812-23.812"/>
          <path d="m56.473 406.48 7.9375-7.9375"/>
          <path d="m32.661 406.48 31.75-31.75"/>
          <path d="m24.723 406.48 37.042-37.042"/>
          <path d="m16.786 406.48 37.042-37.042"/>
          <path d="m8.8484 406.48 37.042-37.042"/>
          <path d="m0.91086 406.48 37.042-37.042"/>
          <path d="m0.91086 398.54 37.042-37.042"/>
          <path d="m0.91086 390.6 31.594-31.594"/>
          <path d="m0.91086 382.67 15.252-15.252"/>
        </g>
      </g>
    </svg>
  );
};

const LiftedCollarIcon = props => {
  const {isSelected} = props;
  const stroke = isSelected ? "white" : "gray";

  return (
    <svg width="100" height="50" viewBox="-10 -5 120 75">
      <g transform="translate(1.735 -342.98)" fill="none" stroke={stroke}>
        <path d="m0.91086 406.48h63.5v-37.042h-26.458v-23.812h-37.042z" strokeLinejoin="round" strokeWidth="1.0762"/>
        <path d="m101.45 369.44h-63.5v-23.812h63.5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.0762"/>
        <g strokeWidth=".26458px">
          <path d="m48.536 406.48 15.875-15.875"/>
          <path d="m40.598 406.48 23.812-23.812"/>
          <path d="m56.473 406.48 7.9375-7.9375"/>
          <path d="m32.661 406.48 31.75-31.75"/>
          <path d="m24.723 406.48 37.042-37.042"/>
          <path d="m16.786 406.48 37.042-37.042"/>
          <path d="m8.8484 406.48 37.042-37.042"/>
          <path d="m0.91086 406.48 37.042-37.042"/>
          <path d="m0.91086 398.54 37.042-37.042"/>
          <path d="m0.91086 390.6 37.042-37.042"/>
          <path d="m0.91086 382.67 37.042-37.042"/>
          <path d="m0.91086 374.73 29.104-29.104"/>
          <path d="m0.91086 366.79 21.167-21.167"/>
          <path d="m0.91086 358.85 13.229-13.229"/>
          <path d="m0.91086 350.92 5.2917-5.2917"/>
        </g>
      </g>
    </svg>
  );
};

const CollarGridItem = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const lid = useSelector(state => state.plan.lid)
  const isDisabled = !lid || lid.type === 'subfloor'

  return (
    <Grid item xs={12}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography component="p" variant="body1"  style={isDisabled ? { color: "rgba(0, 0, 0, 0.38)"} : {}}>
            Betonsockel:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <ButtonGroup fullWidth variant="contained" disabled={isDisabled}>
            <Button
              className={lid && lid.collar === "beveled" ? classes.orangeButton : classes.grayButton}
              color={lid && lid.collar === "beveled" ? "primary" : "default"}
              onClick={() => {
                dispatch(lidActions.setLidCollar("beveled"));
              }}
            >
              <BeveledCollarIcon isSelected={lid && lid.collar === "beveled"}/>
            </Button>
            <Button
              className={lid && lid.collar === "lifted" ? classes.orangeButton : classes.grayButton}
              color={lid && lid.collar === "lifted" ? "primary" : "default"}
              onClick={() => {
                dispatch(lidActions.setLidCollar("lifted"));
              }}
            >
              <LiftedCollarIcon isSelected={lid && lid.collar === "lifted"}/>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FillGridItem = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lid = useSelector(state => state.plan.lid);
  const pavingReady = lid !== undefined && lid.pavingReady !== undefined && lid.pavingReady;
  const isDisabled = !lid || lid.type === 'subfloor'

  return (
    <Grid item xs={12}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
      >
        <FormControlLabel
          disabled={isDisabled}
          control={
            <Checkbox
              color="primary"
              checked={pavingReady}
              onChange={() => {
                if (pavingReady) {
                  dispatch(lidActions.unsetLidPavingReady());
                } else {
                  dispatch(lidActions.setLidPavingReady());
                }
              }}
            />
          }
          label="Für Pflästerung, Deckelfüllung:"
        />
        <Grid item xs={8}>
          <ButtonGroup fullWidth variant="contained" disabled={isDisabled}>
            <Button
              className={lid && lid.fill === "anthracite" ? classes.orangeButton : classes.grayButton}
              color={lid && lid.fill === "anthracite" ? "primary" : "default"}
              onClick={() => {
                dispatch(lidActions.setLidFill("anthracite"));
              }}
            >
              anthrazit
            </Button>
            <Button
              className={lid && lid.fill === "concretegray" ? classes.orangeButton : classes.grayButton}
              color={lid && lid.fill === "concretegray" ? "primary" : "default"}
              onClick={() => {
                dispatch(lidActions.setLidFill("concretegray"));
              }}
            >
              betongrau
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  )
};

export const LidSection = () => {
  return (
    <Grid container spacing={2}>
      <TitleGridItem/>
      <LidTypeGridItem/>
      <CollarGridItem/>
      <FillGridItem/>
    </Grid>
  );
};
