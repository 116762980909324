import React from 'react';

import {Grid, Button, Menu, MenuItem, Fab} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch, useHistory} from "react-router-dom";

import {mannhartWebsite, orangeButtonStyle} from "../constants";
import imgSrcLogo from '../image/logo.png';
import {authenticationActions} from "../actions";
import {isLoginRequired} from "../helpers/authHelper";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "1em",
    paddingRight: '1em',
  },
  logo: {
    width: "100%"
  },
  orangeButton: orangeButtonStyle
}));


const getInitials = user => {
  if (!user) {
    return "??";
  }
  const getInitial = s => {
    return s && s.length > 0 ? s[0].toUpperCase() : "?";
  };

  return getInitial(user.firstname) + getInitial(user.lastname);
};

const getName = user => {
  const fallback = "Beunutzername";
  if (!user) {
    return fallback;
  }
  const firstName = user.firstname && user.firstname.length > 0 ? user.firstname : '';
  const lastName = user.lastname && user.lastname.length > 0 ? user.lastname : '';

  if (firstName.length < 1 && lastName.length < 1) {
    return fallback;
  }
  let name = firstName;

  if (name.length > 0) {
    name += ' ';
  }
  name += lastName;
  return name;
};

const LoggedOutButtons = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const step = parseInt(match.params.step);
  const history = useHistory();

  return (
    <React.Fragment>
      <Grid item>
        <Button
          className={classes.orangeButton}
          color="primary"
          variant="contained"
          onClick={() => history.push("/registration?navigateTo=/step/" + step)}
        >
          Registrieren
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.orangeButton}
          color="primary"
          variant="contained"
          onClick={() => history.push("/login?navigateTo=/step/" + step)}
        >
          Anmelden
        </Button>
      </Grid>
    </React.Fragment>
  );
};

const LoggedInButtons = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector(state => state.user);
  const authentication = useSelector(state => state.authentication);
  const dispatch = useDispatch();
  const initials = getInitials(user);
  const name = getName(user);
  const match = useRouteMatch();
  const step = parseInt(match.params.step);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <Grid container spacing={1} onClick={handleClick}>
        <Grid item>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            className={classes.orangeButton}
          >
            {initials}
          </Fab>
        </Grid>
        <Grid item>
          <KeyboardArrowDownIcon fontSize="large"/>
        </Grid>
      </Grid>
      <Menu
        id="simple-menu"
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Grid
            container
            alignItems="center"
            onClick={handleClick}
          >
            <Grid item xs={3}>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className={classes.orangeButton}
              >
                {initials}
              </Fab>
            </Grid>
            <Grid item xs={8}>
              {name}
            </Grid>
            <Grid item xs={1}>
              <KeyboardArrowUpIcon fontSize="large"/>
            </Grid>
          </Grid>
        </MenuItem>
        <hr/>
        <MenuItem
          onClick={() => {
            handleClose();
            history.push("/step/1")
          }}
        >
          Kabelschacht Konfigurator
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            if (isLoginRequired(authentication)) {
              history.push("/login?navigateTo=/step/" + step);
              return;
            }
            dispatch(authenticationActions.extendLoginIfRequired());
            history.push("/projects")
          }}
        >
          Bestellungen und Offertanfragen
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            if (isLoginRequired(authentication)) {
              history.push("/login?navigateTo=/step/" + step);
              return;
            }
            dispatch(authenticationActions.extendLoginIfRequired());
            history.push("/plans")
          }}
        >
          Persönliche Pläne
        </MenuItem>
        <hr/>
        <MenuItem onClick={() => {
          handleClose();
          dispatch(authenticationActions.logout());
          const currentUrl = window.location.href;

          if (currentUrl.endsWith("plans") || currentUrl.endsWith("projects")) {
            history.push("/step/1")
          }
        }}>
          Abmelden
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export const Header = () => {
  const classes = useStyles();
  const loggedIn = useSelector(state => state.authentication.loggedIn);

  return (
    <Grid
      container
      spacing={1}
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={4}>
        <Button style={{padding:'0px'}} onClick={() => window.location = mannhartWebsite}>
          <img src={imgSrcLogo} alt="MM Mannhart Logo" className={classes.logo}/>
        </Button>
      </Grid>
      <Grid
        container
        item
        direction="row-reverse"
        xs={8}
        alignItems="center"
        spacing={1}
      >
        {
          loggedIn
            ? <LoggedInButtons/>
            : <LoggedOutButtons/>
        }
      </Grid>
    </Grid>
  );
};
