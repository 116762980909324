import React from "react"

import {Arrow, Line, Text} from "react-konva"

import {FONT_SIZE} from "../../constants"

const color = "black"
const arrowStrokeWidth = 0.5
const limiterStrokeWidth = 0.5

const margin = 0.1 // of measurement area height
const labelMargin = 1 // in pixel
const arrowPosition = 0.7 // of measurement row height, from top
const limiterOverlap = 0.1 // of measurement area height

const createArrow = (key, x1, y1, x2, y2) => {
  return (
    <Arrow
      key={key + "_arrow"}
      points={[x1, y1, x2, y2]}
      strokeWidth={arrowStrokeWidth}
      stroke={color}
      fill={color}
      lineCap="round"
      pointerAtBeginning={true}
      pointerWidth={5}
    />
  )
}

const createHorizontalArrowLabel = (key, x, y, width, height, text) => {
  return (
    <Text
      key={key + "_label"}
      x={x - 50}
      y={y}
      width={width + 100}
      height={height}
      align="center"
      verticalAlign="bottom"
      fontSize={FONT_SIZE}
      text={text}
      fill={color}
    />
  )
}

const createVerticalArrowLabel = (key, screenX, screenY, screenHeight, textHeight, text) => {
  const y = screenY + screenHeight

  return (
    <Text
      key={key + "_label"}
      x={screenX}
      y={y + 50}
      width={screenHeight + 100}
      height={textHeight}
      align="center"
      verticalAlign="bottom"
      fontSize={FONT_SIZE}
      text={text}
      fill={color}
      rotation={-90}
    />
  )
}

const createMeasurementLimiter = (key, screenX1, screenY1, screenX2, screenY2) => {
  return (
    <Line
      key={key}
      points={[screenX1, screenY1, screenX2, screenY2]}
      strokeWidth={limiterStrokeWidth}
      stroke={color}
      lineCap="round"
    />
  )
}

const createHorizontalMeasurementLimiters = (
  key,
  screenX,
  screenY,
  screenHeight,
  measurementRows,
  rowOffsets,
  scale,
) => {
  const screenMargin = margin * screenHeight
  const screenRowHeight = (screenHeight - screenMargin) / measurementRows.length
  measurementRows[measurementRows.length - 1].unshift(0)
  let result = []

  for (let i = 0; i < measurementRows.length; i++) {
    const measurements = measurementRows[i]
    const y =
      screenY
      + screenMargin
      + i * screenRowHeight
      + screenRowHeight * arrowPosition
      + limiterOverlap * screenHeight
    let currentX = rowOffsets[i] ? rowOffsets[i] : 0

    result.push(
      measurements.map((value, index) => {
        currentX += value
        const x = currentX * scale + screenX

        return createMeasurementLimiter(
          key + "_overall_limiter_" + i + "_" + index,
          x,
          screenY,
          x,
          y
        )
      })
    )
  }
  return result
}

const createVerticalMeasurementLimiters = (
  key,
  screenX,
  screenY,
  screenWidth,
  scale,
  rowOffsets,
  measurementRows
) => {
  measurementRows[0].unshift(0)
  const screenMargin = margin * screenWidth
  const screenRowsWidth = (screenWidth - screenMargin) / measurementRows.length
  let result = []

  for (let i = 0; i < measurementRows.length; i++) {
    const measurements = measurementRows[i]
    const arrowX =
      screenX
      + screenRowsWidth * i
      + screenRowsWidth * arrowPosition
    const x = arrowX - screenWidth * limiterOverlap
    let currentY = rowOffsets[i] ? rowOffsets[i] : 0

    result.push(
      measurements.map((value, index) => {
        currentY += value
        const currentScreenY = screenY + currentY * scale

        return createMeasurementLimiter(
          key + "_limiter_" + i + "_" + index,
          x,
          currentScreenY,
          screenX + screenWidth,
          currentScreenY
        )
      }))
  }
  return result
}

const createHorizontalMeasurementsRow = (
  key,
  screenX,
  screenY,
  screenHeight,
  measurements,
  scale
) => {
  const textHeight = 0.75 * screenHeight
  const arrowY = screenY + textHeight
  let fromX = screenX

  return measurements.map((measurement, i) => {
    const screenWidth = measurement * scale
    const toX = fromX + screenWidth
    const renderedArrow = createArrow(key + "_" + i, fromX, arrowY, toX, arrowY)
    const renderedLabel = createHorizontalArrowLabel(
      key + "_" + i,
      fromX,
      screenY - labelMargin,
      screenWidth,
      textHeight,
      measurement)

    fromX = toX
    return [
      renderedArrow,
      renderedLabel
    ]
  })
}

const createVerticalMeasurementsRow = (
  key,
  screenX,
  screenY,
  screenWidth,
  measurements,
  scale
) => {
  const textHeight = arrowPosition * screenWidth
  const arrowX = screenX + textHeight
  let fromY = screenY

  return measurements.reverse().map((measurement, i) => {
    const screenHeight = measurement * scale
    const toY = fromY + screenHeight
    const renderedArrow = createArrow("_" + i, arrowX, fromY, arrowX, toY)
    const renderedLabel = createVerticalArrowLabel(
      key + "_" + i,
      screenX - labelMargin,
      fromY,
      screenHeight,
      textHeight,
      measurement)

    fromY = toY
    return [
      renderedArrow,
      renderedLabel
    ]
  })
}

export const createHorizontalMeasurements = (layout, measurementRows, rowOffsets) => {
  const key = "horizontal_measurements"
  const marginHeight = layout.screenHeight * margin
  const rowHeight = (layout.screenHeight - marginHeight) / measurementRows.length
  let result = []

  for (let i = 0; i < measurementRows.length; i++) {
    const measurements = measurementRows[i]
    const screenX = layout.screenX + (rowOffsets[i] ? rowOffsets[i] * layout.scale : 0)

    result.push(
      createHorizontalMeasurementsRow(
        key + "_" + i,
        screenX,
        layout.screenY + marginHeight + i * rowHeight,
        rowHeight,
        measurements,
        layout.scale)
    )
  }
  result.push(
    createHorizontalMeasurementLimiters(
      key,
      layout.screenX,
      layout.screenY,
      layout.screenHeight,
      measurementRows,
      rowOffsets,
      layout.scale
    )
  )
  return result
}

export const createVerticalMeasurements = (layout, measurementRows, rowOffsets = []) => {
  const key = "vertical_measurements"
  const screenWidthWithinMargin = layout.screenWidth * (1 - margin)
  const rowWidth = screenWidthWithinMargin / measurementRows.length
  let result = []

  for (let i = 0; i < measurementRows.length; i++) {
    const measurements = measurementRows[i]
    const screenY = layout.screenY + (rowOffsets[i] ? rowOffsets[i] * layout.scale : 0)

    result.push(
      createVerticalMeasurementsRow(
        key + "_" + i,
        layout.screenX + rowWidth * i,
        screenY,
        rowWidth,
        measurements,
        layout.scale)
    )
  }
  result.push(
    createVerticalMeasurementLimiters(
      key,
      layout.screenX,
      layout.screenY,
      layout.screenWidth,
      layout.scale,
      rowOffsets,
      measurementRows
    )
  )
  return result
}




const getCircularElementsXPositions = circularElements => {
  return circularElements.map(element => {
    return element.x
  })
}

const getLinearElementsXPositions = elements => {
  return elements.reduce(
    (acc, curr) => {
      return acc.concat(
        [curr.x, ...(curr.orientation === 'horizontal' ? [curr.x + curr.length] : [])])
    },
    [])
}

const getRectangularElementsXPositions = elements => {
  return elements.reduce(
    (acc, curr) => {
      return acc.concat(curr.x, curr.x + curr.width)
    },
    [])
}

const getMeasurements = (positions, reverseOrder = false) => {
  if (reverseOrder) {
    positions.sort((a, b) => b - a)
  } else {
    positions.sort((a, b) => a - b)
  }
  const distinctPositions = [...new Set(positions)]
  let current = 0
  let measurements = distinctPositions.map((value) => {
    const width = value - current
    current = value

    return width
  })

  return measurements.filter(val => val > 0)
}

export const getPartialXMeasurements = (circularElements, linearElements, rectangularElements, realWidth) => {
  const xPositions =
    getCircularElementsXPositions(circularElements)
      .concat(getLinearElementsXPositions(linearElements))
      .concat(getRectangularElementsXPositions(rectangularElements))
      .concat(0, realWidth)

  if (xPositions.length < 1) {
    return []
  }
  return getMeasurements(xPositions)
}

const getCircularElementsYPositions = circularElements => {
  return circularElements.map(element => {
    return element.y
  })
}

const getLinearElementsYPositions = elements => {
  return elements.reduce(
    (acc, curr) => {
      return acc.concat(
        [curr.y, ...(curr.orientation === 'vertical' ? [curr.y + curr.length] : [])])
    },
    [])
}

const getRectangularElementsYPositions = elements => {
  return elements.reduce(
    (acc, curr) => {
      return acc.concat(curr.y, curr.y + curr.height)
    },
    [])
}

export const getPartialYMeasurements = (circularElements, linearElements, rectangularElements, realHeight) => {
  const yPositions =
    getCircularElementsYPositions(circularElements)
      .concat(getLinearElementsYPositions(linearElements))
      .concat(getRectangularElementsYPositions(rectangularElements))
      .concat(0, realHeight)

  if (yPositions.length < 1) {
    return []
  }
  return getMeasurements(yPositions)
}

export const createMeasurements = (
  circularElements,
  linearElements,
  rectangularElements,
  innerWidth,
  wallWidth,
  horizontalMeasurementsLayout,
  verticalMeasurementsLayout,
  offsetVerticalMeasurements = false
) => {
  let horizontalMeasurementRows = [[wallWidth, innerWidth, wallWidth]]
  let horizontalOffsets = [0]
  const partialXMeasurements = getPartialXMeasurements(circularElements, linearElements, rectangularElements, innerWidth)

  if (partialXMeasurements.length > 1) {
    horizontalMeasurementRows.unshift(partialXMeasurements)
    horizontalOffsets.unshift(wallWidth)
  }
  let verticalMeasurementRows = [[verticalMeasurementsLayout.realHeight]]
  let verticalOffsets = [0]
  const partialYMeasurements = getPartialYMeasurements(
    circularElements,
    linearElements,
    rectangularElements,
    verticalMeasurementsLayout.realHeight
  )

  if (partialYMeasurements.length > 1) {
    verticalMeasurementRows.push(partialYMeasurements)
    if (offsetVerticalMeasurements) {
      verticalOffsets.push(wallWidth)
    }
  }
  return [
    createHorizontalMeasurements(
      horizontalMeasurementsLayout,
      horizontalMeasurementRows,
      horizontalOffsets),
    createVerticalMeasurements(
      verticalMeasurementsLayout,
      verticalMeasurementRows)
  ]
}
