import React from "react";

import Konva from "konva";
import {Group, Line, Rect, Shape, Text} from "react-konva";

import {FONT_SIZE, PLAN_ITEM_OPACITY} from "../../../constants";
import {toRealRect, toRealRectPosition, toScreenRect} from "../helper/realWorldTransform";
import {snapRectToGrid} from "../helper/snap";
import {hideCrossHairs, updateCrossHairs} from "./BlockOutCrossHairs";
import {renderResizeHandles} from "./rectangularElementsResizeHandles";
import {handleDragStart, setOpacity} from "./elementFunctions";

const widthTextSpacing = 4;
const heightTextSpacing = 6;
const positionTextSpacing = 2;

const renderRectangle = (key, width, height) => {
  return (
    <Rect
      key={key + "_rectangle"}
      width={width}
      height={height}
      fill="white"
      stroke="black"
      strokeWidth={1}
      shadowColor="black"
      shadowBlur={8}
      shadowOpacity={0}
    />);
};

const renderStandardFill = (key, width, height) => {
  const borderWidth = 0.03 * Math.min(width, height);
  const innerCornerX = 0.2 * width;
  const innerCornerY = 0.3 * height;

  return [
    (
      <Shape
        key={key + "_shape"}
        name="fill"
        sceneFunc={(context, shape) => {
          context.beginPath();
          context.moveTo(borderWidth, borderWidth);
          context.lineTo(width - borderWidth, borderWidth);
          context.lineTo(innerCornerX, innerCornerY);
          context.lineTo(borderWidth, height - borderWidth);
          context.closePath();
          context.fillStrokeShape(shape);
        }}
        fill="black"
      />
    )
  ];
};

const renderSagexFill = (key, width, height) => {
  const distance = 40;
  let result = [];
  let length = width + height - distance;

  while (length > 0) {
    const from = {
      x: Math.max(length - height, 0),
      y: Math.min(height, length)
    };
    const to = {
      x: Math.min(width, length),
      y: Math.max(length - width, 0)
    };
    result.push((
      <Line
        key={key + "_line_" + from.x + "_" + from.y}
        name="fill"
        points={[from.x, from.y, to.x, to.y]}
        stroke="gray"
        strokeWidth={1}
      />
    ));
    length -= distance;
  }
  return result;
};

const renderFill = (key, width, height, blockOut) => {
  switch (blockOut.type) {
    case "standard":
      return renderStandardFill(key, width, height);

    case "sagex":
      return renderSagexFill(key, width, height);

    default:
      break;
  }
};

const renderMeasurements = (key, width, height, blockOut) => {
  let result = [];

  if (blockOut !== undefined && blockOut.x !== undefined && blockOut.y !== undefined) {
    result.push([
      <Text
        key={key + "_position_text"}
        name="position"
        x={-200 - positionTextSpacing}
        y={height + positionTextSpacing}
        width={200}
        height={FONT_SIZE}
        fontSize={FONT_SIZE}
        align="right"
        verticalAlign="top"
        text={blockOut.x + "/" + blockOut.y}
        fill="black"
      />,
      <Text
        key={key + "_width_text"}
        name="width"
        x={width / 2 - 100}
        y={height - FONT_SIZE - widthTextSpacing}
        width={200}
        height={FONT_SIZE}
        fontSize={FONT_SIZE}
        align="center"
        verticalAlign="bottom"
        text={blockOut.width}
        fill="black"
      />,
      <Text
        key={key + "_height_text"}
        name="height"
        x={width - 200 - heightTextSpacing}
        y={height / 2 - FONT_SIZE / 2}
        width={200}
        height={FONT_SIZE}
        fontSize={FONT_SIZE}
        align="right"
        verticalAlign="middle"
        text={blockOut.height}
        fill="black"
      />
    ]);
  }
  return result;
};

const renderGroupElements = (key, width, height, blockOut) => {
  return [
    renderRectangle(key, width, height),
    renderFill(key, width, height, blockOut),
    renderMeasurements(key, width, height, blockOut)
  ];
};

const handleDragMove = (e, configAreaLayout, realWorldTransform) => {
  const blockOutGroup = e.target;
  const blockOut = blockOutGroup.attrs.blockOut;
  const stage = blockOutGroup.getStage();
  const x = e.target.x();
  const y = e.target.y();
  const blockOutLength = blockOut.length ? blockOut.length : blockOut.width;
  const absoluteScreenRect = {
    x: x,
    y: y,
    width: blockOutLength * configAreaLayout.scale,
    height: blockOut.height * configAreaLayout.scale
  };
  const realRect = toRealRect(
    absoluteScreenRect,
    realWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  );
  const snappedRealRect = snapRectToGrid(realRect, configAreaLayout.realWidth, configAreaLayout.realHeight);

  updateCrossHairs(configAreaLayout, stage, absoluteScreenRect, snappedRealRect);
};

const handleDragEnd = (e, blockOut, configAreaLayout, realWorldTransform, dragEndCallback) => {
  setOpacity(e.target);
  hideCrossHairs(e.target.getStage());
  const x = e.target.x();
  const y = e.target.y();
  const realPosition = toRealRectPosition(
    {x, y},
    blockOut,
    realWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY});
  const snappedRealRect = snapRectToGrid(
    {x: realPosition.x, y: realPosition.y, width: blockOut.width, height: blockOut.height},
    configAreaLayout.realWidth,
    configAreaLayout.realHeight,
  );

  if (e.target.attrs.origin) { // dragged from toolbar?
    e.target.to({
      opacity: 0,
      duration: 0,
      x: e.target.attrs.origin.x,
      y: e.target.attrs.origin.y,
    });
  } else { // dragged from plan!
    const snappedScreenRect = toScreenRect(
      snappedRealRect,
      realWorldTransform,
      {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
    );

    e.target.to({
      duration: 0.05,
      easing: Konva.Easings.StrongEaseOut,
      x: snappedScreenRect.x,
      y: snappedScreenRect.y
    });
  }
  if (
    x < configAreaLayout.screenX || x > configAreaLayout.screenX + configAreaLayout.screenWidth
    || y < configAreaLayout.screenY || y > configAreaLayout.screenY + configAreaLayout.screenHeight) {
    return;
  }
  dragEndCallback(snappedRealRect);
};

const dragBoundFunc = (pos, screenRect, configAreaLayout) => {
  const minX = configAreaLayout.screenX;
  const maxX = configAreaLayout.screenX + configAreaLayout.screenWidth - screenRect.width;
  const x = Math.max(minX, Math.min(pos.x, maxX));
  const minY = configAreaLayout.screenY;
  const maxY = configAreaLayout.screenY + configAreaLayout.screenHeight - screenRect.height;
  const y = Math.max(minY, Math.min(pos.y, maxY));

  return {x, y};
};

const handleResizing = (handle, resizeRect, configAreaLayout, realWorldTransform) => {
  const blockOut = handle.parent;

  setOpacity(blockOut, "resizing");
  const rect = blockOut.find('Rect');

  rect.x(resizeRect.x);
  rect.y(resizeRect.y);
  rect.width(resizeRect.width);
  rect.height(resizeRect.height);

  const absoluteScreenRect = {
    x: blockOut.x() + resizeRect.x,
    y: blockOut.y() + resizeRect.y,
    width: resizeRect.width,
    height: resizeRect.height
  };
  const realRect = toRealRect(
    absoluteScreenRect,
    realWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  );
  const snappedRealRect = snapRectToGrid(realRect, configAreaLayout.realWidth, configAreaLayout.realHeight);
  const widthText = blockOut.find('.width');

  widthText.x(resizeRect.x + resizeRect.width / 2 - 100);
  widthText.y(resizeRect.y + resizeRect.height - FONT_SIZE - widthTextSpacing);
  widthText.text(snappedRealRect.width);
  const heightText = blockOut.find('.height');

  heightText.x(resizeRect.x + resizeRect.width - 200 - heightTextSpacing);
  heightText.y(resizeRect.y + resizeRect.height / 2 - FONT_SIZE / 2);
  heightText.text(snappedRealRect.height);
  const stage = handle.getStage();

  updateCrossHairs(configAreaLayout, stage, absoluteScreenRect, snappedRealRect);
};

const handleResized = (resizedCallback, handle, resizedRect) => {
  hideCrossHairs(handle.getStage());
  setOpacity(handle.parent);
  resizedCallback(resizedRect);
  const rect = handle.parent.find('Rect')[0];

  rect.x(0);
  rect.y(0);
};

export const renderStaticToolbarBlockOut = (
  key,
  blockOut,
  x,
  y,
  width,
  height
) => {
  return (
    <Group
      key={key + "_static_block_out"}
      x={x}
      y={y}
      opacity={PLAN_ITEM_OPACITY}
    >
      {renderGroupElements(key, width, height, blockOut)}
    </Group>
  );
};

export const renderDragPreviewToolbarBlockOut = (
  key,
  blockOut,
  x,
  y,
  configAreaLayout,
  realWorldTransform,
  dragEndCallback
) => {
  const width = blockOut.width * configAreaLayout.scale;
  const height = blockOut.height * configAreaLayout.scale;

  return (
    <Group
      key={key + "_drag_preview_block_out"}
      x={x}
      y={y}
      draggable
      opacity={0}
      onDragStart={handleDragStart}
      onDragMove={e => handleDragMove(e, configAreaLayout, realWorldTransform)}
      onDragEnd={e => handleDragEnd(e, blockOut, configAreaLayout, realWorldTransform, dragEndCallback)}
      blockOut={blockOut}
      origin={{x: x, y: y}}
    >
      {renderGroupElements(key, width, height, blockOut)}
    </Group>
  );
};

export const renderPlanBlockOut = (
  blockOut,
  configAreaLayout,
  realWorldTransform,
  dragEndCallback,
  clickCallback,
  resizedCallback
) => {
  const key = "plan_block_out_" + blockOut.id;
  const screenRect = toScreenRect(
    blockOut,
    realWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  );

  return (
    <Group
      key={key}
      x={screenRect.x}
      y={screenRect.y}
      draggable
      opacity={PLAN_ITEM_OPACITY}
      onClick={clickCallback}
      onDragStart={handleDragStart}
      onDragMove={e => handleDragMove(e, configAreaLayout, realWorldTransform)}
      onDragEnd={e => handleDragEnd(e, blockOut, configAreaLayout, realWorldTransform, dragEndCallback)}
      dragBoundFunc={pos => dragBoundFunc(pos, screenRect, configAreaLayout)}
      blockOut={blockOut}
    >
      {renderGroupElements(key, screenRect.width, screenRect.height, blockOut)}
      {renderResizeHandles(
        key,
        configAreaLayout,
        screenRect,
        (handle, resizeRect) => handleResizing(handle, resizeRect, configAreaLayout, realWorldTransform),
        (handle, resized, resizedRect) => handleResized(resizedCallback, handle, resized, resizedRect)
      )}
    </Group>
  );
};
