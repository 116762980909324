import {Grid, TextField, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {projectActions} from "../../../actions";
import {useDispatch, useSelector} from "react-redux";
import {getDeadlineError, getMinDeadline, isDeadlineValid} from "../../../validation/projectValidation";

const getInitialDeadline = () => {
  const date = new Date();

  date.setDate(date.getDate() + 7); // one week
  return date.toISOString().slice(0, 10);
};

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Liefertermin
      </Typography>
      < hr/>
    </Grid>
  );
};

const DeadlineGridItem = () => {
  const dispatch = useDispatch();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const deadline = useSelector(state => state.project.desiredSchedule);
  const [currentDeadline, setCurrentDeadline] = useState(deadline);
  const deadlineError = getDeadlineError(currentDeadline);

  useEffect(() => {
    setCurrentDeadline(deadline);
  },
    [deadline]
  );
  if (isFirstRender) {
    setIsFirstRender(false);
    const initialDeadline = getInitialDeadline();

    if (deadline < initialDeadline) {
      dispatch(projectActions.setDesiredSchedule(initialDeadline));
    }
  }
  return (
    <Grid item xs={12}>
      <TextField
        id="desiredSchedule"
        label="Wunschtermin"
        type="date"
        variant="outlined"
        fullWidth
        InputProps={{
          inputProps: {
            min: getMinDeadline()
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        value={currentDeadline}
        error={deadlineError.length > 0}
        helperText={deadlineError}
        onChange={e => {
          setCurrentDeadline(e.target.value);
          if (isDeadlineValid(e.target.value)) {
            dispatch(projectActions.setDesiredSchedule(e.target.value));
          }
        }}
      />
    </Grid>
  );
};

export const DeadlineSection = () => {
  return (
    <Grid container spacing={2}>
      <TitleGridItem/>
      <DeadlineGridItem/>
    </Grid>
  );
};
