import React from "react";

import {Grid, Typography} from "@material-ui/core";

import {ElevationPlan} from "../../Plan/ElevationPlan/ElevationPlan";

const TitleGridItem = props => {
  const {step} = props;
  let viewDescription = '';

  switch (step) {
    case 2:
      viewDescription = "A";
      break;
    case 3:
      viewDescription = "B";
      break;
    case 4:
      viewDescription = "C";
      break;
    case 5:
      viewDescription = "D";
      break;
    default:
      viewDescription = "";
  }

  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        {"Ansicht " + viewDescription}
      </Typography>
      < hr/>
    </Grid>
  );
};

const PlanGridItem = props => {
  const {step} = props;

  return (
    <Grid item xs={12}>
      <ElevationPlan currentStep={step} isToolbarVisible={false}/>
    </Grid>
  );
};

export const ElevationPlanSection = props => {
  const {step} = props;

  return (
    <Grid container spacing={4}>
      <TitleGridItem step={step}/>
      <PlanGridItem step={step}/>
    </Grid>
  );
};

