export const planActionTypes = {
  reset: 'RESET_PLAN',
  setDescription: "SET_PLAN_DESCRIPTION",
  setLength: 'SET_LENGTH',
  setWidth: 'SET_WIDTH',
  setWallWidth: 'SET_WALL_WIDTH',
  setVault: 'SET_VAULT', // new
  unsetVault: "UNSET_VAULT", // new
  setLid: 'SET_LID',
  unsetLid: 'UNSET_LID',
  setFloor: 'SET_FLOOR',
  unsetFloor: 'UNSET_FLOOR',
  setVaultHeight: 'SET_VAULT_HEIGHT', // renamed (was setHeight)
  addSleeve: 'ADD_SLEEVE',
  removeSleeve: 'REMOVE_SLEEVE',
  moveSleeve: 'MOVE_SLEEVE',
  addBlockOut: 'ADD_BLOCK_OUT',
  removeBlockOut: 'REMOVE_BLOCK_OUT',
  moveBlockOut: 'MOVE_BLOCK_OUT',
  resizeBlockOut: 'RESIZE_BLOCK_OUT',
  addCRail: 'ADD_C_RAIL', // new
  removeCRail: 'REMOVE_C_RAIL', // new
  moveCRail: 'MOVE_C_RAIL', // new
  resizeCRail: 'RESIZE_C_RAIL', // new
  addGrounding: 'ADD_GROUNDING', // new
  removeGrounding: 'REMOVE_GROUNDING', // new
  moveGrounding: 'MOVE_GROUNDING', // new
  addWallDrainage: 'ADD_WALL_DRAINAGE', // new
  removeWallDrainage: 'REMOVE_WALL_DRAINAGE', // new
  moveWallDrainage: 'MOVE_WALL_DRAINAGE', // new
  mirrorFromOppositeSide: 'MIRROR_FROM_OPPOSITE_SIDE',
  setLidType: 'SET_LID_TYPE',
  setLidCollar: 'SET_LID_COLLAR',
  setLidPavingReady: 'SET_LID_PAVING_READY',
  unsetLidPavingReady: 'UNSET_LID_PAVING_READY',
  setLidFill: 'SET_LID_FILL',
  addManhole: 'ADD_MANHOLE',
  removeManhole: 'REMOVE_MANHOLE',
  moveManhole: 'MOVE_MANHOLE',
  resizeManhole: 'RESIZE_MANHOLE',
  setFloorThickness: 'SET_FLOOR_THICKNESS', // new
  addFloorDrainage: 'ADD_FLOOR_DRAINAGE', // new
  removeFloorDrainage: 'REMOVE_FLOOR_DRAINAGE', // new
  moveFloorDrainage: 'MOVE_FLOOR_DRAINAGE', // new
};
