import {authHeader} from '../helpers/authHeader';
import {handleResponse} from "./httpHelper";
import {apiUrls} from "../constants";

const apiUrl = apiUrls.plansEndpoint;

const createPlan = plan => {
  const body = JSON.stringify(plan);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader()
    },
    body
  };

  return fetch(apiUrl, requestOptions)
    .then(handleResponse);
};

const readPlan = id => {
  const url = `${apiUrl}/${id}`;
  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader()
    }
  };

  return fetch(url, requestOptions)
    .then(handleResponse);
};

const readPlans = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader()
    }
  };

  return fetch(apiUrl, requestOptions)
    .then(handleResponse);
};

export const planService = {
  createPlan,
  readPlan,
  readPlans
};
