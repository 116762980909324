import React from "react";

export const StepIcon = props => {
  const {scale, step, isSelected, disabled} = props;
  const color = disabled
    ? "gray"
    : isSelected
      ? "rgba(239, 139, 45, 0.9)"
      : "black"

    if (![2, 3, 4, 5].includes(step)) {
      return null;
    }
  let wall, triangle;

  switch (step) {
    case 2:
      wall = {x: 2, y: 2, width: 10, height: 1};
      triangle = [7, 2, 8.5, 0, 5.5, 0];
      break;

    case 3:
      wall = {x: 2, y: 7, width: 10, height: 1};
      triangle = [7, 8, 8.5, 10, 5.5, 10];
      break;

    case 4:
      wall = {x: 2, y: 2, width: 1, height: 6};
      triangle = [2, 5, 0, 3.5, 0, 6.5];
      break;

    case 5:
      wall = {x: 11, y: 2, width: 1, height: 6};
      triangle = [12, 5, 14, 3.5, 14, 6.5];
      break;

    default:
      break;
  }

  return (
    <svg width={14 * scale} height={10 * scale}>
      <rect
        x={2 * scale} y={2 * scale}
        width={10 * scale} height={6 * scale}
        style={{fill: "none", stroke: color, strokeWidth: 1}}
      />
      <rect
        x={wall.x * scale} y={wall.y * scale}
        width={wall.width * scale} height={wall.height * scale}
        style={{fill: color, stroke: color, strokeWidth: 1}}
      />
      <polygon
        points={triangle.map(i => i * scale)}
        style={{fill: color, stroke: color, strokeWidth: 1}}
      />
    </svg>
  );
};

