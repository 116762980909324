import React from "react";
import {Switch, Route, useRouteMatch} from "react-router-dom";
import {Container, Grid, useTheme} from "@material-ui/core";

import {Header} from "../Header";
import {Stepper} from "./Stepper";
import {Step1} from "./Step1";
import {VerificationStep} from "./VerificationStep";
import {OrderStep} from "./OrderStep"
import {VaultStep} from "./VaultStep/VaultStep";
import {BottomBar} from "./BottomBar";
import {LidStep} from "./LidStep/LidStep";
import {FloorStep} from "./FloorStep/FloorStep";

export const Step = () => {
  const theme = useTheme();
  const match = useRouteMatch();

  return (
    <Container  maxWidth="lg" style={{paddingBottom: theme.spacing(4), minWidth:'750px'}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Route path={`${match.path}/:step`} component={Header}/>
        </Grid>
        <Grid item xs={12}>
          <Route path={`${match.path}/:step`} component={Stepper}/>
        </Grid>
        <Grid item xs={12}>
          <Switch>
            <Route path={`${match.path}/1`} component={Step1}/>
            <Route path={`${match.path}/2`} component={LidStep}/>
            <Route path={`${match.path}/3a`} component={VaultStep}/>
            <Route path={`${match.path}/3b`} component={VaultStep}/>
            <Route path={`${match.path}/3c`} component={VaultStep}/>
            <Route path={`${match.path}/3d`} component={VaultStep}/>
            <Route path={`${match.path}/4`} component={FloorStep}/>
            <Route path={`${match.path}/5`} component={VerificationStep}/>
            <Route path={`${match.path}/6`} component={OrderStep}/>
            <Route path={`${match.path}`} component={Step1}/> {/* todo: 404 or redirect */}
          </Switch>
        </Grid>
        <Grid item xs={12}>
          <Route path={`${match.path}/:step`} component={BottomBar}/>
        </Grid>
      </Grid>
    </Container>
  );
};

