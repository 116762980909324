import React from "react"
import {Text, Layer, Line} from "react-konva"
import {FONT_SIZE} from "../../../constants"

const crossHairColor = "red"
const textColor = "red"

const textWidth = 40
const textHeight = 18
const textDistance = 20

const createLine = (name) => {
  return (
    <Line
      key={name}
      name={name}
      stroke={crossHairColor}
      strokeWidth={0.3}
      points={[]}
    />
  )
}

const createText = (name) => {
  return (
    <Text
      key={name}
      name={name}
      width={textWidth}
      height={textHeight}
      align="center"
      verticalAlign="middle"
      fontSize={FONT_SIZE}
      fill={textColor}
    />
  )
}

export const createCRailCrossHairLayer = () => {
  return (
    <Layer
      name={"cRailCrossHairLayer"}
      visible={true}
    >
      {[
        createText("top_text"),
        createText("bottom_text"),
        createText("left_text"),
        createText("right_text"),
        createLine("center_line_1"),
        createLine("center_line_2"),
        createLine("center_line_3"),
        createLine("center_line_4"),
        createLine("startpoint_line_1"),
        createLine("startpoint_line_2"),
        createLine("endpoint_line_1"),
        createLine("endpoint_line_2"),
      ]}
    </Layer>
  )
}

const isWithinLayout = (line, layout) => {
  const isWithinLayout = (x, y) => {
    return x >= layout.screenX
      && x <= layout.screenX + layout.screenWidth
      && y >= layout.screenY
      && y <= layout.screenY + layout.screenHeight
  }

  if (!isWithinLayout(line.x, line.y)) {
    return false
  }
  if (line.orientation === 'horizontal' && !isWithinLayout(line.x + line.length, line.y)) {
    return false
  }
  return !(line.orientation === 'vertical' && !isWithinLayout(line.x, line.y + line.length));

}

const getShapes = layer => {
  return {
    topText: layer.find('.top_text')[0],
    bottomText: layer.find('.bottom_text')[0],
    leftText: layer.find('.left_text')[0],
    rightText: layer.find('.right_text')[0],
    centerLine1: layer.find('.center_line_1')[0],
    centerLine2: layer.find('.center_line_2')[0],
    centerLine3: layer.find('.center_line_3')[0],
    centerLine4: layer.find('.center_line_4')[0],
    startpointLine1: layer.find('.startpoint_line_1')[0],
    startpointLine2: layer.find('.startpoint_line_2')[0],
    endpointLine1: layer.find('.endpoint_line_1')[0],
    endpointLine2: layer.find('.endpoint_line_2')[0]
  }
}

const getTextPositions = line => {
  const halfTextWidth = textWidth / 2
  const halfTextHeight = textHeight / 2
  const startPoint = line
  const endPoint =
    line.orientation === 'horizontal' ? {x: line.x + line.length, y: line.y} :
      line.orientation === 'vertical' ? {x: line.x, y: line.y - line.length} :
        line

  return {
    left: {x: startPoint.x - textWidth - textDistance, y: startPoint.y - halfTextHeight},
    bottom: {x: startPoint.x - halfTextWidth, y: startPoint.y + textDistance},
    top: {x: endPoint.x - halfTextWidth, y: endPoint.y - textDistance - textHeight},
    right: {x: endPoint.x + textDistance, y: endPoint.y - halfTextHeight}
  }
}

const updateLines = (configAreaLayout, line, shapes, textPositions) => {
  const left = configAreaLayout.screenX
  const right = configAreaLayout.screenX + configAreaLayout.screenWidth
  const top = configAreaLayout.screenY
  const bottom = configAreaLayout.screenY + configAreaLayout.screenHeight
  const startPoint = line

  switch (line.orientation) {
    case 'horizontal': {
      const endPoint = {x: line.x + line.length, y: line.y}
      const centerPoint = {x: line.x + line.length / 2, y: line.y}
      const y = startPoint.y
      const x1 = startPoint.x
      const x2 = endPoint.x

      shapes.centerLine1.points(
        [left, y, Math.max(left, textPositions.left.x), y]
      )
      shapes.centerLine2.points(
        [textPositions.left.x + textWidth, y, centerPoint.x - textWidth / 2, y]
      )
      shapes.centerLine3.points(
        [centerPoint.x + textWidth / 2, y, textPositions.right.x, y]
      )
      shapes.centerLine4.points(
        [Math.min(right, textPositions.right.x + textWidth), y, right, y]
      )
      shapes.startpointLine1.points(
        [x1, top, x1, Math.max(top, textPositions.bottom.y)]
      )
      shapes.startpointLine2.points(
        [x1, Math.min(bottom, textPositions.bottom.y + textHeight), x1, bottom]
      )
      shapes.endpointLine1.points(
        [x2, top, x2, Math.max(top, textPositions.top.y)]
      )
      shapes.endpointLine2.points(
        [x2, Math.min(bottom, textPositions.top.y + textHeight), x2, bottom]
      )
    }
      break

    case 'vertical': {
      const endPoint = {x: line.x, y: line.y - line.length}
      const centerPoint = {x: line.x, y: line.y - line.length / 2}
      const x = startPoint.x
      const y1 = startPoint.y
      const y2 = endPoint.y

      shapes.centerLine1.points(
        [x, bottom, x, Math.min(bottom, textPositions.bottom.y + textHeight), x]
      )
      shapes.centerLine2.points(
        [x, textPositions.bottom.y, x, centerPoint.y + textHeight / 2]
      )
      shapes.centerLine3.points(
        [x, centerPoint.y - textHeight / 2, x, textPositions.top.y + textHeight]
      )
      shapes.centerLine4.points(
        [x, Math.max(top, textPositions.top.y), x, top]
      )
      shapes.startpointLine1.points(
        [left, y1, Math.max(left, textPositions.left.x), y1]
      )
      shapes.startpointLine2.points(
        [Math.min(right, textPositions.left.x + textWidth), y1, right, y1]
      )
      shapes.endpointLine1.points(
        [left, y2, Math.max(left, textPositions.right.x), y2]
      )
      shapes.endpointLine2.points(
        [Math.min(right, textPositions.right.x + textWidth), y2, right, y2]
      )
    }
      break

    default:
  }
}

const updateText = (textShape, measurement, position) => {
  textShape.text(measurement.toString())
  textShape.x(position.x)
  textShape.y(position.y)
}

const updateTexts = (configAreaLayout, snappedRealLine, shapes, textPositions) => {
  const startPoint = snappedRealLine
  const endPoint = {
    x: snappedRealLine.x + (snappedRealLine.orientation === 'horizontal' ? snappedRealLine.length : 0),
    y: snappedRealLine.y + (snappedRealLine.orientation === 'vertical' ? snappedRealLine.length : 0)
  }
  updateText(
    shapes.topText,
    configAreaLayout.realHeight - endPoint.y,
    textPositions.top
  )
  updateText(
    shapes.bottomText,
    startPoint.y,
    textPositions.bottom
  )
  updateText(
    shapes.leftText,
    snappedRealLine.x,
    textPositions.left
  )
  updateText(
    shapes.rightText,
    configAreaLayout.realWidth - endPoint.x,
    textPositions.right
  )
}

export const updateCrossHairs = (configAreaLayout, stage, screenLine, snappedRealLine) => {
  const crossHairLayer = stage.find(".cRailCrossHairLayer")[0]

  if (!isWithinLayout(screenLine, configAreaLayout)) {
    crossHairLayer.hide()
    crossHairLayer.draw()
    return
  }
  crossHairLayer.show()
  const shapes = getShapes(crossHairLayer)
  const textPositions = getTextPositions(screenLine)

  updateLines(configAreaLayout, screenLine, shapes, textPositions)
  updateTexts(configAreaLayout, snappedRealLine, shapes, textPositions)
  crossHairLayer.draw()
}

export const hideCrossHairs = (stage) => {
  const crossHairLayer = stage.find(".cRailCrossHairLayer")[0]

  crossHairLayer.hide()
  crossHairLayer.draw()
}
