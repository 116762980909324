import React from "react";

import {makeStyles, Paper} from "@material-ui/core";

import {orangeBackgroundColor} from "../../../constants";

const useStyles = makeStyles(() => ({
  plan: {
    background: orangeBackgroundColor,
    padding: '2em',
  }
}));

export const FloorStep = () => {
  const classes = useStyles();

  return (
    <Paper square={true} className={classes.plan} elevation={10}>
      {/*
      <ElevationPlan
        wallIndex={wallIndex}
        isToolbarVisible={true}
      />
      */}
    </Paper>
  )
};
