export const CONFIGURATION_STEPS = [
  {id: "1", description: "Abmessungen"},
  {id: "2", description: "Abdeckung"},
  {id: "3a", description: "Ansicht A"},
  {id: "3b", description: "Ansicht B"},
  {id: "3c", description: "Ansicht C"},
  {id: "3d", description: "Ansicht D"},
  {id: "4", description: "Boden"},
  {id: "5", description: "Kontrolle"},
  {id: "6", description: "Lieferung"}
]

export const isStepEnabled = (plan, stepId) => {
  switch (stepId) {
    case '1':
      return true
    case '2':
      return plan.lid
    case '3a':
    case '3b':
    case '3c':
    case '3d':
      return plan.vault
    case '4':
      return plan.floor
    case '5':
    case '6':
      return true
    default:
      return false
  }
}

const getStep = id => {
  const steps = CONFIGURATION_STEPS.filter(s => s.id === id);

  if (steps.length !== 1) {
    return null
  }
  return steps[0]
}

export const getStepByRoute = route => {
  const slashIndex = route.lastIndexOf('/')

  return getStep(route.slice(slashIndex + 1))
}

export const getStepIndex = id => {
  const step = getStep(id)

  return CONFIGURATION_STEPS.indexOf(step)

}

export const getNextStep = (plan, currentStepId) => {
  const currentStepIndex = getStepIndex(currentStepId)

  if (currentStepIndex === -1) {
    return null
  }
  let i = currentStepIndex + 1

  while (i < CONFIGURATION_STEPS.length) {
    const step = CONFIGURATION_STEPS[i]

    if (isStepEnabled(plan, step.id)) {
      return step
    }
    i++
  }
  return null
}

export const getPreviousStep = (plan, currentStepId) => {
  const currentStepIndex = getStepIndex(currentStepId)

  if (currentStepIndex === -1) {
    return null
  }
  let i = currentStepIndex - 1

  while (i > -1) {
    const step = CONFIGURATION_STEPS[i]

    if (isStepEnabled(plan, step.id)) {
      return step
    }
    i--
  }
  return null
}
