import React from 'react';
import {TextField} from "@material-ui/core";

export const Search = props => {
  const {onChange} = props;

  return (
    <TextField
      variant="outlined"
      id="search"
      label="Suche"
      name="search"
      onChange={e => onChange(e)}
    />
  );
};