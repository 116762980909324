import React from "react"
import {useSelector} from "react-redux"

import {Layer, Stage} from "react-konva"
import Konva from "konva"

import {createPart} from "../GroundPlan/Part"
import {
  createHorizontalMeasurements,
  createVerticalMeasurements,
  getPartialXMeasurements,
  getPartialYMeasurements
} from "../Measurement"
import {createVerticallyCenteredGrid} from "../Grid"
import {
  getNestedTransform,
  getRealWorldTransform,
  toRealRectPosition,
  toScreenRect,
  toScreenRectPosition
} from "../helper/realWorldTransform"
import {AutoResizeDiv} from "../AutoResizeDiv"
import {renderToolbar} from "../Toolbar/toolbar";
import {renderManholePlanElement} from "./manholeRenderFactory";

const createLayout = (clientSize, innerLength, innerWidth, wallWidth, isToolbarVisible) => {
  const outerLength = innerLength + 2 * wallWidth
  const outerWidth = innerWidth + 2 * wallWidth
  const measurementsAreaScreenWidth = Math.min(clientSize.width - 4, 65)
  const toolbarScreenHeight = Math.min(clientSize.width - 4, 125)
  const partScreenWidth = (clientSize.width - measurementsAreaScreenWidth - 4)

  const scale = partScreenWidth / outerLength
  const partScreenHeight = outerWidth * scale

  const greatestOuterWidth = Math.max(outerLength, outerWidth)
  const partRealWorldTransform = getRealWorldTransform(greatestOuterWidth, outerWidth, partScreenWidth)
  const configAreaRealWorldTransform = getNestedTransform(partRealWorldTransform, innerLength, innerWidth)

  const overall = {
    screenX: 2,
    screenY: 0,
    screenWidth: clientSize.width - 4,
    screenHeight: isToolbarVisible
      ? toolbarScreenHeight + measurementsAreaScreenWidth + partScreenHeight + 40
      : measurementsAreaScreenWidth + partScreenHeight,
    realWidth: outerWidth,
    realLength: outerLength,
    scale: scale
  }
  const toolbar = {
    screenX: overall.screenX + 10,
    screenY: overall.screenY,
    screenWidth: overall.screenWidth - 15,
    screenHeight: toolbarScreenHeight,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const part =
    isToolbarVisible
      ? {
        screenX: overall.screenX + measurementsAreaScreenWidth,
        screenY: overall.screenY + toolbar.screenHeight + 10,
        screenWidth: outerLength * scale,
        screenHeight: outerWidth * scale,
        realWidth: outerLength,
        realHeight: outerWidth,
        scale: scale
      }
      : {
        screenX: overall.screenX + measurementsAreaScreenWidth,
        screenY: overall.screenY,
        screenWidth: outerLength * scale,
        screenHeight: outerWidth * scale,
        realWidth: outerLength,
        realHeight: outerWidth,
        scale: scale
      }
  // noinspection JSSuspiciousNameCombination
  const configArea = {
    screenX: part.screenX + wallWidth * scale,
    screenY: part.screenY + wallWidth * scale,
    screenWidth: innerLength * scale,
    screenHeight: innerWidth * scale,
    realWidth: innerLength,
    realHeight: innerWidth,
    transform: configAreaRealWorldTransform,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const horizontalMeasurements = {
    screenX: part.screenX,
    screenY: part.screenY + part.screenHeight,
    screenWidth: part.screenWidth,
    screenHeight: measurementsAreaScreenWidth,
    realWidth: outerLength,
    scale: scale
  }
  // noinspection JSSuspiciousNameCombination
  const verticalMeasurements = {
    screenX: overall.screenX,
    screenY: part.screenY,
    screenWidth: measurementsAreaScreenWidth,
    screenHeight: part.screenHeight,
    realHeight: outerWidth,
    scale: scale
  }

  return {
    scale,
    configAreaRealWorldTransform,
    overall, toolbar, part, configArea, horizontalMeasurements, verticalMeasurements
  }
}

const renderManholes = (
  moveItemHandler,
  removeItemHandler,
  previewResizeItemHandler,
  resizeItemHandler,
  configAreaLayout,
  manholes
) => {
  return manholes.map(manhole => {
    const screenZero = {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
    const screenRect = toScreenRect(manhole, configAreaLayout.transform, screenZero)
    const onClick = () => removeItemHandler(manhole.id)
    const onDragEnd = e => {
      const screenPosition = {x: e.target.x(), y: e.target.y()}
      const realPosition = toRealRectPosition(
        screenPosition,
        manhole,
        configAreaLayout.transform,
        {x: configAreaLayout.screenX, y: configAreaLayout.screenY})
      const movedManhole = moveItemHandler({...manhole, x: realPosition.x, y: realPosition.y})
      const newScreenPosition = toScreenRectPosition(movedManhole, movedManhole, configAreaLayout.transform, {
        x: configAreaLayout.screenX,
        y: configAreaLayout.screenY
      })

      e.target.to({
        duration: 0.05,
        easing: Konva.Easings.StrongEaseOut,
        x: newScreenPosition.x,
        y: newScreenPosition.y
      })
    }
    const onResizing = screenRect => {
      const realWidth = screenRect.width / configAreaLayout.scale
      const resizedManhole = previewResizeItemHandler({...manhole, width: realWidth})

      return {
        screenWidth: resizedManhole.width * configAreaLayout.scale,
        manhole: resizedManhole
      }
    }
    const onResized = screenRect => {
      const realWidth = screenRect.width / configAreaLayout.scale
      const resizedManhole = resizeItemHandler({...manhole, width: realWidth})

      return {
        screenWidth: resizedManhole.width * configAreaLayout.scale,
        manhole: resizedManhole
      }
    }

    return renderManholePlanElement(screenRect, configAreaLayout, manhole, onClick, onDragEnd, onResizing, onResized)
  })
}

const getHorizontalMeasurementStops = (layout, manholes) => {
  const wallWidth = (layout.part.realWidth - layout.configArea.realWidth) / 2
  const outerStops = [wallWidth, layout.configArea.realWidth, wallWidth]
  const innerStops = getPartialXMeasurements([], [], manholes, layout.configArea.realWidth)

  return innerStops.length > 1
    ? {stops: [innerStops, outerStops], offsets: [wallWidth, 0]}
    : {stops: [outerStops], offsets: [0]}
}

const getVerticalMeasurementStops = (layout, manholes) => {
  const wallWidth = (layout.part.realHeight - layout.configArea.realHeight) / 2
  const outerStops = [wallWidth, layout.configArea.realHeight, wallWidth]
  const innerStops = getPartialYMeasurements([], [], manholes, layout.configArea.realHeight)

  return innerStops.length > 1
    ? {stops: [outerStops, innerStops], offsets: [0, wallWidth]}
    : {stops: [outerStops], offsets: [0]}
}

const renderMeasurements = (layout, manholes) => {
  const horizontalMeasurementStops = getHorizontalMeasurementStops(layout, manholes)
  const verticalMeasurementStops = getVerticalMeasurementStops(layout, manholes)

  return (
    <React.Fragment>
      {
        createHorizontalMeasurements(
          layout.horizontalMeasurements,
          horizontalMeasurementStops.stops,
          horizontalMeasurementStops.offsets
        )
      }
      {
        createVerticalMeasurements(
          layout.verticalMeasurements,
          verticalMeasurementStops.stops,
          verticalMeasurementStops.offsets
        )
      }
    </React.Fragment>
  )
}

const Plan = props => {
  const {
    isToolbarVisible,
    clientSize,
    addItemHandler,
    moveItemHandler,
    removeItemHandler,
    previewResizeItemHandler,
    resizeItemHandler
  } = props
  const innerLength = useSelector(state => state.plan.length)
  const innerWidth = useSelector(state => state.plan.width)
  const wallWidth = useSelector(state => state.plan.wallWidth)
  const lid = useSelector(state => state.plan.lid)
  const layout = createLayout(clientSize, innerLength, innerWidth, wallWidth, isToolbarVisible)
  const toolbarItemDragPreviewDragEndHandler =
    (toolbarItemDefinition, screenPos) => {
      const realPosition = toRealRectPosition(
        screenPos,
        toolbarItemDefinition,
        layout.configArea.transform,
        {x: layout.configArea.screenX, y: layout.configArea.screenY})

      addItemHandler({...toolbarItemDefinition, x: realPosition.x, y: realPosition.y})
    }

  return (
    <div>
      <React.Fragment/>
      {!isNaN(layout.scale) &&
      <Stage
        width={clientSize.width}
        height={layout.overall.screenHeight}
      >
        <Layer>
          {createPart(layout.part, layout.configArea)}
          {createVerticallyCenteredGrid(layout.configArea, layout.configAreaRealWorldTransform)}
          {renderMeasurements(layout, lid.manholes)}
          {
            isToolbarVisible
              ? renderToolbar(lid.type, toolbarItemDragPreviewDragEndHandler, layout.toolbar)
              : null
          }
          {renderManholes(
            moveItemHandler,
            removeItemHandler,
            previewResizeItemHandler,
            resizeItemHandler,
            layout.configArea,
            lid.manholes
          )}
        </Layer>
      </Stage>
      }
    </div>
  )
}

export const LidPlan = props => {
  return (
    <AutoResizeDiv>
      <Plan {...props}/>
    </AutoResizeDiv>
  )
}
