import React from "react"
import {Circle} from "react-konva"

const strokeWidth = 1
const mouseOverStrokeWidth = 2

export const getHandles = handle => {
  const parent = handle.getParent()

  return {
    top: parent.find('.top')[0],
    left: parent.find('.left')[0],
    bottom: parent.find('.bottom')[0],
    right: parent.find('.right')[0]
  }
}

export const handleMouseOver = e => {
  const handle = e.target
  const layer = handle.getLayer()

  document.body.style.cursor = 'pointer'
  handle.strokeWidth(mouseOverStrokeWidth)
  if (layer) layer.draw()
}

export const handleMouseOut = e => {
  const handle = e.target
  const layer = handle.getLayer()

  document.body.style.cursor = 'default'
  handle.strokeWidth(strokeWidth)

  if (layer) layer.draw()
}

export const handleMouseDown = e => {
  const handle = e.target
  const group = handle.parent

  group.draggable(false)
  handle.moveToTop()
}

export const handleDragStart = e => {
  e.cancelBubble = true
}

const handleDragMove = (e, resizingCallback, handlesToElement) => {
  e.cancelBubble = true
  const handle = e.target
  const handles = getHandles(handle)

  switch (handle.getName(0)) {
    case 'top':
    case 'bottom':
      if (handles.left && handles.right) {
        const y = handles.top.y() + (handles.bottom.y() - handles.top.y()) / 2

        handles.left.y(y)
        handles.right.y(y)
      }
      break

    case 'left':
    case 'right':
      if (handles.top && handles.bottom){
        const x = handles.left.x() + (handles.right.x() - handles.left.x()) / 2

        handles.top.x(x)
        handles.bottom.x(x)
      }
      break

    default:
      break
  }
  resizingCallback(handle, handlesToElement(handles))
}

export const handleDragEnd = (e, resizedCallback, handlesToElementFunction) => {
  e.cancelBubble = true
  const handle = e.target
  const handles = getHandles(handle)

  const resizedElement = handlesToElementFunction(handles)
  switch (handle.name()) {
    case "top": {
      handle.y(0)
      break
    }

    case "left": {
      handle.x(0)
      break
    }

    case "bottom":
    case "right":
    default:
      break
  }
  const group = handle.parent

  group.draggable(true)
  resizedCallback(handle, resizedElement)
}

export const renderResizeHandle = (
  x,
  y,
  name,
  key,
  handlesToElement,
  dragBoundFunc,
  resizingCallback,
  resizedCallback
) => {
  return (
    <Circle
      key={key + "_resize_handle_" + name}
      x={x}
      y={y}
      name={name}
      radius={5}
      fill="lightgray"
      stroke="darkgray"
      strokeWidth={1}
      draggable={true}
      dragontop={false}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onDragStart={handleDragStart}
      onDragMove={e => handleDragMove(e, resizingCallback, handlesToElement)}
      onDragEnd={e => handleDragEnd(e, resizedCallback, handlesToElement)}
      dragBoundFunc={dragBoundFunc}
    />
  )
}
