import React, {useEffect, useState} from "react";

import {Grid, TextField, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";

import {ElevationPlan} from "../../Plan/ElevationPlan/ElevationPlan";
import {
  planLimits,
  getVaultHeightError,
  getWallWidthError,
  isVaultHeightValid,
  isWallWidthValid, getFloorThicknessError, isFloorThicknessValid
} from "../../../validation/planValidation";
import {floorActions, planActions, vaultActions} from "../../../actions";

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Ansicht
      </Typography>
      < hr/>
    </Grid>
  );
};

const HeightField = () => {
  const dispatch = useDispatch()
  const vault = useSelector(state => state.plan.vault)
  const height = vault ? vault.height : 0
  const [currentHeight, setCurrentHeight] = useState(height)
  const error =  !vault ? null : getVaultHeightError(currentHeight)

  useEffect(() => {
      setCurrentHeight(height)
    },
    [height]
  )
  return (
    <TextField
      disabled={!vault}
      type="number"
      InputProps={{
        inputProps: {
          min: planLimits.minHeight,
          max: planLimits.maxHeight,
          step: planLimits.vaultHeightStepWidth
        }
      }}
      variant="outlined"
      fullWidth
      margin="normal"
      id="height"
      label="Höhe [cm]"
      name="height"
      value={currentHeight}
      error={error}
      helperText={error}
      onChange={e => {
        setCurrentHeight(e.target.value);
        if (isVaultHeightValid(e.target.value)) {
          dispatch(vaultActions.setVaultHeight(parseInt(e.target.value)));
        }
      }}
    />
  );
};

const WallWidthField = () => {
  const dispatch = useDispatch();
  const wallWidth = useSelector(state => state.plan.wallWidth);
  const [currentWallWidth, setCurrentWallWidth] = useState(wallWidth);
  const error = getWallWidthError(currentWallWidth);

  useEffect(() => {
      setCurrentWallWidth(wallWidth);
    },
    [wallWidth]
  );
  return (
    <TextField
      type="number"
      InputProps={{
        inputProps: {
          min: planLimits.minWallWidth,
          max: planLimits.maxWallWidth,
          step: planLimits.wallWidthStepWidth
        }
      }}
      variant="outlined"
      fullWidth
      margin="normal"
      id="wallWidth"
      label="Wandstärke [cm]"
      name="wallWidth"
      value={currentWallWidth}
      error={error}
      helperText={error}
      onChange={e => {
        setCurrentWallWidth(e.target.value);
        if (isWallWidthValid(e.target.value)) {
          dispatch(planActions.setWallWidth(parseInt(e.target.value)));
        }
      }}
    />
  );
};

const FloorThicknessField = () => {
  const dispatch = useDispatch()
  const floor = useSelector(state => state.plan.floor)
  const thickness = floor ? floor.thickness : 0
  const [currentThickness, setCurrentThickness] = useState(thickness)
  const error =  !floor ? null : getFloorThicknessError(thickness)

  useEffect(() => {
      setCurrentThickness(thickness)
    },
    [thickness]
  )
  return (
    <TextField
      disabled={!floor}
      type="number"
      InputProps={{
        inputProps: {
          min: planLimits.minFloorThickness,
          max: planLimits.maxFloorThickness,
          step: planLimits.floorThicknessStepWidth
        }
      }}
      variant="outlined"
      fullWidth
      margin="normal"
      id="height"
      label="Bodenstärke [cm]"
      name="thickness"
      value={currentThickness}
      error={error}
      helperText={error}
      onChange={e => {
        setCurrentThickness(e.target.value);
        if (isFloorThicknessValid(e.target.value)) {
          dispatch(floorActions.setFloorThickness(parseInt(e.target.value)));
        }
      }}
    />
  );
};

const InputFieldsGridItem = () => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <HeightField/>
        </Grid>
        <Grid item xs={4}>
          <WallWidthField/>
        </Grid>
        <Grid item xs={4}>
          <FloorThicknessField/>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PlanGridItem = () => {
  return (
    <Grid item xs={12}>
      <ElevationPlan currentStep={1} isToolbarVisible={false}/>
    </Grid>
  );
};

export const ElevationPlanSection = () => {
  return (
    <Grid container spacing={2}>
      <TitleGridItem/>
      <InputFieldsGridItem/>
      <PlanGridItem/>
    </Grid>
  );
};

