import React from 'react';
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
} from '@material-ui/core';
import {makeStyles, useTheme} from "@material-ui/core/styles";

import {whiteBackgroundColor} from '../../constants';
import imgSrcLogo from "../../image/MM_logo.svg";
import {Registration, EmailVerification} from "./Registration";
import {Login} from "./Login";
import {ResetPassword, ChangePassword} from "./ResetPassword";
import {Step} from "../Steps";

import {Route, Redirect, Switch} from "react-router-dom";

const useStyles = () => {
  const theme = useTheme();

  return makeStyles({
    logo: {
      width: '15%',
      margin: 'auto',
    },
    paper: {
      width: '100%',
      marginTop: theme.spacing(4),
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: whiteBackgroundColor
    },
  })();
};

export function Auth() {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{
        marginTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        minWidth:'600px'
      }}
    >
      <CssBaseline/>
      <Grid container>
        <img src={imgSrcLogo} alt="Manhart" className={classes.logo}/>
        <Paper square={true} className={classes.paper}>
          <Switch>
            <Route exact path="/auth" component={Login}/>>
            <Route exact path="/registration" component={Registration}/>>
            <Route exact path="/emailverification" component={EmailVerification}/>>
            <Route exact path="/login" component={Login}/>>
            <Route exact path="/resetpassword" component={ResetPassword}/>>
            <Route exact path="/changepassword" component={ChangePassword}/>>
            <Route path={"/step"} component={Step}/>
            <Route path="/" render={() => {
              return <Redirect to={"/step/1"}/>;
            }}/>
          </Switch>
        </Paper>
      </Grid>
    </Container>
  );
}


