export const sleeves = [
  {
    outerDiameter: 8.5,
    innerDiameter: 6.5,
    name: "K55",
    colloquialism: "K55"
  },
  {
    outerDiameter: 10,
    innerDiameter: 7.5,
    name : "72/60",
    colloquialism: "60"
  },
  {
    outerDiameter: 12.5,
    innerDiameter: 9.5,
    name : "92/80",
    colloquialism: "80"
  },
  {
    outerDiameter: 14.5,
    innerDiameter: 11.5,
    name : "112/100",
    colloquialism: "100"
  },
  {
    outerDiameter: 16.5,
    innerDiameter: 13.5,
    name : "132/120",
    colloquialism: "120"
  },
  {
    outerDiameter: 21.5,
    innerDiameter: 16.5,
    name : "163/148",
    colloquialism: "150"
  },
  {
    outerDiameter: 30,
    innerDiameter: 21.5,
    name : "214/195",
    colloquialism: "200"
  }
]

export const drainages = [
  {
    outerDiameter: 10,
    innerDiameter: 10,
    name : "",
    colloquialism: "PP-Muffe"
  }
]

export const groundings = [
  {
    outerDiameter: 5,
    innerDiameter: 5,
    name : "",
    colloquialism: "Erdung"
  }
]

export const blockOuts = [
  {
    type: "standard",
    width: 50,
    height: 30
  },
  {
    type: "sagex",
    width: 50,
    height: 30
  }
]

export const cRails = [
  {
    material: "stainless",
    orientation: "horizontal",
    length: 30
  },
  {
    material: "stainless",
    orientation: "vertical",
    length: 30
  },
  {
    material: "galvanized",
    orientation: "horizontal",
    length: 30
  },
  {
    material: "galvanized",
    orientation: "vertical",
    length: 30
  }
]

export const vaultWallItems = [...sleeves, ...drainages, ...groundings, ...blockOuts, ...cRails]

