import {planActionTypes as actionTypes} from "../constants";

const reset = () => {
  return {type: actionTypes.reset};
};

const setDescription = description => {
  return {
    type: actionTypes.setDescription,
    data: description
  };
};

const setLength = length => {
  return {
    type: actionTypes.setLength,
    data: length
  };
};

const setWidth = width => {
  return {
    type: actionTypes.setWidth,
    data: width
  };
};

const setWallWidth = wallWidth => {
  return {
    type: actionTypes.setWallWidth,
    data: wallWidth
  };
};

export const planActions = {
  reset,
  setDescription,
  setLength,
  setWidth,
  setWallWidth
};
