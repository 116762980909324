import React, {useEffect, useState} from "react";

import {Grid, TextField, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";

import {projectActions} from "../../../actions";
import {getQuantityError, isQuantityValid} from "../../../validation/projectValidation";

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Menge
      </Typography>
      < hr/>
    </Grid>
  );
};

const QuantityGridItem = () => {
  const dispatch = useDispatch();
  const quantity = useSelector(state => state.project.quantity);
  const length = useSelector(state => state.plan.length);
  const width = useSelector(state => state.plan.width);
  //const height = useSelector(state => state.plan.vault.height); // todo_mga: check vault for null
  const height =  100;
  const wallWidth = useSelector(state => state.plan.wallWidth);
  const [currentQuantity, setCurrentQuantity] = useState(quantity);
  const quantityError = getQuantityError(currentQuantity);

  useEffect(() => {
    setCurrentQuantity(quantity);
  },
    [quantity]
  );
  return (
    <Grid item xs={12} container alignItems="center" spacing={2}>
      <Grid item xs={4}>
        <TextField
          type="number"
          InputProps={{inputProps: {min: 1, max: 1000}}}
          variant="outlined"
          fullWidth
          margin="normal"
          id="quantity"
          name="quantity"
          label="Menge"
          value={currentQuantity}
          error={quantityError.length > 0}
          helperText={quantityError}
          onChange={e => {
            setCurrentQuantity(e.target.value);
            const quantity = parseInt(e.target.value);

            if (isQuantityValid(quantity)) {
              dispatch(projectActions.setQuantity(quantity));
            }
          }}
        />
      </Grid>
      <Grid item>
        <Typography component="p" variant="body1">
          {`Kabelschacht ${length}/${width}/${height}/${wallWidth} gem. Zeichnung`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const QuantitySection = () => {
  return (
    <Grid container spacing={2}>
      <TitleGridItem/>
      <QuantityGridItem/>
    </Grid>
  );
};
