import React, {useEffect, useState} from "react";

import {Container, Grid, makeStyles, Paper, Typography, useTheme, CircularProgress} from "@material-ui/core";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";

import {apiUrls, grayBackgroundColor} from "../../constants";
import {planApiActions} from "../../actions";
import {Header} from "../Header";
import Link from "@material-ui/core/Link";
import {Search} from "../Search";
import {toFilteredPlans} from "../../helpers/projectSearch";

const useStyles = theme => {
  return makeStyles({
    plan: {
      background: grayBackgroundColor,
      padding: theme.spacing(6)
    }
  })();
};

const openPlanOnClicked = (plan, dispatch, history) => {
  dispatch(planApiActions.readPlan(plan.id));
  history.push('/step/1');
};

const TitleGridItem = props => {
  const {setFilter} = props;
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <Grid container direction="row" justify="space-between" alignItems="flex-end"
            style={{marginBottom: theme.spacing(1)}}>
        <Typography xs={5} component="h1" variant="h4">
          Persönliche Pläne
        </Typography>
        <Search
          onChange={e => {
            setFilter(e.target.value);
          }}
        />
      </Grid>
      < hr/>
    </Grid>
  );
};

export const PlanGridItem = props => {
  const {plan} = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const created = new Date(plan.created);

  return (
    <Grid item xs={12}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={1}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={apiUrls.planPdfDownload + "?linkId=" + plan.linkId}
          >
            <PictureAsPdfIcon style={{fontSize: 60, width: "100%"}} color="primary"/>
          </a>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography component="h1" variant="h5" align="right" style={{display: "inline-block"}}>
                    {`${plan.length}`}
                  </Typography>
                  <Typography component="h1" variant="body1" align="right" style={{display: "inline-block"}}>
                    L
                  </Typography>
                  <Typography component="h1" variant="h5" align="right" style={{display: "inline-block"}}>
                    {`/${plan.width}`}</Typography>
                  <Typography component="h1" variant="body1" align="right" style={{display: "inline-block"}}>
                    B
                  </Typography>
                  <Typography component="h1" variant="h5" align="right" style={{display: "inline-block"}}>
                    {`/${plan.height}`}
                  </Typography>
                  <Typography component="h1" variant="body1" align="right" style={{display: "inline-block"}}>
                    H
                  </Typography>
                  <Typography component="h1" variant="h5" align="right" style={{display: "inline-block"}}>
                    {`/${plan.wallWidth}`}
                  </Typography>
                  <Typography component="h1" variant="body1" align="right" style={{display: "inline-block"}}>
                    WS
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      openPlanOnClicked(plan, dispatch, history)
                    }}
                  >
                    <Typography component="p" color="primary" variant="body2" texttransform="none">
                      Als Vorlage öffnen
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container justify="space-between" alignItems={"center"}>
            <Grid item>
              <Typography component="h1" variant="body2">
                {`Plan erstellt: ${user.firstname} ${user.lastname}, ${created.toLocaleString()}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{whiteSpace: 'pre-wrap'}} component="h1" variant="body2">
              {plan.description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Plans = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const plans = useSelector(state => state.plans);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    dispatch(planApiActions.readPlans());
  }, [dispatch]);
  return (
    <Container maxWidth="lg" style={{paddingBottom: theme.spacing(4), minWidth: '750px'}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Header/>
        </Grid>
        <Grid item xs={12}>
          <Paper square={true} className={classes.plan}>
            {plans.fetching &&
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <CircularProgress style={{justifySelf: "center"}}/>
              </Grid>
            </Grid>
            }
            {plans.error &&
            <Typography
              component="p"
              variant="body2"
              color="error"
              style={{
                marginTop: theme.spacing(2)
              }}
            >
              {plans.error.toString()}
            </Typography>
            }
            {Array.isArray(plans) &&
            <Grid container spacing={4}>
              <TitleGridItem setFilter={setFilter}/>
              {plans && toFilteredPlans(plans, filter).map(plan => {
                return (<PlanGridItem plan={plan} key={"plan_item_" + plan.id}/>)
              })}
            </Grid>
            }
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
