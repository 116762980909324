import React from "react"
import {Circle, Line} from "react-konva"

import {
  renderStaticToolbarElement,
  renderDragPreviewToolbarElement,
  renderPlanElement
} from "./circularElementFunctions"

const getGridMeasurements = (radius, halfMeshWidth) => {
  const meshWidth = 2 * halfMeshWidth
  let result = []

  for (let p = halfMeshWidth; p < radius; p += meshWidth) {
    const alpha = Math.acos(p / radius)
    const halfLength = Math.sin(alpha) * radius

    result.push({p, halfLength})
  }
  return result
}

const renderMeshGrid = (key, radius) => {
  const halfMeshWidth = 2
  const createLine = (p1, p2) => {
    return (
      <Line
        key={key + "_" + p1.x + "_" + p1.y + "_" + p2.x + "_" + p2.y}
        name="dimension"
        points={[p1.x, p1.y, p2.x, p2.y]}
        strokeWidth={1}
        stroke="gray"
        lineCap="round"
      />
    )
  }
  const createHorizontalLine = (y, halfLength) => {
    return createLine({x: -halfLength, y}, {x: halfLength, y})
  }
  const createVerticalLine = (x, halfLength) => {
    return createLine({x, y: -halfLength}, {x, y: halfLength})
  }

  return getGridMeasurements(radius, halfMeshWidth).map(m => {
    return [
      createHorizontalLine(m.p, m.halfLength),
      createHorizontalLine(-m.p, m.halfLength),
      createVerticalLine(m.p, m.halfLength),
      createVerticalLine(-m.p, m.halfLength)
    ]
  })
}

/*
const renderHoleGrid = (key, radius) => {
  const halfMeshWidth = 4
  const holeRadius = 4
  const createCircle = (x, y) => {
    return (
      <Circle
        key={key + "_hole_" + x + "_" + y}
        name="dimension"
        x={x} y={y}
        radius={holeRadius}
        stroke="gray"
        strokeWidth={1}
      />)
  }

  return getGridMeasurements(radius - holeRadius, halfMeshWidth)
    .map((y, i, arr) => {
      return arr.filter(x => {
        return x.p < y.halfLength
      }).map(x => {
        return [
          createCircle(y.p, x.p),
          createCircle(y.p, -x.p),
          createCircle(-y.p, x.p),
          createCircle(-y.p, -x.p)
        ]
      })
    })
}
 */

const renderGroupElements = (key, radius) => {
  return [
    (
      <Circle
        key={key + "_circle"}
        radius={radius}
        fill="#eeeeee"
        stroke="black"
        strokeWidth={1}
        shadowColor="black"
        shadowBlur={8}
        shadowOpacity={0}
      />
    ),
    renderMeshGrid(key, radius),
    //renderHoleGrid(key, radius)
  ]
}


export const renderStaticToolbarDrainage = (key, drainage, x, y, radius) => {
  return renderStaticToolbarElement(key + "_static_drainage", drainage, x, y, radius, renderGroupElements)
}

export const renderDragPreviewToolbarDrainage = (
  key,
  drainage,
  x,
  y,
  configAreaLayout,
  realWorldTransform,
  dragEndCallback
) => {
  return renderDragPreviewToolbarElement(
    key + "_drag_preview_drainage",
    drainage,
    x,
    y,
    drainage.outerDiameter / 2 * configAreaLayout.scale,
    configAreaLayout,
    realWorldTransform,
    dragEndCallback,
    renderGroupElements
  )
}

export const renderPlanDrainage = (
  drainage,
  configAreaLayout,
  configAreaRealWorldTransform,
  dragEndCallback,
  clickCallback
) => {
  return renderPlanElement(
   "plan_drainage_" + drainage.id,
    drainage,
    drainage.outerDiameter / 2 * configAreaLayout.scale,
    configAreaLayout,
    configAreaRealWorldTransform,
    dragEndCallback,
    clickCallback,
    renderGroupElements
  )
}
