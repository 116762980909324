import React from 'react';

import {Route, Switch, useRouteMatch} from "react-router-dom";
import {Grid, Container, CssBaseline, Paper} from '@material-ui/core';
import {makeStyles, useTheme} from "@material-ui/core/styles";

import imgSrcLogo from "../../image/MM_logo.svg";
import {grayBackgroundColor} from "../../constants";

import {CreatePlan} from "./CreatePlan";
import {CreateProject} from './';
import {RequestFailed} from "./RequestFailed";

const useStyles = () => {
  const theme = useTheme();

  return makeStyles({
    '@global': {
      body: {
        marginTop: theme.spacing(8),
      },
    },
    logo: {
      width: '15%',
      margin: 'auto',
    },
    paper: {
      width: '100%',
      marginTop: theme.spacing(6),
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: grayBackgroundColor
    },
  })();
};

export const ApiRequest = () => {
  const theme = useTheme();
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <Container component="main" maxWidth="sm" style={{paddingBottom: theme.spacing(4), minWidth:'750px'}}>
      <CssBaseline/>
      <Grid container>
        <img src={imgSrcLogo} alt="Manhart" className={classes.logo}/>
        <Paper square={true} className={classes.paper}>
          <Switch>
            <Route path={`${match.path}/createplan`} component={CreatePlan}/>
            <Route path={`${match.path}/createproject`} component={CreateProject}/>
            <Route path={`${match.path}/requestfailed`} render={(props) => <RequestFailed {...props}/>} />
          </Switch>
        </Paper>
      </Grid>
    </Container>
  );
};

