import React from "react"
import {Circle, Line} from "react-konva"

import {
  renderStaticToolbarElement, renderDragPreviewToolbarElement, renderPlanElement
} from "./circularElementFunctions"

const renderGroupElements = (key, radius) => {
  const createOuterCircle = () => {
    return (
      <Circle
        key={key + "_outer_circle"}
        radius={radius}
        fill="#eeeeee"
        stroke="black"
        strokeWidth={1}
        shadowColor="black"
        shadowBlur={8}
        shadowOpacity={0}
      />
    )
  }
  const createLine = (p1, p2) => {
    return (
      <Line
        key={key + "_" + p1.x + "_" + p1.y + "_" + p2.x + "_" + p2.y}
        name="dimension"
        points={[p1.x, p1.y, p2.x, p2.y]}
        strokeWidth={1}
        stroke="black"
        lineCap="round"
      />
    )
  }

  return [
    createOuterCircle(),
    createLine({x: 0, y: -0.6 * radius}, {x: 0, y: -0.3 * radius}),
    createLine({x: -0.4 * radius, y: 0}, {x: 0.4 * radius, y: 0}),
    createLine({x: -0.25 * radius, y: 0.3 * radius}, {x: 0.25 * radius, y: 0.3 * radius}),
    createLine({x: -0.1 * radius, y: 0.6 * radius}, {x: 0.1 * radius, y: 0.6 * radius})
  ]
}

export const renderStaticToolbarGrounding = (key, grounding, x, y, radius) => {
  return renderStaticToolbarElement(key + "_static_grounding", grounding, x, y, radius, renderGroupElements)
}

export const renderDragPreviewToolbarGrounding = (
  key,
  grounding,
  x,
  y,
  configAreaLayout,
  realWorldTransform,
  dragEndCallback
) => {
  return renderDragPreviewToolbarElement(
    key + "_drag_preview_grounding",
    grounding,
    x,
    y,
    grounding.outerDiameter / 2 * configAreaLayout.scale,
    configAreaLayout,
    realWorldTransform,
    dragEndCallback,
    renderGroupElements
  )
}

export const renderPlanGrounding = (
  grounding,
  configAreaLayout,
  configAreaRealWorldTransform,
  dragEndCallback,
  clickCallback
) => {
  return renderPlanElement(
    "plan_grounding_" + grounding.id,
    grounding,
    grounding.outerDiameter / 2 * configAreaLayout.scale,
    configAreaLayout,
    configAreaRealWorldTransform,
    dragEndCallback,
    clickCallback,
    renderGroupElements
  )
}
