import {renderPlanSleeve} from "./SleeveRenderFactory";
import {vaultActions} from "../../../actions";

const createSleeve = (sleeve, index, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  const dragEndCallbackHandler =
    snappedCoordinate => {
      dispatch(vaultActions.moveSleeve(wallIndex, sleeve.id, snappedCoordinate.x, snappedCoordinate.y));
    };
  const clickCallbackHandler = () => {
    dispatch(vaultActions.removeSleeve(wallIndex, sleeve.id));
  };

  return renderPlanSleeve(sleeve, configAreaLayout, realWorldTransform, dragEndCallbackHandler, clickCallbackHandler);
};

export const createSleeves = (sleeves, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  return sleeves.map((sleeve, i) => {
    return createSleeve(sleeve, i, configAreaLayout, realWorldTransform, wallIndex, dispatch);
  });
};
