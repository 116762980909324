import React from "react"

import Konva from "konva"
import {Group, Rect, Text} from "react-konva"

import {FONT_SIZE, PLAN_ITEM_OPACITY} from "../../../constants"
import {
  toRealLine,
  toRealPosition,
  toScreenLength,
  toScreenPosition
} from "../helper/realWorldTransform"
import {snapCRailToGrid, snapResizeCRail} from "../helper/snap"
import {hideCrossHairs, updateCrossHairs} from "./CRailCrossHairs"
import {renderResizeHandles} from "./linearElementsResizeHandles"
import {handleDragStart, setOpacity} from "./elementFunctions";

const positionTextSpacing = 2

const renderMeasurements = (key, screenLength, screenWidth, cRail) => {
  let result = [];

  if (cRail !== undefined && cRail.x !== undefined && cRail.y !== undefined) {
    result.push(
      <Text
        key={key + "_position_text"}
        name="position"
        x={-200 - positionTextSpacing - (cRail.orientation === 'vertical' ? screenWidth / 2 : 0)}
        y={positionTextSpacing + (cRail.orientation === 'horizontal' ? screenWidth / 2 : 0)}
        width={200}
        height={FONT_SIZE}
        fontSize={FONT_SIZE}
        align="right"
        verticalAlign="top"
        text={cRail.x + "/" + cRail.y}
        fill="black"
      />
    )
  }
  if (cRail !== undefined && cRail.length !== undefined) {
    result.push(
      <Text
        key={key + "_length_text"}
        name="length"
        x={cRail.orientation === 'horizontal' ? screenLength / 2 - 100 : -100}
        y={cRail.orientation === 'horizontal' ? -FONT_SIZE / 2 : -(screenLength + FONT_SIZE) / 2}
        width={200}
        height={FONT_SIZE}
        fontSize={FONT_SIZE}
        align="center"
        verticalAlign="bottom"
        text={cRail.length}
        fill="black"
      />
    )
  }

  return result;

}

const renderGroupElements = (key, screenLength, cRail, dashed, hideMeasurements = false) => {
  const cRailWidth = 3.8
  const screenWidth = screenLength * cRailWidth / cRail.length
  switch (cRail.orientation) {
    case 'horizontal':
      return [
        <
          Rect
          y={-screenWidth / 2}
          key={key + "_rectangle"}
          width={screenLength}
          height={screenWidth}
          stroke={'blue'}
          strokeWidth={1}
          dash={dashed ? [10, 5] : []}
          fill={"white"}
        />,
        !hideMeasurements && renderMeasurements(key, screenLength, screenWidth, cRail)
      ]

    case 'vertical':
      return [
        (
          <Rect
            x={-screenWidth / 2}
            y={-screenLength}
            key={key + "_rectangle"}
            width={screenWidth}
            height={screenLength}
            stroke={'blue'}
            strokeWidth={1}
            dash={dashed ? [10, 5] : []}
            fill={"white"}
          />
        ),
        !hideMeasurements && renderMeasurements(key, screenLength, screenWidth, cRail)
      ]

    default: return []
  }
}

const handleDragMove = (e, configAreaLayout, realWorldTransform) => {
  const cRailGroup = e.target
  const cRail = cRailGroup.attrs.cRail
  const stage = cRailGroup.getStage()
  const x = cRailGroup.x()
  const y = cRailGroup.y()

  const absoluteScreenLine = {
    x: x,
    y: y,
    orientation: cRail.orientation,
    length: cRail.length * configAreaLayout.scale,
  }
  const realLine = toRealLine(
    absoluteScreenLine,
    realWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  )
  const snappedRealCRail = snapCRailToGrid(realLine, configAreaLayout.realWidth, configAreaLayout.realHeight)

  updateCrossHairs(configAreaLayout, stage, absoluteScreenLine, snappedRealCRail)
}

const handleDragEnd = (e, cRail, configAreaLayout, realWorldTransform, dragEndCallback) => {
  setOpacity(e.target)
  hideCrossHairs(e.target.getStage())
  const x = e.target.x()
  const y = e.target.y()
  const realPosition = toRealPosition(
    {x, y},
    realWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY})
  const snappedRealPosition = snapCRailToGrid(
    {x: realPosition.x, y: realPosition.y, orientation: cRail.orientation, length: cRail.length},
    configAreaLayout.realWidth,
    configAreaLayout.realHeight
  )

  if (e.target.attrs.origin) { // dragged from toolbar?
    e.target.to({
      opacity: 0,
      duration: 0,
      x: e.target.attrs.origin.x,
      y: e.target.attrs.origin.y,
    })
  } else { // dragged from plan!
    const snappedScreenPos = toScreenPosition(
      snappedRealPosition,
      realWorldTransform,
      {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
    )

    e.target.to({
      duration: 0.05,
      easing: Konva.Easings.StrongEaseOut,
      x: snappedScreenPos.x,
      y: snappedScreenPos.y
    })
  }

  if (
    x < configAreaLayout.screenX || x > configAreaLayout.screenX + configAreaLayout.screenWidth
    || y < configAreaLayout.screenY || y > configAreaLayout.screenY + configAreaLayout.screenHeight) {
    return
  }
  dragEndCallback(snappedRealPosition)
}

const dragBoundFunc = (pos, orientation, screenLength, configAreaLayout) => {
  const halfCRailScreenWidth = 1.9 * configAreaLayout.scale
  const toBound = (minX, maxX, minY, maxY) => {
    return {
      x: Math.max(minX, Math.min(pos.x, maxX)),
      y: Math.max(minY, Math.min(pos.y, maxY))
    }
  }

  switch (orientation) {
    case 'horizontal': {
      return toBound(
        configAreaLayout.screenX,
        configAreaLayout.screenX + configAreaLayout.screenWidth - screenLength,
        configAreaLayout.screenY + halfCRailScreenWidth,
        configAreaLayout.screenY + configAreaLayout.screenHeight - halfCRailScreenWidth)
    }

    case 'vertical':
      return toBound(
        configAreaLayout.screenX + halfCRailScreenWidth,
        configAreaLayout.screenX + configAreaLayout.screenWidth - halfCRailScreenWidth,
        configAreaLayout.screenY + screenLength,
        configAreaLayout.screenY + configAreaLayout.screenHeight
      )

    default:
      return pos
  }
}

const handleResizing = (handle, resizeLine, configAreaLayout, realWorldTransform) => {
  const cRailGroup = handle.parent

  setOpacity(cRailGroup, "resizing")
  const rectShape = cRailGroup.find('Rect')

  switch (resizeLine.orientation) {
    case 'horizontal':
      rectShape.x(resizeLine.x)
      rectShape.width(resizeLine.length)
      break

    case 'vertical':
      rectShape.y(resizeLine.y - resizeLine.length)
      rectShape.height(resizeLine.length)
      break

    default:
  }

  const absoluteScreenLine = {
    ...resizeLine,
    x: cRailGroup.x() + resizeLine.x,
    y: cRailGroup.y() + resizeLine.y,
    length: resizeLine.length
  }
  const realLine = toRealLine(
    absoluteScreenLine,
    realWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  )
  const direction = handle.name()
  const snappedRealCRail = snapResizeCRail(realLine, configAreaLayout.realWidth, configAreaLayout.realHeight, direction)
  const lengthText = cRailGroup.find('.length')

  switch (resizeLine.orientation)
  {
    case 'horizontal':
      lengthText.x(resizeLine.x + resizeLine.length / 2 - 100)
      break

    case 'vertical':
      lengthText.y(resizeLine.y - (resizeLine.length + FONT_SIZE) / 2)
      break

    default:
  }
  lengthText.text(snappedRealCRail.length)
  const stage = handle.getStage()

  updateCrossHairs(configAreaLayout, stage, absoluteScreenLine, snappedRealCRail)
}

const handleResized = (resizedCallback, handle, resizedLine) => {
  hideCrossHairs(handle.getStage())
  setOpacity(handle.parent)
  const direction = handle.name()
  const newLength = resizedCallback(resizedLine, direction)
  const rectShape = handle.parent.find('Rect')[0]

  switch (resizedLine.orientation) {
    case 'horizontal':
      rectShape.x(0)
      rectShape.width(newLength)
      break

    case 'vertical':
      rectShape.y(0 - newLength)
      rectShape.height(newLength)
      break

    default:
  }
  switch (direction) {
    case 'right':
      handle.x(newLength)
      break

    case 'top':
      handle.y(-newLength)
      break

    case 'bottom':
      handle.y(0)
      break

    default:
  }
}

export const renderStaticToolbarCRail = (key, cRail, cRailLength, toolbarItemX, toolbarItemY) => {
  return (
    <Group
      key={key + "_static_c_rail"}
      x={toolbarItemX + (cRail.orientation === 'vertical' ? cRailLength / 2 : 0)}
      y={toolbarItemY + (cRail.orientation === 'horizontal' ? cRailLength / 2 : cRailLength)}
      opacity={PLAN_ITEM_OPACITY}
    >
      {
        renderGroupElements(key, cRailLength, cRail, false, true)
      }
    </Group>
  )
}

export const renderDragPreviewToolbarCRail = (
  key,
  cRail,
  toolbarItemX,
  toolbarItemY,
  toolbarItemWidth,
  toolbarItemHeight,
  configAreaLayout,
  realWorldTransform,
  dragEndCallback
) => {
  const screenLength = toScreenLength(cRail.length, realWorldTransform)
  const xTranslate = cRail.orientation === 'vertical' ? toolbarItemWidth / 2 : 0
  const yTranslate = cRail.orientation === 'horizontal' ? toolbarItemHeight / 2 : toolbarItemHeight
  const x = toolbarItemX + xTranslate
  const y = toolbarItemY + yTranslate

  return (
    <Group
      key={key + "_drag_preview_c_rail"}
      x={x}
      y={y}
      draggable
      opacity={0}
      onDragStart={handleDragStart}
      onDragMove={e => handleDragMove(e, configAreaLayout, realWorldTransform)}
      onDragEnd={e => handleDragEnd(e, cRail, configAreaLayout, realWorldTransform, dragEndCallback)}
      cRail={cRail}
      origin={{x, y}}
    >
      {renderGroupElements(key, screenLength, cRail, true)}
      <Rect
        x={-xTranslate}
        y={-yTranslate}
        width={toolbarItemWidth}
        height={toolbarItemHeight}
      />

    </Group>
  )
}

export const renderPlanCRail = (
  cRail,
  configAreaLayout,
  realWorldTransform,
  dragEndCallback,
  clickCallback,
  resizedCallback
) => {
  const key = "plan_c_rail_" + cRail.id
  const screenPos = toScreenPosition(
    cRail,
    realWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  )
  const screenLength = toScreenLength(cRail.length, realWorldTransform)

  return (
    <Group
      key={key}
      x={screenPos.x}
      y={screenPos.y}
      draggable
      opacity={PLAN_ITEM_OPACITY}
      onClick={clickCallback}
      onDragStart={handleDragStart}
      onDragMove={e => handleDragMove(e, configAreaLayout, realWorldTransform)}
      onDragEnd={e => handleDragEnd(e, cRail, configAreaLayout, realWorldTransform, dragEndCallback)}
      dragBoundFunc={pos => dragBoundFunc(pos, cRail.orientation, screenLength, configAreaLayout)}
      cRail={cRail}
    >
      {renderGroupElements(key, screenLength, cRail, true)}
      {
        renderResizeHandles(
          key,
          configAreaLayout,
          cRail.orientation,
          screenPos,
          screenLength,
          (handle, resizeRect) => handleResizing(handle, resizeRect, configAreaLayout, realWorldTransform),
          (handle, resized, resizedRect) => handleResized(resizedCallback, handle, resized, resizedRect)
        )
      }
    </Group>
  )
}
