import React, {useEffect, useState} from "react";

import {Grid, TextField, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";

import {GroundPlan} from "../../Plan/GroundPlan/GroundPlan";
import {
  planLimits,
  isLengthValid,
  getLengthError,
  isWidthValid,
  getWidthError
} from "../../../validation/planValidation";
import {planActions} from "../../../actions";

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Grundriss
      </Typography>
      < hr/>
    </Grid>
  );
};

const LengthField = () => {
  const dispatch = useDispatch();
  const length = useSelector(state => state.plan.length);
  const [currentLength, setCurrentLength] = useState(length);
  const error = getLengthError(currentLength);

  useEffect(() => {
      setCurrentLength(length);
    },
    [length]
  );
  return (
    <TextField
      autoFocus
      type="number"
      InputProps={{
        inputProps: {
          min: planLimits.minLength,
          max: planLimits.maxLength,
          step: planLimits.lengthStepWidth
        }
      }}
      variant="outlined"
      fullWidth
      margin="normal"
      id="length"
      label="Länge im Licht [cm]"
      name="length"
      value={currentLength}
      error={error}
      helperText={error}
      onChange={e => {
        setCurrentLength(e.target.value);
        if (isLengthValid(e.target.value)) {
          dispatch(planActions.setLength(parseInt(e.target.value)));
        }
      }}
    />
  );
};

const WidthField = () => {
  const dispatch = useDispatch();
  const width = useSelector(state => state.plan.width);
  const [currentWidth, setCurrentWidth] = useState(width);
  const error = getWidthError(currentWidth);

  useEffect(() => {
      setCurrentWidth(width);
    },
    [width]
  );
  return (
    <TextField
      type="number"
      InputProps={{
        inputProps: {
          min: planLimits.minWidth,
          max: planLimits.maxWidth,
          step: planLimits.widthStepWidth
        }
      }}
      variant="outlined"
      fullWidth
      margin="normal"
      id="length"
      label="Breite im Licht [cm]"
      name="width"
      value={currentWidth}
      error={error}
      helperText={error}
      onChange={e => {
        setCurrentWidth(e.target.value);
        if (isWidthValid(e.target.value)) {
          dispatch(planActions.setWidth(parseInt(e.target.value)));
        }
      }}
    />
  );
};

const InputFieldsGridItem = () => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <LengthField/>
        </Grid>
        <Grid item xs={6}>
          <WidthField/>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PlanGirdItem = () => {
  return (
    <Grid item xs={12}>
      <GroundPlan/>
    </Grid>
  );
};

export const GroundPlanSection = () => {
  return (
    <Grid container spacing={2}>
      <TitleGridItem/>
      <InputFieldsGridItem/>
      <PlanGirdItem/>
    </Grid>
  );
};
