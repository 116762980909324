export const projectApiActionTypes = {
  resetProjects: 'RESET_PROJECTS',

  createRequest: 'CREATE_PROJECT_REQUEST',
  createSuccess: 'CREATE_PROJECT_SUCCESS',
  createFailure: 'CREATE_PROJECT_FAILURE',

  readProjectsRequest: 'READ_PROJECTS_REQUEST',
  readProjectsSuccess: 'READ_PROJECTS_SUCCESS',
  readProjectsFailure: 'READ_PROJECTS_FAILURE'
};