import React, {useState} from 'react';
import {
  Typography,
  Container,
  Button,
  ButtonGroup,
  TextField,
  useTheme, makeStyles, Grid, CircularProgress
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {orangeButtonStyle, grayButtonStyle, dialogStyles, termsAndConditions, privacyPolicy} from '../../../constants';
import {authenticationActions} from "../../../actions";
import {isValidEmail, isValidPassword} from "../../../validation/authValidation";
import * as queryString from "query-string";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = theme => {
  return makeStyles({
    italic: {
      fontStyle: 'italic',
    },
    sectionHeader: {
      marginTop: theme.spacing(4)
    },
    orangeButton: orangeButtonStyle,
    grayButton: grayButtonStyle,
    paragraph: dialogStyles.paragraphStyle
  })();
};

const Success = props => {
  const navigateToQueryString = queryString.parse(props.location.search).navigateTo;
  const navigateTo = navigateToQueryString === undefined ? '/step/1' : navigateToQueryString;
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  return (
    <Container componemt="main" maxWidth="sm">
      <Typography component="h1" variant="h4">
        Konto erfolgreich erstellt
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Besten Dank für die Registrierung. Wir haben für Sie ein Konto erstellt!
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Nachdem Sie Ihre E-Mail Adresse bestätigt haben - wir haben Ihnen soeben ein E-Mail mit einem Link zugesandt -
        können Sie sich direkt anmelden.
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Falls Sie kein E-Mail in Ihrem Postfach finden, prüfen Sie bitte auch Ihren Spam- oder Junk-Mail-Ordner.
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.orangeButton}
        style={{
          margin: theme.spacing(3, 0, 2),
        }}
        onClick={() => history.replace('/login/?navigateTo=' + navigateTo)}
      >
        Anmeldung
      </Button>
    </Container>
  );
};

export const Registration = props => {
  const navigateToQueryString = queryString.parse(props.location.search).navigateTo;
  const navigateTo = navigateToQueryString === undefined ? '/step/1' : navigateToQueryString;
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const history = useHistory();

  // local state
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [firstName, setFistName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [salutation, setSalutation] = useState('Herr');
  const [termsAccepted, setTermsAccepted] = useState(false);

  if (isFirstRender) {
    dispatch(authenticationActions.logout());
    setIsFirstRender(false);
  }
  // redux state
  const registering = useSelector(state => state.authentication.registering);
  const registered = useSelector(state => state.authentication.registered);
  const error = useSelector(state => state.authentication.error);

  if (registered) {
    return <Success location={props.location}/>;
  }
  return (
    <Container componemt="main" maxWidth="sm">
      <Typography component="h1" variant="h4">
        Herzlich Willkommen bei MM&nbsp;Mannhart AG
      </Typography>
      <Typography className={classes.paragraph} component="p" variant="body2">
        Bitte ergänzen Sie folgende Angaben.
      </Typography>
      <form
        name="form"
        className={classes.form}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          setSubmitted(true);
          if (
            salutation
            && firstName
            && lastName
            && isValidEmail(email)
            && isValidPassword(password) && password === passwordConfirmation
            && phone
            && company
            && address
            && zip
            && city
            && termsAccepted
          ) {
            let user = {
              salutation,
              firstname: firstName,
              lastname: lastName,
              phone,
              email,
              password,
              company,
              address,
              zip,
              city
            };
            console.log("user:", user);
            dispatch(authenticationActions.register(user));
          }
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          className={classes.sectionHeader}
          style={{marginTop: theme.spacing(6)}}
        >
          Personalien
        </Typography>
        <hr/>
        <ButtonGroup
          style={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
          }}
          variant="contained"
          fullWidth
        >
          <Button
            className={salutation === "Herr" ? classes.orangeButton : classes.grayButton}
            color={salutation === "Herr" ? "primary" : "default"}
            onClick={() => setSalutation("Herr")}
          >
            Herr
          </Button>
          <Button
            className={salutation === "Frau" ? classes.orangeButton : classes.grayButton}
            color={salutation === "Frau" ? "primary" : "default"}
            onClick={() => setSalutation("Frau")}
          >
            Frau
          </Button>
        </ButtonGroup>
        <TextField
          autoFocus
          variant="outlined"
          margin="normal"
          fullWidth
          id="firstname"
          label="Vorname"
          name="firstname"
          autoComplete="given-name"
          value={firstName}
          error={submitted && !firstName}
          helperText={submitted && !firstName ? "Vorname ist erforderlich" : null}
          onChange={e => setFistName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="lastname"
          label="Name"
          name="lastname"
          autoComplete="family-name"
          value={lastName}
          error={submitted && !lastName}
          helperText={submitted && !lastName ? "Name ist erforderlich" : null}
          onChange={e => setLastName(e.target.value)}
        />
        <Typography component="h1" variant="h5" className={classes.sectionHeader}>
          Login
        </Typography>
        <hr/>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="E-Mail"
          name="email"
          autoComplete="email"
          value={email}
          error={submitted && !isValidEmail(email)}
          helperText={
            !submitted
              ? null
              : !email
              ? "E-Mail ist erforderlich"
              : !isValidEmail(email)
                ? "E-Mail ist ungültig"
                : null
          }
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Kennwort"
          type="password"
          id="password"
          autoComplete="new-password"
          value={password}
          error={submitted && !isValidPassword(password)}
          helperText={
            !submitted
              ? null
              : !password
              ? "Kennwort ist erfoderlich"
              : !isValidPassword(password)
                ? "Das Kennwort erfüllt die Sicherheitsrichtlinie nicht (min. 8 Zeichen lang, 1 Zahl, 1 Gross-/Kleinbuchstabe und 1 Sonderzeichen"
                : null
          }
          onChange={e => setPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="passwordConfirmation"
          label="Kennwort bestätigen"
          type="password"
          id="passwordConfirmation"
          autoComplete="new-password"
          value={passwordConfirmation}
          error={submitted && (!passwordConfirmation || passwordConfirmation !== password)}
          helperText={
            !submitted
              ? null
              : !passwordConfirmation
              ? "Kennwort ist erfoderlich"
              : passwordConfirmation !== password
                ? "Kennwörter sind nicht identisch"
                : null
          }
          onChange={e => setPasswordConfirmation(e.target.value)}
        />
        <Typography component="h1" variant="h5" className={classes.sectionHeader}>
          Kontaktdaten
        </Typography>
        <hr/>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="phone"
          label="Telefon"
          name="phone"
          autoComplete="tel"
          value={phone}
          error={submitted && !phone}
          helperText={submitted && !phone ? "Telefonnummer ist erforderlich" : null}
          onChange={e => setPhone(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="company"
          label="Firma"
          name="company"
          autoComplete="organization"
          value={company}
          error={submitted && !company}
          helperText={submitted && !company ? "Firma ist erforderlich" : null}
          onChange={e => setCompany(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="address"
          label="Adresse"
          name="address"
          autoComplete="street-address"
          value={address}
          error={submitted && !address}
          helperText={submitted && !address ? "Adresse ist erforderlich" : null}
          onChange={e => setAddress(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="zip"
          label="PLZ"
          name="zip"
          autoComplete="postal-code"
          value={zip}
          error={submitted && !zip}
          helperText={submitted && !zip ? "PLZ ist erforderlich" : null}
          onChange={e => setZip(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="city"
          label="Ort"
          name="city"
          autoComplete="address-level2"
          value={city}
          error={submitted && !city}
          helperText={submitted && !city ? "Ort ist erforderlich" : null}
          onChange={e => setCity(e.target.value)}
        />
        <FormControlLabel
          style={{marginTop: theme.spacing(2)}}
          control={
            <Checkbox
              color="primary"
              checked={termsAccepted}
              onChange={e => {
                setTermsAccepted(e.target.checked);
              }}
            />
          }
          label={
            <Typography component="p" variant="body2">
              Ich stimme den <a target="_blank" rel="noopener noreferrer" href={termsAndConditions}>Allgemeinen
              Geschäftsbedingungen</a> von MM-Mannhart AG zu und habe
              die <a target="_blank" rel="noopener noreferrer" href={privacyPolicy}>Datenschutzrichtlinien</a> gelesen.
            </Typography>
          }
        />
        {error &&
        <Typography
          component="p"
          variant="caption"
          color="error"
          style={{
            marginTop: theme.spacing(2)
          }}
        >
          {error.toString()}
        </Typography>
        }
        {registering &&
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CircularProgress/>
          </Grid>
        </Grid>
        }
        <Button
          disabled={!termsAccepted}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.orangeButton}
          style={{
            margin: theme.spacing(3, 0, 2),
          }}
        >
          Registrieren
        </Button>
      </form>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Button onClick={() => history.push(navigateTo)}>
            <Typography component="p" color="primary" variant="body2" texttransform="none">
              Zurück zum Konfigurator
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

