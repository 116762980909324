import React from "react";

import {makeStyles, Paper} from "@material-ui/core";
import {useRouteMatch} from "react-router-dom";

import {orangeBackgroundColor} from "../../../constants";
import {ElevationPlan} from "../../Plan/ElevationPlan/ElevationPlan";

const useStyles = makeStyles(() => ({
  plan: {
    background: orangeBackgroundColor,
    padding: '2em',
  }
}));

export const VaultStep = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const wallIndex = (m => {
    switch(m) {
      case '/step/3a': return 0
      case '/step/3b': return 1
      case '/step/3c': return 2
      case '/step/3d': return 3
      default: return 0
    }
  })(match.path);

  return (
    <Paper square={true} className={classes.plan} elevation={10}>
      <ElevationPlan
        wallIndex={wallIndex}
        isToolbarVisible={true}
      />
    </Paper>
  )
};
