import React, {useRef, useLayoutEffect, useState} from "react";
import {Stage, Layer} from "react-konva";
import {useSelector} from "react-redux";
import {createHorizontalMeasurements, createVerticalMeasurements} from "../Measurement";
import {createPart} from "./Part";

export const GroundPlan = () => {
  const client = useRef();
  const [clientSize, setClientSize] = useState({});
  const resizeDelay = 100;
  let resizeTimer = null;

  const updateClientSize = () => {
    if (client.current) {
      setClientSize({
        width: client.current.offsetWidth,
        height: client.current.offsetHeight
      });
    }
  };

  useLayoutEffect(() => updateClientSize(), []);
  window.addEventListener('resize', () => {
    clearInterval(resizeTimer);
    resizeTimer = setTimeout(updateClientSize, resizeDelay);
  });

  const innerLength = useSelector(state => state.plan.length);
  const innerWidth = useSelector(state => state.plan.width);
  const wallWidth = useSelector(state => state.plan.wallWidth);
  const outerLength = innerLength + 2 * wallWidth;
  const outerWidth = innerWidth + 2 * wallWidth;

  const measurementsAreaScreenWidth = Math.min(clientSize.width - 4, 65);
  const partScreenWidth = (clientSize.width - measurementsAreaScreenWidth - 4);
  const scale = partScreenWidth / outerLength;
  const partScreenHeight = outerWidth * scale;

  const layout = {
    screenX: 2,
    screenY: 0,
    screenWidth: clientSize.width - 4,
    screenHeight: measurementsAreaScreenWidth + partScreenHeight,
    realWidth: outerWidth,
    realLength: outerLength,
    scale: scale
  };
  // noinspection JSSuspiciousNameCombination
  const partLayout = {
    screenX: layout.screenX + measurementsAreaScreenWidth,
    screenY: layout.screenY,
    screenWidth: outerLength * scale,
    screenHeight: outerWidth * scale,
    realWidth: outerLength,
    realHeight: outerWidth,
    scale: scale
  };
  // noinspection JSSuspiciousNameCombination
  const partInsideLayout = {
    screenX: partLayout.screenX + wallWidth * scale,
    screenY: partLayout.screenY + wallWidth * scale,
    screenWidth: innerLength * scale,
    screenHeight: innerWidth * scale,
    realWidth: innerLength,
    realHeight: innerWidth,
    scale: scale
  };
  // noinspection JSSuspiciousNameCombination
  const horizontalMeasurementLayout = {
    screenX: partLayout.screenX,
    screenY: partLayout.screenY + partLayout.screenHeight,
    screenWidth: partLayout.screenWidth,
    screenHeight: measurementsAreaScreenWidth,
    realWidth: outerLength,
    scale: scale
  };
  // noinspection JSSuspiciousNameCombination
  const verticalMeasurementLayout = {
    screenX: layout.screenX,
    screenY: partLayout.screenY,
    screenWidth: measurementsAreaScreenWidth,
    screenHeight: partLayout.screenHeight,
    realHeight: outerWidth,
    scale: scale
  };


  return (
    <div ref={client}>
      {!isNaN(scale) &&
      <Stage
        width={clientSize.width}
        height={layout.screenHeight}
      >
        <Layer>
          {createPart(partLayout, partInsideLayout)}
          {
            createHorizontalMeasurements(
              horizontalMeasurementLayout,
              [[wallWidth, partInsideLayout.realWidth, wallWidth]],
              [0]
            )
          }
          {
            createVerticalMeasurements(
              verticalMeasurementLayout,
              [[wallWidth, partInsideLayout.realHeight, wallWidth]]
            )
          }
        </Layer>
      </Stage>
      }
    </div>
  );
};

