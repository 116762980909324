import React from "react"
import {Circle, Text} from "react-konva"

import {FONT_SIZE} from "../../../constants"
import {
  renderDragPreviewToolbarElement,
  renderPlanElement,
  renderStaticToolbarElement
} from "./circularElementFunctions"

const renderGroupElements = (key, radius, sleeve) => {
  return [
    (
      <Circle
        key={key + "_outer_circle"}
        radius={radius}
        fill="#eeeeee"
        stroke="black"
        strokeWidth={1}
        shadowColor="black"
        shadowBlur={8}
        shadowOpacity={0}
      />
    ), (
      <Circle
        key={key + "_inner_circle"}
        radius={0.8 * radius}
        stroke="black"
        strokeWidth={1}
      />
    ), (
      <Text
        key={key + "_dimension_text"}
        name="dimension"
        x={-radius}
        y={-radius}
        width={2 * radius}
        height={2 * radius}
        fontSize={FONT_SIZE}
        align="center"
        verticalAlign="middle"
        text={sleeve.colloquialism}
        fill="black"
      />
    )
  ]
}


export const renderStaticToolbarSleeve = (key, sleeve, x, y, radius) => {
  return renderStaticToolbarElement(key + "_static_sleeve", sleeve, x, y, radius, renderGroupElements)
}

export const renderDragPreviewToolbarSleeve = (
  key,
  sleeve,
  x,
  y,
  configAreaLayout,
  realWorldTransform,
  dragEndCallback
) => {
  return renderDragPreviewToolbarElement(
    key + "_drag_preview_sleeve",
    sleeve,
    x,
    y,
    sleeve.outerDiameter / 2 * configAreaLayout.scale,
    configAreaLayout,
    realWorldTransform,
    dragEndCallback,
    renderGroupElements
  )
}

export const renderPlanSleeve = (
  sleeve,
  configAreaLayout,
  configAreaRealWorldTransform,
  dragEndCallback,
  clickCallback
) => {
  return renderPlanElement(
    "plan_sleeve_" + sleeve.id,
    sleeve,
    sleeve.outerDiameter / 2 * configAreaLayout.scale,
    configAreaLayout,
    configAreaRealWorldTransform,
    dragEndCallback,
    clickCallback,
    renderGroupElements
  )
}
