
export const getCenteredGridLinePositions = (gridWidth, cellWidth, includeEndpoints = false) => {
  if (cellWidth < 1) return [];
  const half = gridWidth / 2;
  let result = [half];
  let offset = cellWidth;

  while (offset < half || (includeEndpoints && offset <= half)) {
    result.unshift(half - offset);
    result.push(half + offset);
    offset += cellWidth;
  }
  return result;
};

export const getGridLinePositions = (gridWidth, cellWidth, includeEndpoints = false) => {
  let result = [];
  let currentPos = includeEndpoints ? 0 : cellWidth;

  while (currentPos < gridWidth || (includeEndpoints && currentPos <= gridWidth)) {
    result.push(currentPos);
    currentPos += cellWidth;
  }
  return result;
};
