export const authenticationActionTypes = {

  EMAIL_VERIFICATION_REQUEST: 'EMAIL_VERIFICATION_REQUEST',
  EMAIL_VERIFICATION_SUCCESS: 'EMAIL_VERIFICATION_SUCCESS',
  EMAIL_VERIFICATION_FAILURE: 'EMAIL_VERIFICATION_FAILURE',

  REGISTRATION_REQUEST: 'REGISTRATION_REQUEST',
  REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
  REGISTRATION_FAILURE: 'REGISTRATION_FAILURE',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  EXTEND_LOGIN_REQUEST: 'EXTEND_LOGIN_REQUEST',
  EXTEND_LOGIN_SUCCESS: 'EXTEND_LOGIN_SUCCESS',
  EXTEND_LOGIN_FAILURE: 'EXTEND_LOGIN_FAILURE',

  LOGOUT: 'LOGOUT',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

};
