import React from "react";
import {Text, Layer, Line} from "react-konva";
import {FONT_SIZE} from "../../../constants";

const crossHairColor = "red";
const textColor = "red";

const textWidth = 40;
const textHeight = 18;
const textDistance = 20;

const createLine = (key, name) => {
  return (
    <Line
      key={key}
      name={name}
      stroke={crossHairColor}
      strokeWidth={0.3}
      points={[]}
    />
  );
};

export const createBlockOutCrossHairLayer = () => {
  return (
    <Layer
      name={"blockOutCrossHairLayer"}
      visible={true}
    >
      {[
        createLine("horizontal_block_out_cross_hair_1_1", "horizontal-cross-hair-left"),
        createLine("horizontal_block_out_cross_hair_1_2", "horizontal-cross-hair-right"),
        createLine("vertical_block_out_cross_hair_1_1", "vertical-cross-hair-top"),
        createLine("vertical_block_out_cross_hair_1_2", "vertical-cross-hair-bottom"),
        createLine("horizontal_block_out_cross_hair_2_1", "horizontal-cross-hair-left"),
        createLine("horizontal_block_out_cross_hair_2_2", "horizontal-cross-hair-right"),
        createLine("vertical_block_out_cross_hair_2_1", "vertical-cross-hair-top"),
        createLine("vertical_block_out_cross_hair_2_2", "vertical-cross-hair-bottom"),
      ]}

      <Text
        key="top_text"
        name="top_text"
        width={textWidth}
        height={textHeight}
        align="center"
        verticalAlign="middle"
        fontSize={FONT_SIZE}
        fill={textColor}
      />
      <Text
        key="bottom_text"
        name="bottom_text"
        width={textWidth}
        height={textHeight}
        align="center"
        verticalAlign="middle"
        fontSize={FONT_SIZE}
        fill={textColor}
      />
      <Text
        key="left_text"
        name="left_text"
        width={textWidth}
        height={textHeight}
        align="center"
        verticalAlign="middle"
        fontSize={FONT_SIZE}
        fill={textColor}
      />
      <Text
        key="right_text"
        name="right_text"
        width={textWidth}
        height={textHeight}
        align="center"
        verticalAlign="middle"
        fontSize={FONT_SIZE}
        fill={textColor}
      />
    </Layer>
  );
};

const isWithinLayout = (rect, layout) => {
  return (
    rect.x >= layout.screenX
    && rect.x + rect.width <= layout.screenX + layout.screenWidth
    && rect.y >= layout.screenY
    && rect.y + rect.height <= layout.screenY + layout.screenHeight
  );
};

const getShapes = layer => {
  return {
    topText: layer.find('.top_text')[0],
    bottomText: layer.find('.bottom_text')[0],
    leftText: layer.find('.left_text')[0],
    rightText: layer.find('.right_text')[0],
    topLineLeft: layer.find('.horizontal-cross-hair-left')[0],
    topLineRight: layer.find('.horizontal-cross-hair-right')[0],
    leftLineTop: layer.find('.vertical-cross-hair-top')[0],
    leftLineBottom: layer.find('.vertical-cross-hair-bottom')[0],
    bottomLineLeft: layer.find('.horizontal-cross-hair-left')[1],
    bottomLineRight: layer.find('.horizontal-cross-hair-right')[1],
    rightLineTop: layer.find('.vertical-cross-hair-top')[1],
    rightLineBottom: layer.find('.vertical-cross-hair-bottom')[1]
  };
};

const getTextPositions = rect => {
  const halfTextWidth = textWidth / 2;
  const halfTextHeight = textHeight / 2;

  return {
    top: {x: rect.x + rect.width - halfTextWidth, y: rect.y - textDistance - textHeight},
    bottom: {x: rect.x - halfTextWidth, y: rect.y + rect.height + textDistance},
    left: {x: rect.x - textWidth - textDistance, y: rect.y + rect.height - halfTextHeight},
    right: {x: rect.x + rect.width + textDistance, y: rect.y - halfTextHeight}
  };
};

const updateLines = (configAreaLayout, rect, shapes, textPositions) => {
  const left = configAreaLayout.screenX;
  const right = configAreaLayout.screenX + configAreaLayout.screenWidth;
  const top = configAreaLayout.screenY;
  const bottom = configAreaLayout.screenY + configAreaLayout.screenHeight;

  shapes.bottomLineLeft.points(
    [left, rect.y + rect.height, Math.max(left, textPositions.left.x), rect.y + rect.height]
  );
  shapes.bottomLineRight.points(
    [Math.min(textPositions.left.x + textWidth, right), rect.y + rect.height, right, rect.y + rect.height]
  );
  shapes.leftLineTop.points(
    [rect.x, top, rect.x, Math.max(top, textPositions.bottom.y)]
  );
  shapes.leftLineBottom.points(
    [rect.x, Math.min(bottom, textPositions.bottom.y + textHeight), rect.x, bottom]
  );
  shapes.topLineLeft.points(
    [left, rect.y, Math.max(left, textPositions.right.x), rect.y]
  );
  shapes.topLineRight.points(
    [Math.min(textPositions.right.x + textWidth, right), rect.y, right, rect.y]
  );
  shapes.rightLineTop.points(
    [rect.x + rect.width, top, rect.x + rect.width, Math.max(top, textPositions.top.y)]
  );
  shapes.rightLineBottom.points(
    [rect.x + rect.width, Math.min(bottom, textPositions.top.y + textHeight), rect.x + rect.width, bottom]
  );
};

const updateText = (textShape, measurement, position) => {
  textShape.text(measurement.toString());
  textShape.x(position.x);
  textShape.y(position.y);
};

const updateTexts = (configAreaLayout, snappedRealRect, shapes, textPositions) => {
  updateText(
    shapes.topText,
    configAreaLayout.realHeight - snappedRealRect.y - snappedRealRect.height,
    textPositions.top
  );
  updateText(
    shapes.bottomText,
    snappedRealRect.y,
    textPositions.bottom
  );
  updateText(
    shapes.leftText,
    snappedRealRect.x,
    textPositions.left
  );
  updateText(
    shapes.rightText,
    configAreaLayout.realWidth - snappedRealRect.x - snappedRealRect.width,
    textPositions.right
  );
};

export const updateCrossHairs = (configAreaLayout, stage, screenRect, snappedRealRect) => {
  const crossHairLayer = stage.find(".blockOutCrossHairLayer")[0];

  if (!isWithinLayout(screenRect, configAreaLayout)) {
    crossHairLayer.hide();
    crossHairLayer.draw();
    return;
  }
  crossHairLayer.show();
  const shapes = getShapes(crossHairLayer);
  const textPositions = getTextPositions(screenRect);

  updateLines(configAreaLayout, screenRect, shapes, textPositions);
  updateTexts(configAreaLayout, snappedRealRect, shapes, textPositions);
  crossHairLayer.draw();
};

export const hideCrossHairs = (stage) => {
  const crossHairLayer = stage.find(".blockOutCrossHairLayer")[0];

  crossHairLayer.hide();
  crossHairLayer.draw();
};

