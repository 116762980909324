import { combineReducers } from 'redux';

import {authenticationReducer} from "./authenticationReducer";
import {userReducer} from "./userReducer";
import {planReducer} from "./planReducer";
import {projectReducer} from "./projectReducer";
import {plansReducer} from "./plansReducer";
import {projectsReducer} from "./projectsReducer";
import {apiRequestStateReducer} from "./apiRequestStateReducer";

export default combineReducers({
    authentication: authenticationReducer,
    user: userReducer,
    plan: planReducer,
    project: projectReducer,
    plans: plansReducer,
    projects: projectsReducer,
    apiRequestState: apiRequestStateReducer
});
