import React from "react"

import {makeStyles, Paper} from "@material-ui/core"

import {orangeBackgroundColor} from "../../../constants"
import {LidSection} from "./LidSection"
import Grid from "@material-ui/core/Grid"
import {LidPlan} from "../../Plan/LidPlan/LidPlan"
import {AutoResizeDiv} from "../../Plan/AutoResizeDiv"
import {useDispatch, useSelector} from "react-redux"
import {lidActions} from "../../../actions"
import {snapNoResizeRectToGrid, snapRectToGrid, snapResizeManhole} from "../../Plan/helper/snap"

const useStyles = makeStyles(() => ({
  plan: {
    background: orangeBackgroundColor,
    padding: '2em',
  }
}))

const addItem = (dispatch, configAreaWidth, configAreaHeight, item) => {
  const snapped = {
    ...item,
    ...snapNoResizeRectToGrid(item, configAreaWidth, configAreaHeight)
  }

  dispatch(lidActions.addManhole({...item, x: snapped.x, y: snapped.y}))
  return {...item, x: snapped.x, y: snapped.y}
}

const moveItem = (dispatch, configAreaWidth, configAreaHeight, item) => {
  const snapped = {
    ...item,
    ...snapNoResizeRectToGrid(item, configAreaWidth, configAreaHeight)
  }

  dispatch(lidActions.moveManhole(item.id, snapped.x, snapped.y))
  return {...item, x: snapped.x, y: snapped.y}
}

const removeItem = (dispatch, id) => {
  dispatch(lidActions.removeManhole(id))
}

const resizeItem = (dispatch, configAreaWidth, item) => {
  const width = snapResizeManhole(item, configAreaWidth)

  dispatch(lidActions.resizeManhole(item.id, width))
  return {...item, width}
}

const previewResizeItem = (configAreaWidth, item) => {
  const width = snapResizeManhole(item, configAreaWidth)

  return {...item, width}
}


export const LidStep = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const innerLength = useSelector(state => state.plan.length)
  const innerWidth = useSelector(state => state.plan.width)

  return (
    <Paper square={true} className={classes.plan} elevation={10}>
      <Grid container spacing={6}>
        <Grid item>
          <LidSection/>
        </Grid>
      </Grid>
      <br/>
      <br/>
      <br/>
      <AutoResizeDiv>
        <LidPlan
          isToolbarVisible={true}
          addItemHandler={item => addItem(dispatch, innerLength, innerWidth, item)}
          moveItemHandler={item => moveItem(dispatch, innerLength, innerWidth, item)}
          removeItemHandler={id => removeItem(dispatch, id)}
          resizeItemHandler={item => resizeItem(dispatch, innerLength, item)}
          previewResizeItemHandler={item => previewResizeItem(innerLength, item)}
        />
      </AutoResizeDiv>
    </Paper>
  )
}
