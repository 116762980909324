import {projectApiActionTypes as actionTypes} from "../constants";

const initialState = [];

export const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.resetProjects:
      return initialState;

    case actionTypes.readProjectsRequest:
      return {fetching: true};

    case actionTypes.readProjectsFailure:
      return {error: action.error};

    case actionTypes.readProjectsSuccess:
      return Array.isArray(action.data) ? [...action.data] : [];


    default:
      return state;
  }
};
