import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {vaultActions, lidActions, floorActions} from "../../../actions";

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Kabelschacht Konfigurator
      </Typography>
      < hr/>
    </Grid>
  );
};

const TextGridItem = () => {
  return [
    (
      <Grid item xs={12} key={'upper_text'}>
        <Typography component="p" variant="body1">
          Mit dem Konfigurator können Sie Ihren eigenen Kabelschacht zusammenstellen. Definieren Sie die Abmessung und
          platzieren Sie Aussparungen oder Muffen auf einem 2,5 cm Raster. Zusätzlich
          können Sie eine passende Abdeckung mit einer Einstiegslucke oder ein separaten Boden konfigurieren.
        </Typography>
      </Grid>
    ),
    (
      <Grid item xs={12} key={'lower_text'}>
        <Typography component="p" variant="body1">
          Jetzt starten? Was möchten Sie konfigurieren...?
        </Typography>
      </Grid>
    )
  ]
};

const VaultGridItem = () => {
  const dispatch = useDispatch();
  const vault = useSelector(state => state.plan.vault);
  const lid = useSelector(state => state.plan.lid);
  const floor = useSelector(state => state.plan.floor);

  return (
    <Grid item xs={12}>
      <FormControlLabel
        disabled={vault && !lid && !floor}
        control={
          <Checkbox
            color="primary"
            checked={vault !== undefined}
            onChange={() => {
              if (vault) {
                dispatch(vaultActions.unsetVault());
              } else {
                dispatch(vaultActions.setVault());
              }
            }}
          />
        }
        label="... einen Kabelschacht"
      />
    </Grid>
  );
};

const LidGridItem = () => {
  const dispatch = useDispatch();
  const vault = useSelector(state => state.plan.vault);
  const lid = useSelector(state => state.plan.lid);
  const floor = useSelector(state => state.plan.floor);

  return (
    <Grid item xs={12}>
      <FormControlLabel
        disabled={!vault && lid && !floor}
        control={
          <Checkbox
            color="primary"
            checked={lid !== undefined}
            onChange={() => {
              if (lid) {
                dispatch(lidActions.unsetLid());
              } else {
                dispatch(lidActions.setLid());
              }
            }}
          />
        }
        label="... eine passende Abdeckung"
      />
    </Grid>
  );
};

const FloorGridItem = () => {
  const dispatch = useDispatch();
  const vault = useSelector(state => state.plan.vault);
  const lid = useSelector(state => state.plan.lid);
  const floor = useSelector(state => state.plan.floor);

  return (
    <Grid item xs={12}>
      <FormControlLabel
        disabled={!vault && !lid && floor}
        control={
          <Checkbox
            color="primary"
            checked={floor !== undefined}
            onChange={() => {
              if (floor) {
                dispatch(floorActions.unsetFloor());
              } else {
                dispatch(floorActions.setFloor());
              }
            }}
          />
        }
        label="... einen separaten Boden"
      />
    </Grid>
  );
};

export const ComponentsSection = () => {
  return (
    <Grid container spacing={2}>
      <TitleGridItem/>
      <TextGridItem/>
      <VaultGridItem/>
      <LidGridItem/>
      <FloorGridItem/>
    </Grid>
  );
};
