import {PLAN_ITEM_DRAGGING_OPACITY, PLAN_ITEM_OPACITY} from "../../../constants";
import {hideCrossHairs} from "./BlockOutCrossHairs";
import {toRealRectPosition, toScreenRect} from "../helper/realWorldTransform";
import {snapRectToGrid} from "../helper/snap";
import Konva from "konva";

export const setOpacity = (element, action) => {
  switch (action) {
    case "resizing":
      element.find('.fill').opacity(0)
      element.find('.position').opacity(0)
      break

    case "dragging":
      element.opacity(PLAN_ITEM_DRAGGING_OPACITY)
      element.find('.fill').opacity(0)
      element.find('.position').opacity(0)
      element.find('.dimension').opacity(0)
      break

    default:
      element.opacity(PLAN_ITEM_OPACITY)
      element.find('.fill').opacity(1)
      element.find('.position').opacity(1)
      element.find('.dimension').opacity(1)
      break
  }
}

export const handleDragStart = e => {
  //e.target.moveToTop()
  setOpacity(e.target, "dragging")
}

export const handleDragEnd = (e) => {
  setOpacity(e.target);
  //hideCrossHairs(e.target.getStage());
  /*
  const x = e.target.x();
  const y = e.target.y();
  const realPosition = toRealRectPosition(
    {x, y},
    blockOut,
    realWorldTransform,
    {x: configAreaLayout.screenX, y: configAreaLayout.screenY});
  const snappedRealRect = snapRectToGrid(
    {x: realPosition.x, y: realPosition.y, width: blockOut.width, height: blockOut.height},
    configAreaLayout.realWidth,
    configAreaLayout.realHeight,
  );
  */

  if (e.target.attrs.origin) { // dragged from toolbar?
    e.target.to({
      opacity: 0,
      duration: 0,
      x: e.target.attrs.origin.x,
      y: e.target.attrs.origin.y,
    });
  }
  /*
  else { // dragged from plan!
    const snappedScreenRect = toScreenRect(
      snappedRealRect,
      realWorldTransform,
      {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
    );

    e.target.to({
      duration: 0.05,
      easing: Konva.Easings.StrongEaseOut,
      x: snappedScreenRect.x,
      y: snappedScreenRect.y
    });
  }
  if (
    x < configAreaLayout.screenX || x > configAreaLayout.screenX + configAreaLayout.screenWidth
    || y < configAreaLayout.screenY || y > configAreaLayout.screenY + configAreaLayout.screenHeight) {
    return;
  }
  dragEndCallback(snappedRealRect);

   */
};
