import {vaultActions} from "../../../actions";
import {renderPlanGrounding} from "./GroundingRenderFactory";

const createGrounding = (grounding, index, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  const dragEndCallbackHandler =
    snappedCoordinate => {
      dispatch(vaultActions.moveGrounding(wallIndex, grounding.id, snappedCoordinate.x, snappedCoordinate.y));
    };
  const clickCallbackHandler = () => {
    dispatch(vaultActions.removeGrounding(wallIndex, grounding.id));
  };

  return renderPlanGrounding(grounding, configAreaLayout, realWorldTransform, dragEndCallbackHandler, clickCallbackHandler);
};

export const createGroundings = (groundings, configAreaLayout, realWorldTransform, wallIndex, dispatch) => {
  return groundings.map((grounding, i) => {
    return createGrounding(grounding, i, configAreaLayout, realWorldTransform, wallIndex, dispatch);
  });
};
