import {planActionTypes as actionTypes} from "../constants"

const setLid = () => {
  return {
    type: actionTypes.setLid
  }
}

const unsetLid = () => {
  return {
    type: actionTypes.unsetLid
  }
}

const setLidType = lidType => {
  return {
    type: actionTypes.setLidType,
    data: lidType
  }
}

const setLidCollar = collar => {
  return {
    type: actionTypes.setLidCollar,
    data: collar
  }
}

const setLidPavingReady = () => {
  return {
    type: actionTypes.setLidPavingReady
  }
}

const unsetLidPavingReady = () => {
  return {
    type: actionTypes.unsetLidPavingReady
  }
}

const setLidFill = fill => {
  return {
    type: actionTypes.setLidFill,
    data: fill
  }
}

const addManhole = (manhole) => {
  return {
    type: actionTypes.addManhole,
    data: manhole
  }
}

const removeManhole = (id) => {
  return {
    type: actionTypes.removeManhole,
    data: id
  }
}

const moveManhole = (id, x, y) => {
  return {
    type: actionTypes.moveManhole,
    data: {id, x, y}
  }
}

const resizeManhole = (id, width) => {
  return {
    type: actionTypes.resizeManhole,
    data: {id, width}
  }
}

export const lidActions = {
  setLid,
  unsetLid,
  setLidType,
  setLidCollar,
  setLidPavingReady,
  unsetLidPavingReady,
  setLidFill,
  addManhole,
  removeManhole,
  moveManhole,
  resizeManhole,
}
