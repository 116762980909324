import {authHeader} from "../helpers/authHeader";
import {handleResponse} from "./httpHelper";
import {apiUrls} from "../constants";

const apiUrl = apiUrls.userEndpoint;

const read = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader()
    }
  };

  return fetch(apiUrl, requestOptions)
    .then(handleResponse);
};

export const userService = {
  read
};
